import { FC } from "react";
import Text from "../text/text";
import Modal from "react-bootstrap/Modal";
import "./loading.css";

type Props = {
  show: boolean;
};

const Loading: FC<Props> = ({ ...props }: Props) => {
  return (
    <Modal show={props.show} centered>
      <Modal.Body>
        <div className="loadingContainer">
          <Text>Carregando, por favor aguarde</Text>
          <div className="loading">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Loading;
