import { FC, useState, ChangeEvent, MouseEvent, useEffect, Fragment } from "react";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer";
import Text from "../../components/text/text";
import Loading from "../../components/loading/loading";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import "./sale.css";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import {
  formatCellphone,
  formatCpf,
  validateCPF,
  validateEmail,
  validateRequired,
  validateString,
} from "../../validations/validations";
import axios from "axios";
import * as Models from "../../models/index";
import { formatPrice } from "../../utils";
import { useNavigate } from "react-router-dom";

const Sale: FC = () => {
  const navigate = useNavigate();
  useAuth();
  const [user] = useClient();
  const [productSearch, setProductSearch] = useState<string>("");
  const [clientSearch, setClientSearch] = useState<string>("");
  const [clientNameTitle, setClientNameTitle] = useState<string>("");
  const [clientId, setClientId] = useState<number>(0);
  const [clientName, setClientName] = useState<string>("");
  const [clienteEmail, setClientEmail] = useState<string>("");
  const [clientCpf, setClientCpf] = useState<string>("");
  const [clientCellphone, setClientCellphone] = useState<string>("");
  const [clients, setClients] = useState<Array<Models.Client>>([]);
  const [products, setProducts] = useState<Array<Models.Product>>([]);
  const [paymentOptions, setPaymentOptions] = useState<Array<Models.PaymentOptions>>([]);
  const [cartProduct, setCartProduct] = useState<Array<Models.Product>>([]);
  const [productQuantity, setProductQuantity] = useState<Array<{ quantity: string | undefined }>>([]);
  const [saleTotalPrice, setSaleTotalPrice] = useState<number>(0);
  const [saleCoupon, setSaleCoupon] = useState<string>("");
  const [showModalNewClient, setShowModalNewClient] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>();
  const [salePayment, setSalePayment] = useState<number>(0);

  const handleClose = (type: string) => {
    if (type === "modalNewClient") setShowModalNewClient(false);
    setClientName("");
    setClientEmail("");
    setClientCpf("");
    setClientCellphone("");
  };

  const handleShow = (type: string) => {
    if (type === "modalNewClient") setShowModalNewClient(true);
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setClientCpf(cpf);
  };

  const handleChangeCelphone = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const celphone = formatCellphone(rawValue);
    setClientCellphone(celphone);
  };

  const handleSubmit = async () => {
    if (
      !validateRequired({
        Nome: clientName,
      })
    ) {
      return;
    }
    if (!validateString(clientName, 4, 100)) return;
    if (clienteEmail && !validateEmail(clienteEmail)) return;
    if (clientCpf && !validateCPF(clientCpf)) return;

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/client/new-client/environment/${user.environmentId}`,
        {
          name: clientName,
          email: clienteEmail,
          cpf: clientCpf,
          cellphone: clientCellphone,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await teste();
      toast.success("Cliente cadastrado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
        // icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }

    setClientName("");
    setClientEmail("");
    setClientCpf("");
    setClientCellphone("");
    setShowModalNewClient(false);
  };

  const handleCreateSaleWithCart = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      console.log({
        clientId: clientId,
        paymentOptionId: salePayment,
        coupon: saleCoupon,
        cartProduct: cartProduct.map((iterator) => {
          return { productId: iterator.id, quantity: productQuantity[iterator.id]?.quantity ?? "" };
        }),
        total: saleTotalPrice,
      });
      await axios.post(
        process.env.REACT_APP_API_URL + `/sale/new-sale-with-cart/environment/${userStorage.environmentId}`,
        {
          clientId: clientId,
          paymentOptionId: salePayment,
          coupon: parseFloat(saleCoupon),
          cartProduct: cartProduct.map((iterator) => {
            return { id: 0, productId: iterator.id, quantity: parseInt(productQuantity[iterator.id]?.quantity ?? "0") };
          }),
          total: saleTotalPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setCartProduct([]);
      setClientNameTitle("");
      setSaleTotalPrice(0);
      setSalePayment(0);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleSubmitSale = async () => {
    console.log("/cart/new-cart/environment/2", {
      products: cartProduct.map((iterator) => {
        return { productId: iterator.id, quantity: productQuantity[iterator.id]?.quantity ?? "" };
      }),
      coupon: saleCoupon,
    });

    console.log("/sale/new-sale/environment/2", {
      clientId: clientNameTitle,
      paymentOptionsId: 1,
      cartId: 1,
    });

    await handleCreateSaleWithCart();
  };

  const handleClient = (clientId: number) => {
    const client = clients.find((e) => e.id === clientId);
    setClientNameTitle(client?.name ?? "");
    setClientId(clientId);
    setClientSearch("");
  };

  const handleCartProducts = (productId: number) => {
    setCartProduct([...cartProduct, products.find((e: Models.Product) => e.id === productId) as Models.Product]);
    setProductSearch("");
  };

  const handleCalculatePrice = (e: string, index: number) => {
    const product = products.find((prod) => prod.id == index);
    if (parseInt(e) > product?.quantity!) {
      toast.error(`Produto ${product?.name}. Quantidade ${product?.quantity} em estoque.`, {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return;
    }
    productQuantity[index] = { quantity: e ?? "0" };
    setProductQuantity([...productQuantity]);
    let price = 0;
    cartProduct.forEach((p: Models.Product) => {
      price += parseInt(productQuantity[p.id]?.quantity ?? "0") * p.unitaryPrice;
    });
    setSaleTotalPrice(price);
  };

  const handleRemoveProduct = (productId: number) => {
    productQuantity[productId] = { quantity: undefined };
    setProductQuantity([...productQuantity]);
    let price = 0;
    cartProduct.forEach((p: Models.Product) => {
      price += parseInt(productQuantity[p.id]?.quantity ?? "0") * p.unitaryPrice;
    });
    setSaleTotalPrice(price);
    setCartProduct(cartProduct.filter((e: Models.Product) => e.id !== productId));
  };

  const handleClients = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const clientsData = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-clients/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setClients(clientsData.data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleProducts = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const productData = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/get-products/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setProducts(productData.data.products);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handlePaymentOptions = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const paymentOptionsData = await axios.get(`${process.env.REACT_APP_API_URL}/sale/get-payment-options`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setPaymentOptions(paymentOptionsData.data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleData = async () => {
    await handleClients();
    await handleProducts();
    await handlePaymentOptions();
  };

  const teste = async () => {
    await handleClients();
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <Nav user={user} />
      <div className="saleContainer">
        <div className="saleChildren">
          <div className="saleChildrenClientFilter">
            <div>
              <Text size="28px">Venda</Text>
              <div>Cliente: {clientNameTitle ?? ""}</div>
            </div>
            <div className="clientContent">
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar cliente"
                  value={clientSearch}
                  change={(e) => setClientSearch(e.target.value)}
                  focus={(e) => setClientSearch(e.target.value)}
                />
                {clientSearch && (
                  <div className="clientSearchDropdown">
                    <ul>
                      {clients &&
                        clients
                          .filter(
                            (e) =>
                              e.name?.toUpperCase().includes(clientSearch) ||
                              e.name?.toLowerCase().includes(clientSearch) ||
                              clientSearch === ""
                          )
                          .map((item: Models.Client) => {
                            return (
                              <li
                                key={item.id}
                                value={item.id}
                                onClick={(e: MouseEvent<HTMLLIElement>) => handleClient(e.currentTarget.value)}>
                                {item.name}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                )}
              </div>
              <Button
                name="novo cliente"
                click={() => {
                  handleShow("modalNewClient");
                }}
              />
            </div>
          </div>
          <hr />
          <div className="saleContent">
            <div className="saleContentProducts">
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar produto"
                  value={productSearch}
                  change={(e) => setProductSearch(e.target.value)}
                  focus={(e) => setProductSearch(e.target.value)}
                />
              </div>
              {productSearch && (
                <div className="productSearchDropdown">
                  <ul>
                    {products &&
                      products
                        .filter(
                          (e) =>
                            (e.name.toUpperCase().includes(productSearch) ||
                              e.name.toLowerCase().includes(productSearch) ||
                              productSearch === "") &&
                            (cartProduct.length == 0 || cartProduct.find((p: Models.Product) => ![e.id].includes(p.id)))
                        )
                        .map((item: Models.Product) => {
                          return (
                            <li
                              key={item.id}
                              value={item.id}
                              onClick={(e: MouseEvent<HTMLLIElement>) => handleCartProducts(e.currentTarget.value)}>
                              {item.name}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              )}
              <hr />
              {/* <Text size="20px">Itens</Text>
                <hr /> */}
              <div className="tableCustomProducts">
                <Table hover responsive className="hideResponsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Preço unitário</th>
                      <th>Quantidade</th>
                      <th>Preço total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartProduct &&
                      cartProduct.length > 0 &&
                      cartProduct.map((iterator: Models.Product, index: number) => {
                        return (
                          <tr key={iterator.id}>
                            <td>{index + 1}</td>
                            <td>{iterator.code}</td>
                            <td>{iterator.name}</td>
                            <td>{formatPrice(iterator.unitaryPrice ?? "0")}</td>
                            <td>
                              <input
                                className="quantity"
                                type="text"
                                maxLength={4}
                                value={productQuantity[iterator.id]?.quantity ?? ""}
                                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleCalculatePrice(e.target.value, iterator.id)
                                }
                              />
                            </td>
                            <td>
                              {productQuantity[iterator.id]?.quantity ?? 0 * iterator.unitaryPrice
                                ? formatPrice(
                                    parseInt(productQuantity[iterator.id]?.quantity ?? "0") * iterator.unitaryPrice
                                  )
                                : "R$ 0,00"}
                            </td>
                            <td>
                              <i
                                className="fa-solid fa-circle-minus trashMinus"
                                onClick={() => handleRemoveProduct(iterator.id)}></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Table hover responsive className="showResponsive fontResponsive">
                  <tbody>
                    {cartProduct &&
                      cartProduct.length > 0 &&
                      cartProduct.map((iterator: Models.Product) => {
                        return (
                          <Fragment key={iterator.id}>
                            <tr>
                              <th>Código</th>
                              <td>{iterator.code}</td>
                            </tr>
                            <tr>
                              <th>Nome</th>
                              <td>{iterator.name}</td>
                            </tr>
                            <tr>
                              <th>Preço unitário</th>
                              <td>{formatPrice(iterator.unitaryPrice ?? "0")}</td>
                            </tr>
                            <tr>
                              <th>Quantidade</th>
                              <td>
                                <input
                                  className="quantity"
                                  type="text"
                                  maxLength={4}
                                  value={productQuantity[iterator.id]?.quantity ?? ""}
                                  onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleCalculatePrice(e.target.value, iterator.id)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Preço total</th>
                              <td>
                                {productQuantity[iterator.id]?.quantity ?? 0 * iterator.unitaryPrice
                                  ? formatPrice(
                                      parseInt(productQuantity[iterator.id]?.quantity ?? "0") * iterator.unitaryPrice
                                    )
                                  : "R$ 0,00"}
                              </td>
                            </tr>
                            <tr>
                              <th></th>
                              <td>
                                <i
                                  className="fa-solid fa-circle-minus trashMinus"
                                  onClick={() => handleRemoveProduct(iterator.id)}></i>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <hr />
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="saleContentPayment">
              {/* <Text size="20px">Resumo</Text>
              <hr /> */}
              <div className="saleContentPaymentTotal">
                <div>
                  <Text size="28px">Total</Text>
                </div>
                <div>
                  <Text color="green" size="28px" bold={true}>
                    {saleTotalPrice ? formatPrice(saleTotalPrice ?? 0) : "R$ 0,00"}
                  </Text>
                </div>
              </div>
              <div className="saleContentPaymentDiscount">
                <div>
                  <Text size="20px">Desconto</Text>
                </div>
                <div>
                  -{" "}
                  <input
                    className="quantity"
                    type="text"
                    maxLength={5}
                    value={saleCoupon}
                    onChange={(c) => setSaleCoupon(c.target.value)}
                  />
                </div>
              </div>
              <hr />
              <Text size="24px">Pagamento</Text>
              <div className="paymentMethodList">
                {paymentOptions &&
                  paymentOptions.map((item: Models.PaymentOptions) => {
                    return (
                      <div
                        key={item.id}
                        className={salePayment === item.id ? "paymentSelected method" : "method"}
                        onClick={() => setSalePayment(item.id)}>
                        <div>
                          <i className={item.icon}></i>
                        </div>
                        <Text>{item.name}</Text>
                      </div>
                    );
                  })}
              </div>
              <hr />
              <div className="text-center">
                <Button name="finalizar venda" click={handleSubmitSale} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalNewClient} onHide={() => handleClose("modalNewClient")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Novo cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text color="var(--blackAll)" bold={true} class="d-block">
            Nome* <Text size="14px">obrigatório</Text>
          </Text>
          <Input type="text" value={clientName} change={(e) => setClientName(e.target.value)} />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            E-mail <Text size="14px">opcional</Text>
          </Text>
          <Input type="text" value={clienteEmail} change={(e) => setClientEmail(e.target.value)} />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            CPF <Text size="14px">opcional</Text>
          </Text>
          <Input
            class="inputHalf"
            type="text"
            // placeholder="000.000.000-00"
            value={clientCpf}
            change={handleChangeCpf}
          />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            Celular <Text size="14px">opcional</Text>
          </Text>
          <Input class="inputHalf" type="text" maxLength={14} value={clientCellphone} change={handleChangeCelphone} />
        </Modal.Body>
        <Modal.Footer>
          <Button name="salvar" click={handleSubmit} />
        </Modal.Footer>
      </Modal>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Sale;
