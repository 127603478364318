import { ReactNode, MouseEventHandler, FC } from "react";
import { Button as ButtonMantine } from "@mantine/core";
import "./button.css";

type Props = {
  name: string | ReactNode;
  width?: string;
  backgroundColor?: string;
  color?: string;
  dataBsDismiss?: string;
  class?: string;
  click?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  bold?: boolean;
  children?: ReactNode;
};

const Button: FC<Props> = ({ ...props }: Props) => {
  return (
    <ButtonMantine
      className={`btn-default ${props.class}`}
      onClick={props.click}
      type="submit"
      style={{
        backgroundColor: props.backgroundColor ?? "var(--backgroundColor2)",
        color: props.color ?? "white",
        fontWeight: props.bold ? "bold" : "normal",
        width: props.width,
        marginRight: "10px",
        marginLeft: "10px",
      }}
      data-bs-dismiss={props.dataBsDismiss}
      disabled={props.disabled}
    >
      {props.children || props.name}
    </ButtonMantine>
  );
};

export default Button;
