import { FC, useState } from "react";
import Text from "../text/text";
import Button from "../button/button";
import Input from "../input/input";
import { toast } from "react-hot-toast";
import "./footer.css";
import { Textarea } from "@mantine/core";

const Footer: FC = () => {
  const [showTicket, setShowTicket] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [addClass, setAddClass] = useState("open");

  // const handleScroll = async (e: FormEvent) => {
  //   e.preventDefault();
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // };

  const handleClick = () => {
    setShowTicket((s) => !s);
    setAddClass("open");
  };

  const handleSendTicket = () => {
    setEmail("");
    setMessage("");
    if (email == "" || message == "") {
      toast.error("Campo e-mail e mensagem são obrigatórios para contato com o suporte.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return;
    }
    setAddClass("done");
  };

  return (
    <>
      <Button class="ticketButton" click={handleClick} name={<i className="fa-regular fa-comment-dots"></i>} />
      <div className={showTicket ? "ticketContainer active" : "ticketContainer inactive"}>
        <div className="ticketTitle">
          <Text>Entre em contato com o suporte</Text>
          <i className="fa-solid fa-xmark ticketClose" onClick={() => setShowTicket(false)}></i>
        </div>
        <hr />
        <div className={addClass == "open" ? "ticketContent active" : "inactive"}>
          <Text class="titleTicketInput" color="var(--blackAll)">
            Seu e-mail
          </Text>
          <Input
            class="ticketEmailInput"
            width="100%"
            type="text"
            value={email}
            change={(e) => setEmail(e.target.value)}
          />
          <Text class="titleTicketInput" color="var(--blackAll)">
            Descreva sua mensagem para nós
          </Text>
          <Textarea rows={4} maxLength={200} value={message} onChange={(e) => setMessage(e.target.value)}></Textarea>
          <hr />
          <Button name="enviar" click={handleSendTicket} />
        </div>
        <div className={addClass == "done" ? "ticketDone active" : "inactive"}>
          Agradecemos pelo seu contato, retornaremos em breve! Acompanhe o andamento do seu ticket pelo e-mail ou portal
          de administração.
          <div className="text-center">
            <i className="fa-solid fa-circle-check"></i>
          </div>
          {/* <Text class="logoMini">ELODIN</Text> */}
          <img src="logo-dark.png" alt="efi" width="100px" height="20px" />
        </div>
      </div>
      <div className="footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {/* <Text size="14px">Desenvolvido por</Text> */}
          <img src="/logo-dark.png" alt="efi" width="80px" height="18px" />
          <Text size="13px">Versão 1.0.0</Text>
        </div>
      </div>
    </>
  );
};

export default Footer;
