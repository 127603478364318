import { FC, useState, ChangeEvent, MouseEvent, useEffect, Fragment } from "react";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer";
import Text from "../../components/text/text";
import Loading from "../../components/loading/loading";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import "./sale.css";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import {
  formatCellphone,
  formatCpf,
  validateCPF,
  validateEmail,
  validateRequired,
  validateString,
} from "../../validations/validations";
import axios from "axios";
import * as Models from "../../models/index";
import { formatPrice } from "../../utils";

const SaleFinish: FC = () => {
  useAuth();
  const [user] = useClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientSearch, setClientSearch] = useState<string>("");
  const [clientNameTitle, setClientNameTitle] = useState<string>("");
  const [clientId, setClientId] = useState<number | null>(null);
  const [clientName, setClientName] = useState<string>("");
  const [clienteEmail, setClientEmail] = useState<string>("");
  const [clientCpf, setClientCpf] = useState<string>("");
  const [clientCellphone, setClientCellphone] = useState<string>("");
  const [clients, setClients] = useState<Array<Models.Client>>([]);
  const [paymentOptions, setPaymentOptions] = useState<Array<Models.PaymentOptions>>([]);
  const [cartProduct, setCartProduct] = useState<any>([]);
  const [saleTotalPrice, setSaleTotalPrice] = useState<number>(0);
  const [saleCoupon, setSaleCoupon] = useState<string>("");
  const [salePayment, setSalePayment] = useState<number>(0);
  const [showModalNewClient, setShowModalNewClient] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>();

  const handleClose = (type: string) => {
    if (type === "modalNewClient") setShowModalNewClient(false);
    setClientName("");
    setClientEmail("");
    setClientCpf("");
    setClientCellphone("");
  };

  const handleShow = (type: string) => {
    if (type === "modalNewClient") setShowModalNewClient(true);
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setClientCpf(cpf);
  };

  const handleChangeCelphone = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const celphone = formatCellphone(rawValue);
    setClientCellphone(celphone);
  };

  const handleSubmit = async () => {
    if (
      !validateRequired({
        Nome: clientName,
      })
    ) {
      return;
    }
    if (!validateString(clientName, 4, 100)) return;
    if (clienteEmail && !validateEmail(clienteEmail)) return;
    if (clientCpf && !validateCPF(clientCpf)) return;

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/client/new-client/environment/${user.environmentId}`,
        {
          name: clientName,
          email: clienteEmail,
          cpf: clientCpf,
          cellphone: clientCellphone,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshData();
      toast.success("Cliente cadastrado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
        // icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }

    setClientName("");
    setClientEmail("");
    setClientCpf("");
    setClientCellphone("");
    setShowModalNewClient(false);
  };

  const handleSubmitSale = async () => {
    console.log("/sale/new-sale/environment/2", {
      clientId: clientId,
      paymentOptionId: salePayment,
      cartId: id,
    });

    if (
      !validateRequired({
        "Forma de pagamento": salePayment,
        Layout: id,
        Total: saleTotalPrice,
      })
    ) {
      return;
    }

    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      await axios.post(
        process.env.REACT_APP_API_URL + `/sale/new-sale/environment/${userStorage.environmentId}`,
        {
          clientId: clientId,
          paymentOptionId: salePayment,
          cartId: parseInt(id ?? "0"),
          total: saleTotalPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setCartProduct([]);
      setClientNameTitle("");
      setSaleTotalPrice(0);
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Venda finalizada com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
        // icon: "👏",
      });
      navigate("/sale-personalized");
    } catch (error: any) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleClient = (clientId: number) => {
    setClientId(clients.find((e) => e.id === clientId)?.id ?? 0);
    setClientNameTitle(clients.find((e) => e.id === clientId)?.name ?? "");
    setClientSearch("");
  };

  const handleClients = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const clientsData = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-clients/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setClients(clientsData.data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handlePaymentOptions = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const paymentOptionsData = await axios.get(`${process.env.REACT_APP_API_URL}/sale/get-payment-options`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setPaymentOptions(paymentOptionsData.data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleCart = async (id: number) => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const cartData = await axios.get(
        `${process.env.REACT_APP_API_URL}/sale-personalized/get-cart/${id}/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setCartProduct(cartData.data);
      setSaleTotalPrice(cartData.data.total);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleData = async () => {
    await handleClients();
    await handlePaymentOptions();
    if (id) await handleCart(parseInt(id));
  };

  const refreshData = async () => {
    await handleClients();
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <Nav user={user} />
      <div className="saleContainer">
        <div className="saleChildren">
          <div className="saleChildrenClientFilter">
            <div>
              <div
                className={
                  cartProduct && cartProduct.status === "busy"
                    ? "d-flex justify-content-center align-items-center gap-2 modalCustom"
                    : "d-flex justify-content-center align-items-center gap-2 modalCustomOp"
                }>
                <Text>Venda: </Text>
                <div className="sonarWrapper">
                  <div className="sonarEmitter">
                    <div className="sonarWave"></div>
                  </div>
                </div>
                {cartProduct && cartProduct.type?.toUpperCase()} - {cartProduct && cartProduct.typeCode}
              </div>
              <div>Cliente: {clientNameTitle ?? ""}</div>
            </div>
            <div className="clientContent">
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar cliente"
                  value={clientSearch}
                  change={(e) => setClientSearch(e.target.value)}
                  focus={(e) => setClientSearch(e.target.value)}
                />
                {clientSearch && (
                  <div className="clientSearchDropdown">
                    <ul>
                      {clients &&
                        clients
                          .filter(
                            (e) =>
                              e.name?.toUpperCase().includes(clientSearch) ||
                              e.name?.toLowerCase().includes(clientSearch) ||
                              clientSearch === ""
                          )
                          .map((item: Models.Client) => {
                            return (
                              <li
                                key={item.id}
                                value={item.id}
                                onClick={(e: MouseEvent<HTMLLIElement>) => handleClient(e.currentTarget.value)}>
                                {item.name}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                )}
              </div>
              <Button
                name="novo cliente"
                click={() => {
                  handleShow("modalNewClient");
                }}
              />
            </div>
          </div>
          <hr />
          <div className="saleContent">
            <div className="saleContentProductsSale">
              <div className="tableCustomProductsSale">
                <Table hover responsive className="hideResponsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Preço unitário</th>
                      <th>Quantidade</th>
                      <th>Preço total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartProduct.cart?.cartProduct &&
                      cartProduct.cart?.cartProduct.map((iterator: any, index: number) => {
                        return (
                          <tr key={iterator.id}>
                            <td>{index + 1}</td>
                            <td>{iterator.product.code}</td>
                            <td>{iterator.product.name}</td>
                            <td>{formatPrice(iterator.product.unitaryPrice ?? "0")}</td>
                            <td>{iterator.quantity}</td>
                            <td>
                              {(iterator.quantity ?? 0) * iterator.product.unitaryPrice
                                ? formatPrice((iterator.quantity ?? 0) * iterator.product.unitaryPrice)
                                : "R$ 0,00"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {/* <Table hover responsive className="showResponsive fontResponsive">
                  <tbody>
                    {cartProduct &&
                      cartProduct.length > 0 &&
                      cartProduct.map((iterator: Models.Product) => {
                        return (
                          <Fragment key={iterator.id}>
                            <tr>
                              <th>Código</th>
                              <td>{iterator.code}</td>
                            </tr>
                            <tr>
                              <th>Nome</th>
                              <td>{iterator.name}</td>
                            </tr>
                            <tr>
                              <th>Preço unitário</th>
                              <td>{formatPrice(iterator.unitaryPrice ?? "0")}</td>
                            </tr>
                            <tr>
                              <th>Quantidade</th>
                              <td>
                                <input
                                  className="quantity"
                                  type="text"
                                  maxLength={4}
                                  value={productQuantity[iterator.id]?.quantity ?? ""}
                                  onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleCalculatePrice(e.target.value, iterator.id)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Preço total</th>
                              <td>
                                {productQuantity[iterator.id]?.quantity ?? 0 * iterator.unitaryPrice
                                  ? formatPrice(
                                      parseInt(productQuantity[iterator.id]?.quantity ?? "0") *
                                        iterator.unitaryPrice
                                    )
                                  : "R$ 0,00"}
                              </td>
                            </tr>
                            <tr>
                              <th></th>
                              <td>
                                <i
                                  className="fa-solid fa-circle-minus trashMinus"
                                  onClick={() => handleRemoveProduct(iterator.id)}
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <hr />
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                  </tbody>
                </Table> */}
              </div>
            </div>
            <div className="saleContentPayment">
              {/* <Text size="20px">Resumo</Text>
              <hr /> */}
              <div className="saleContentPaymentTotal">
                <div>
                  <Text size="28px">Total</Text>
                </div>
                <div>
                  <Text color="green" size="28px" bold={true}>
                    {saleTotalPrice && saleCoupon
                      ? formatPrice(saleTotalPrice - parseInt(saleCoupon))
                      : saleTotalPrice
                      ? formatPrice(saleTotalPrice)
                      : "R$ 0,00"}
                  </Text>
                </div>
              </div>
              <div className="saleContentPaymentDiscount">
                <div>
                  <Text size="20px">Desconto</Text>
                </div>
                <div>
                  -{" "}
                  <input
                    className="quantity"
                    type="text"
                    maxLength={5}
                    value={saleCoupon}
                    onChange={(c) => setSaleCoupon(c.target.value)}
                  />
                </div>
              </div>
              <hr />
              <Text size="24px">Pagamento</Text>
              <div className="paymentMethodList">
                {paymentOptions &&
                  paymentOptions.map((item: Models.PaymentOptions) => {
                    return (
                      <div
                        key={item.id}
                        className={salePayment === item.id ? "paymentSelected method" : "method"}
                        onClick={() => setSalePayment(item.id)}>
                        <div>
                          <i className={item.icon}></i>
                        </div>
                        <Text>{item.name}</Text>
                      </div>
                    );
                  })}
              </div>
              <hr />
              <div className="text-center">
                <Button name="voltar venda" click={() => navigate("/sale-personalized")} />
                <Button name="finalizar venda" click={handleSubmitSale} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalNewClient} onHide={() => handleClose("modalNewClient")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Novo cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text color="var(--blackAll)" bold={true} class="d-block">
            Nome* <Text size="14px">obrigatório</Text>
          </Text>
          <Input type="text" value={clientName} change={(e) => setClientName(e.target.value?.toUpperCase())} />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            E-mail <Text size="14px">opcional</Text>
          </Text>
          <Input type="text" value={clienteEmail} change={(e) => setClientEmail(e.target.value)} />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            CPF <Text size="14px">opcional</Text>
          </Text>
          <Input
            class="inputHalf"
            type="text"
            // placeholder="000.000.000-00"
            value={clientCpf}
            change={handleChangeCpf}
          />
          <Text color="var(--blackAll)" bold={true} class="d-block">
            Celular <Text size="14px">opcional</Text>
          </Text>
          <Input class="inputHalf" type="text" maxLength={14} value={clientCellphone} change={handleChangeCelphone} />
        </Modal.Body>
        <Modal.Footer>
          <Button name="salvar" click={handleSubmit} />
        </Modal.Footer>
      </Modal>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default SaleFinish;
