import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const navigate = useNavigate();

  const authenticated = () => {
    const storage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : false;
    console.log("authenticated?", storage);
    if (!storage) return navigate("/login");
  };

  useEffect(() => {
    authenticated();
  }, []);
};

export default useAuth;
