// src/contexts/WebSocketContext.tsx
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

interface WebSocketContextType {
  socket: Socket | null;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) throw new Error("useWebSocket must be used within a WebSocketProvider");
  return context;
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
    // if (!window.location.pathname.includes("/login")) return;
    if (!userStorage?.token) return;
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/get-user/${userStorage.id}`, {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        })
        .then(() => {
          const newSocket = io(`${process.env.REACT_APP_WEBSOCKET_URL}`, {
            auth: {
              token: userStorage.token,
              environmentId: userStorage.environmentId,
            },
          });

          setSocket(newSocket);

          console.log("websocket connected");

          return () => {
            newSocket.close();
          };
        })
        .catch(() => {
          return;
        });
      return;
    } catch (error) {
      return;
    }
  }, []);

  return <WebSocketContext.Provider value={{ socket }}>{children}</WebSocketContext.Provider>;
};
