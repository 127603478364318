import { FC, ChangeEvent } from "react";
import { TextInput as TextInputMantine } from "@mantine/core";
import "./input.css";

type Props = {
  type: string;
  label?: string;
  description?: string;
  id?: string;
  placeholder?: string;
  value?: string | number;
  width?: string;
  height?: string;
  class?: string;
  bold?: boolean;
  maxLength?: number;
  change?: (e: ChangeEvent<HTMLInputElement>) => void;
  load?: (e: ChangeEvent<HTMLInputElement>) => void;
  focus?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
  rightSection?: JSX.Element;
};

const Input: FC<Props> = ({ ...props }: Props) => {
  return (
    <TextInputMantine
      className={`inputComponent ${props.class}`}
      style={{
        width: props.width ?? "80%",
        height: props.height,
        fontWeight: props.bold ? "bold" : "normal",
        fontSize: "20px",
      }}
      label={props.label}
      description={props.description}
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.change}
      onLoad={props.load}
      onFocus={props.focus}
      maxLength={props.maxLength ?? 100}
      disabled={props.disabled}
      name={props.name}
      checked={props.checked}
      rightSection={props.rightSection}
    />
  );
};

export default Input;
