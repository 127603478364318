import { FC, FormEvent, ChangeEvent, Fragment } from "react";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading/loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import "./login.css";
import { formatCpf, validateCPF, validateRequired } from "../../validations/validations";
import { changeUserData, selectUserData } from "../../redux/userSlice";
import { changeLoading, selectLoading } from "../../redux/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { PasswordInput } from "@mantine/core";

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(selectUserData).data;
  const loadingState = useSelector(selectLoading);

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    dispatch(changeUserData({ ...userState, cpf: cpf }));
  };

  const handleChangePassword = (password: string) => {
    dispatch(changeUserData({ ...userState, password: password }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !validateRequired({
        CPF: userState.cpf,
        Senha: userState.password,
      })
    ) {
      return;
    }
    if (!validateCPF(userState.cpf)) return;
    try {
      dispatch(changeLoading({ ...loadingState, loading: true }));
      const response = await axios.post(process.env.REACT_APP_API_URL + "/user/signin", {
        cpf: userState.cpf,
        password: userState.password,
      });
      localStorage.setItem("user", JSON.stringify(response.data));
      setTimeout(() => {
        dispatch(changeLoading({ ...loadingState, loading: false }));
      }, 2000);
      dispatch(changeUserData({ ...userState, cpf: "" }));
      dispatch(changeUserData({ ...userState, password: "" }));
      navigate("/environments");
      toast("Bem-vindo(a) ao ELODIN", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#cce5ff", color: "#004085" },
        icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        dispatch(changeLoading({ ...loadingState, loading: false }));
      }, 2000);
    }
  };

  return (
    <>
      <div className="containerCustom">
        <div className="loginContainer">
          <img src="logo-e-alpha.png" alt="efi" width="100px" height="100px" />
          <i>Login</i>
          <hr />
          <div style={{ width: "80%" }}>
            <Text color="var(--blackAll)" bold={true}>
              CPF*{" "}
              <Text class="textResponsive" size="14px">
                obrigatório
              </Text>
            </Text>
          </div>
          <Input type="text" value={userState.cpf} change={handleChangeCpf} />
          <div style={{ width: "80%" }}>
            <Text color="var(--blackAll)" bold={true}>
              Senha*{" "}
              <Text class="textResponsive" size="14px">
                obrigatório
              </Text>
            </Text>
          </div>
          <div style={{ width: "80%" }}>
            <PasswordInput
              maxLength={20}
              width="100%"
              value={userState.password}
              onChange={(e) => handleChangePassword(e.target.value)}
            />
          </div>

          <div style={{ width: "80%", fontSize: "14px" }}>
            <a href="/forgot-password">Esqueci a senha</a>
          </div>
          <div style={{ width: "80%", margin: "6% 0%", textAlign: "center" }}>
            <Button name="entrar" click={handleSubmit} />
          </div>
          <Text size="12px" class="info">
            Nos compremetemos com a segurança de seus dados, sua senha é pessoal e intransferível, não compartilhe com
            ninguém.
          </Text>
          <div>
            <Text size="16px">Não é nosso cliente?</Text>
            <span> </span>
            <a href="/landing-page">Ir para planos</a>
          </div>
        </div>
      </div>
      {loadingState.loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Login;
