import { FC, useEffect, useState } from "react";
import Nav from "../../components/nav/nav";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Loading from "../../components/loading/loading";
import Footer from "../../components/footer/footer";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import "./schedule.css";
import moment from "moment";
import Input from "../../components/input/input";

const Schedules: FC = () => {
  useAuth();
  const [user] = useClient();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Nav user={user} />
      <div className="saleCheckoutContainer">
        <div className="saleCheckoutChildren">
          <div className="d-flex justify-content-between align-items-center">
            <Text size="24px">Seus agendamentos</Text>
            <div>
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar"
                  // value={productFiltered}
                  // change={(e) => setProductFiltered(e.target.value)}
                  // focus={(e) => setProductFiltered(e.target.value)}
                />
                <Button
                  name={<i className="fa-solid fa-magnifying-glass hideResponsive"></i>}
                  // click={handleFilteredProducts}
                ></Button>
              </div>
              <hr />
            </div>
          </div>
          <hr />
          <div className="tableModalSchedule">
            <Table hover responsive className="hideResponsive">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Horário</th>
                  <th>Profissional</th>
                  <th>Serviço</th>
                  <th>Preço</th>
                  <th>Status</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10/04/2024</td>
                  <td>08:00</td>
                  <td>CLEUSA</td>
                  <td>#5266 - ESCOVA</td>
                  <td>R$ 49,99</td>
                  <td>ATIVO</td>
                  <td>
                    <i
                      className="fa-solid fa-circle-check"
                      style={{
                        color: "green",
                      }}
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>02/04/2024</td>
                  <td>08:00</td>
                  <td>CLEUSA</td>
                  <td>#5266 - ESCOVA</td>
                  <td>R$ 49,99</td>
                  <td>FINALIZADO</td>
                  <td>
                    <i
                      className="fa-solid fa-circle-check"
                      style={{
                        color: "green",
                      }}
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>02/04/2024</td>
                  <td>10:00</td>
                  <td>CLEUSA</td>
                  <td>#5266 - ESCOVA</td>
                  <td>R$ 49,99</td>
                  <td>CANCELADO</td>
                  <td>
                    <i className="fa-solid fa-circle-minus trashMinus"></i>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Schedules;
