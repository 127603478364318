import { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import "./landingPage.css";
import { formatNumber } from "../../utils";

const LandingPage: FC = () => {
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <>
      <Navbar className="navDefault">
        <Container className=" navCustomContainer">
          <Navbar.Brand href="/landing-page">
            {/* <img src="logo-e-alpha.png" alt="efi" width="60px" height="60px" /> */}
            <img src="logo-e-alpha.png" alt="efi" width="60px" height="60px" />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end navCustom">
            <Button name="login" click={handleLogin} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="landingPageContainerTest">
        {/* <div className="landingPageTitle">
          <h1 className="landingPageTitleTest">Simplicidade Desempenho</h1>
        </div>
        <div className="landingPageSubtitle">
          <Text size="29px" class="titlee">
            <i>Seus negócios, uma plataforma de vendas.</i>
          </Text>
        </div> */}
      </div>
      {/* <hr /> */}
      <div className="landingPageContainerMiddle">
        <div className="landingPagePlans">
          <div className="landingPagePlans">
            {[
              {
                id: 1,
                name: "Gratuito",
                price: 0,
                type: "free",
                star: 1,
                features: [
                  "Gestão de vendas e clientes",
                  "Gestão de colaboradores",
                  "Estoque de produtos e serviços",
                  "Relatórios",
                  "Notificações por e-mail e SMS",
                ],
              },
              {
                id: 2,
                name: "Básico",
                price: 19.99,
                type: "basic",
                star: 2,
                features: [
                  "Gestão de vendas e clientes",
                  "Gestão de colaboradores",
                  "Emissão de nota fiscal*",
                  "Cobrança automatizada no caixa",
                  "Estoque de produtos e serviços",
                  "Relatórios",
                  "Notificações por e-mail e SMS",
                  "Integração Ifood",
                  "Suporte via e-mail, sujeito a horário comercial*",
                ],
              },
              {
                id: 3,
                name: "Avançado",
                price: 39.99,
                type: "advanced",
                star: 3,
                features: [
                  "Gestão de vendas e clientes",
                  "Gestão de colaboradores",
                  "Emissão de nota fiscal",
                  "Cobrança automatizada no caixa",
                  "Estoque de produtos e serviços",
                  "Relatórios",
                  "Notificações por e-mail e SMS",
                  "Integração Ifood",
                  "Sistema de agendamento direto com o cliente",
                  "Suporte via e-mail, sujeito a horário comercial*",
                ],
              },
            ].map((plan) => {
              return (
                <Card className="cardPlanCustom">
                  <Card.Header className="cardHeaderPlanCustom">
                    <div>
                      <Text size="26px" bold={true}>
                        {plan.name}
                      </Text>{" "}
                      <i>{plan.type === "free" ? "30 dias" : "mensal"}</i>
                    </div>
                    <div className="planPriceCustom">
                      {plan.price ? (
                        <>
                          <span style={{ fontSize: "14px" }}>R$</span> {formatNumber(plan.price)}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {plan &&
                      plan.features.map((iterator: string) => {
                        return (
                          <div className="cardBodyCustom">
                            <i className="fa-solid fa-check" style={{ color: "green" }}></i> {iterator}
                          </div>
                        );
                      })}
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button name="comece aqui" click={() => navigate(`/register/plan/${plan.id}`)} />
                  </Card.Footer>
                </Card>
              );
            })}
          </div>
        </div>
        <hr />
        <span className="landingPageEnd">
          <div>
            O que é o ELODIN? <i className="fa-solid fa-circle-info"></i>
          </div>
          <div>Enviar mensagem ao suporte</div>
          <div>Termos</div>
        </span>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
