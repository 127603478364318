import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Toast from "../components/toast/toast";
import {
  Login,
  Home,
  LandingPage,
  Register,
  Registration,
  Products,
  Administration,
  NotFound,
  Environment,
  Sale,
  Report,
  SalePersonalized,
  SaleFinish,
  NewPassword,
  ForgotPassword,
  ChangePassword,
  Schedule,
  Schedules,
} from "../pages";

const Router: FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route path="/register/plan/:id" element={<Register />} />
          <Route path="/registration/plan/:id" element={<Registration />} />
          <Route path="/products" element={<Products />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/environments" element={<Environment />} />
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route path="/change-password/:token" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/report" element={<Report />} />
          <Route path="/sale-finish/cart/:id" element={<SaleFinish />} />
          <Route path="/sale-personalized" element={<SalePersonalized />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/schedules" element={<Schedules />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Toast />
    </>
  );
};

export default Router;
