import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useClient = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [environments, setEnvironments] = useState<any>();

  const handleUser = async () => {
    const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : false;
    try {
      setUser(userStorage);
      const environmentsData = await axios.get(`${process.env.REACT_APP_API_URL}/environment/get-envs`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setEnvironments(environmentsData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  useEffect(() => {
    handleUser();
  }, []);

  return [user, environments, handleUser];
};

export default useClient;
