import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "paymentOptions",
  initialState: {
    data: {
      payments: [],
    },
  },
  reducers: {
    changePaymentOptions(state: any, { payload }) {
      return { ...state, data: payload };
    },
  },
});

export const { changePaymentOptions } = slice.actions;

export const selectPaymentOptions = (state: any) => state.paymentOptions;

export default slice.reducer;
