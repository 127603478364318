import { FC, ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Input from "../../components/input/input";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Container from "react-bootstrap/Container";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import Navbar from "react-bootstrap/Navbar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { toast } from "react-hot-toast";
import {
  validateCPF,
  validateCNPJ,
  validateEmail,
  validateRequired,
  formatCnpj,
  formatCpf,
  formatCardNumber,
  formatCardExp,
  formatCellphone,
} from "../../validations/validations";
import "./registration.css";
import axios from "axios";
import moment from "moment";
import { formatNumber, formatPrice } from "../../utils";
import { Anchor, Checkbox } from "@mantine/core";

const Registration: FC = () => {
  useAuth();
  const [user] = useClient();
  const [cnpj, setCnpj] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [fantasyName, setFantasyName] = useState<string>("");
  const [companySituation, setCompanySituation] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [cellphone, setCellphone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [plan, setPlan] = useState<any>();
  const [planId, setPlanId] = useState<any>();
  const [environmentName, setEnvironmentName] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardExp, setCardExp] = useState<string>("");
  const [cardCvv, setCardCvv] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [complement, setComplement] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("registration");
  const navigate = useNavigate();
  let { id } = useParams();
  const search = (
    <i style={{ cursor: "pointer" }} className="fa-solid fa-magnifying-glass" onClick={() => searchCnpj(cnpj)}></i>
  );

  const searchCEP = (
    <i style={{ cursor: "pointer" }} className="fa-solid fa-magnifying-glass" onClick={handleGetZipCode}></i>
  );

  const handleChangeCNPJ = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const formattedCnpj = formatCnpj(rawValue);
    setCnpj(formattedCnpj);
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setCpf(cpf);
  };

  const handleChangeCellphone = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cellphone = formatCellphone(rawValue);
    setCellphone(cellphone);
  };

  const handleChangeCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cardNumber = formatCardNumber(rawValue);
    setCardNumber(cardNumber);
  };

  const handleChangeCardExp = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cardExp = formatCardExp(rawValue);
    setCardExp(cardExp);
  };

  const searchCnpj = async (cnpj: string) => {
    const cnpjFormatted = cnpj.replace(/[^\d]/g, "");

    try {
      const response = await axios.get(process.env.REACT_APP_API_CNPJ + cnpjFormatted);

      if (response.data?.status === "ERROR") {
        toast.error("CNPJ inválido.", {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "#f8d7da", color: "crimson" },
        });
        return;
      }

      if (response.data?.situacao === "BAIXADA") {
        toast.error("Empresa com situação BAIXADA, por favor insina um novo CNPJ.", {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "#f8d7da", color: "crimson" },
        });
        return;
      }

      setCompanySituation(response.data.situacao);
      setCompanyName(response.data.nome);
      setFantasyName(response.data.fantasia);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    console.log("handleSubmit", {
      cnpj,
      companyName,
      fantasyName,
      planId: parseInt(planId),
      environmentName,
      name,
      email,
      cpf,
      number: cardNumber,
      expMonth: cardExp.split("/")[0],
      expYear: cardExp.split("/")[1],
      cvv: cardCvv,
      cellphone: cellphone.replace(/\D/g, "").substring(2),
      countryCode: 55,
      areaCode: cellphone.slice(1, 3),
      cep,
      street,
      num: number,
      district,
      complement,
      city,
      state,
    });
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/environment/new-environment",
        {
          cnpj,
          companyName,
          fantasyName,
          planId: parseInt(planId),
          environmentName,
          name,
          email,
          cpf,
          number: cardNumber,
          expMonth: cardExp.split("/")[0],
          expYear: cardExp.split("/")[1],
          cvv: cardCvv,
          cellphone: cellphone.replace(/\D/g, "").substring(2),
          countryCode: 55,
          areaCode: cellphone.slice(1, 3),
          cep,
          street,
          num: number,
          district,
          complement,
          city,
          state,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      if (userStorage) {
        userStorage.role = "administrator";
        localStorage.setItem("user", JSON.stringify(userStorage));
      }
      setCnpj("");
      setCompanyName("");
      setFantasyName("");
      setName("");
      setCpf("");
      setEmail("");
      setCardNumber("");
      setCardExp("");
      setCardCvv("");
      setSaveCard(false);
      navigate("/environments");
      toast("Bem-vindo(a) ao ELODIN", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#cce5ff", color: "#004085" },
        icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handlePlans = (e: FormEvent) => {
    e.preventDefault();
    navigate("/landing-page");
  };

  const handleCopyClient = (checked: boolean) => {
    if (checked) {
      setName(user.name);
      setCpf(formatCpf(user.cpf));
    } else {
      setName("");
      setCpf("");
    }
  };

  const handleValidationEnvironment = () => {
    if (
      !validateRequired({
        CNPJ: cnpj,
        "Razão social": companyName,
        "Nome do ambiente": environmentName,
      })
    ) {
      return;
    }

    if (!validateCNPJ(cnpj)) return;

    setActiveTab("payment");
  };

  const handleValidationPayment = () => {
    if (plan && plan.type !== "free") {
      for (const element of Object.entries({
        "Nome do titular": name,
        "CPF do titular": cpf,
        "E-mail": email,
        "Número do cartão de crédito": cardNumber,
        Validade: cardExp,
        CVV: cardCvv,
        "Termos de uso e Política de privacidade": saveCard.toString(),
      })) {
        if (element[1] === "") {
          toast.error(`Campo ${element[0]} é obrigatório.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }

        if (element[0] === "CPF do titular" && !validateCPF(element[1])) return;
        if (element[0] === "E-mail" && !validateEmail(element[1])) return;

        if (element[0] === "Número do cartão de crédito" && element[1].length < 16) {
          toast.error(`Campo ${element[0]} inválido.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }

        let cardExpSplit;
        if (element[0] === "Validade") {
          cardExpSplit = parseInt(element[1].split("/")[1]) + 2000 + "-" + element[1].split("/")[0];
        }
        if (element[0] === "Validade" && moment().diff(moment(cardExpSplit), "days") > 0) {
          toast.error(`Campo ${element[0]} inválido.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }

        if (element[0] === "CVV" && element[1].length < 3) {
          toast.error(`Campo ${element[0]} inválido.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }

        if (element[0] === "Termos de uso e Política de privacidade" && !saveCard) {
          toast.error(`Campo Termos de uso e Política de privacidade é obrigatório.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }
      }
    } else {
      for (const element of Object.entries({
        "Nome do titular": name,
        "CPF do titular": cpf,
        "E-mail": email,
        "Termos de uso e Política de privacidade": saveCard.toString(),
      })) {
        if (element[1] === "") {
          toast.error(`Campo ${element[0]} é obrigatório.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }

        if (element[0] === "CPF do titular" && !validateCPF(element[1])) return;
        if (element[0] === "E-mail" && !validateEmail(element[1])) return;

        if (element[0] === "Termos de uso e Política de privacidade" && !saveCard) {
          toast.error(`Campo Termos de uso e Política de privacidade é obrigatório.`, {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          });
          return;
        }
      }
    }

    setActiveTab("finish");
  };

  const handlePlan = async (id: string) => {
    try {
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const response = await axios.get(process.env.REACT_APP_API_URL + "/plan/get-plan/" + id, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setPlan(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleGetZipCode() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CEP + cep.replace(/[^\d]/g, "") + "/json");
      if (response.data.erro) throw new Error();

      setStreet(response.data.logradouro);
      setDistrict(response.data.bairro);
      setCity(response.data.localidade);
      setState(response.data.uf);
    } catch (error: any) {
      toast.error(`Erro ao buscar o CEP.`, {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
    }
  }

  useEffect(() => {
    if (id) {
      setPlanId(id);
      handlePlan(id);
    }
  }, [id]);

  return (
    <>
      <Navbar className="navCustomAll">
        <Container className="navCustomContainer">
          <Navbar.Brand>
            <img src="/logo-e-alpha.png" alt="efi" width="60px" height="60px" />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end navCustom">
            <Button name="Página inicial" click={handlePlans} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Tabs
        defaultActiveKey="registration"
        transition={false}
        id="noanim-tab-example"
        className="mb-3 tabCustom"
        activeKey={activeTab}
      >
        <Tab
          eventKey="plans"
          title={<span onClick={() => setActiveTab("plans")}>{/*<i className="fa-solid fa-circle"></i>*/} Plano</span>}
        >
          <div className="formContainer">
            <Text class="titleForm" color="var(--blackAll)" size="40px" bold={true}>
              Bem vindo(a)! Escolha seu plano
            </Text>
            <div className="landingPagePlans">
              {[
                {
                  id: 1,
                  name: "Gratuito",
                  price: 0,
                  type: "free",
                  star: 1,
                  features: [
                    "Gestão de vendas e clientes",
                    "Gestão de colaboradores",
                    "Estoque de produtos e serviços",
                    "Relatórios",
                    "Notificações por e-mail e SMS",
                  ],
                },
                {
                  id: 2,
                  name: "Básico",
                  price: 19.99,
                  type: "basic",
                  star: 2,
                  features: [
                    "Gestão de vendas e clientes",
                    "Gestão de colaboradores",
                    "Emissão de nota fiscal*",
                    "Cobrança automatizada no caixa",
                    "Estoque de produtos e serviços",
                    "Relatórios",
                    "Notificações por e-mail e SMS",
                    "Integração Ifood",
                    "Suporte via e-mail, sujeito a horário comercial*",
                  ],
                },
                {
                  id: 3,
                  name: "Avançado",
                  price: 39.99,
                  type: "advanced",
                  star: 3,
                  features: [
                    "Gestão de vendas e clientes",
                    "Gestão de colaboradores",
                    "Emissão de nota fiscal",
                    "Cobrança automatizada no caixa",
                    "Estoque de produtos e serviços",
                    "Relatórios",
                    "Notificações por e-mail e SMS",
                    "Integração Ifood",
                    "Sistema de agendamento direto com o cliente",
                    "Suporte via e-mail, sujeito a horário comercial*",
                  ],
                },
              ].map((plan, index) => {
                return (
                  <Card
                    className={
                      planId && planId == plan.id.toString()
                        ? "cardPlanCustom cardPlanCustomSelected"
                        : "cardPlanCustom"
                    }
                    key={index}
                  >
                    <Card.Header className="cardHeaderPlanCustom">
                      <div>
                        <Text size="26px" bold={true}>
                          {plan.name}
                        </Text>{" "}
                        <i>{plan.type === "free" ? "30 dias" : "mensal"}</i>
                      </div>
                      <div className="planPriceCustom">
                        {plan.price ? (
                          <>
                            <span style={{ fontSize: "14px" }}>R$</span> {formatNumber(plan.price)}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {plan &&
                        plan.features.map((iterator: string, index) => {
                          return (
                            <div className="cardBodyCustom" key={index}>
                              <i className="fa-solid fa-check" style={{ color: "green" }}></i> {iterator}
                            </div>
                          );
                        })}
                      <div
                        className={
                          planId && planId == plan.id.toString() ? "startHere buttonPlanSelected" : "startHere"
                        }
                      ></div>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button
                        name={planId && planId == plan.id.toString() ? "selecionado" : "comece aqui"}
                        click={() => {
                          navigate("/registration/plan/" + plan.id.toString());
                          setActiveTab("registration");
                        }}
                      />
                    </Card.Footer>
                  </Card>
                );
              })}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="registration"
          title={
            <span onClick={() => setActiveTab("registration")}>
              {/*<i className="fa-solid fa-circle"></i>*/} Cadastro de ambiente
            </span>
          }
        >
          <div className="formContainer">
            <div className="tableRegistration">
              <div className="initialContent">
                <div>
                  <Text color="var(--blackAll)" size="40px" bold={true}>
                    Cadastro de ambiente
                  </Text>
                </div>
                <div>
                  <Button name="Avançar" click={handleValidationEnvironment} />
                </div>
              </div>
              <table style={{ width: "100%" }}>
                <tbody className="tableContainer">
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        CNPJ*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input width="100%" type="text" value={cnpj} change={handleChangeCNPJ} rightSection={search} />
                    </td>
                  </tr>
                  {companySituation && (
                    <tr>
                      <Text size="14px" width="90%">
                        Situação: {companySituation}
                      </Text>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Razão social*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={60}
                        value={companyName}
                        change={(e) => setCompanyName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Nome fantasia{" "}
                        <Text size="14px" width="90%">
                          opcional
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={60}
                        value={fantasyName}
                        change={(e) => setFantasyName(e.target.value)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Nome do estabelecimento*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={50}
                        value={environmentName}
                        change={(e) => setEnvironmentName(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
        <Tab eventKey="payment" title={<span onClick={() => setActiveTab("payment")}> Pagamento</span>}>
          <div className="formPaymentContainer">
            <div className="tablePaymentRegistration">
              <div className="initialContent">
                <div>
                  <Text color="var(--blackAll)" size="20px" bold={true}>
                    Endereço de cobrança
                  </Text>
                </div>
              </div>
              <table style={{ width: "100%" }}>
                <tbody className="tableContainer">
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        CEP*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        rightSection={searchCEP}
                        value={cep}
                        change={(e) => setCep(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Rua*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        value={street}
                        change={(e) => setStreet(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Número*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        value={number}
                        change={(e) => setNumber(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Complemento{" "}
                        <Text size="14px" width="90%">
                          opcional
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        value={complement}
                        change={(e) => setComplement(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Bairro*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        value={district}
                        change={(e) => setDistrict(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ display: "flex", gap: "10px" }}>
                      <div style={{ width: "80%" }}>
                        <Text color="var(--blackAll)" bold={true} class="d-block">
                          Cidade*
                        </Text>
                        <Input
                          width="100%"
                          type="text"
                          maxLength={70}
                          value={district}
                          change={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div style={{ width: "20%" }}>
                        <Text color="var(--blackAll)" bold={true} class="d-block">
                          UF*
                        </Text>
                        <Input
                          width="100%"
                          type="text"
                          maxLength={70}
                          value={state}
                          change={(e) => setState(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tableRegistration">
              <div className="initialContent">
                <div>
                  <Text color="var(--blackAll)" size="40px" bold={true}>
                    Pagamento
                  </Text>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Text color="var(--backgroundColor3)" size="20px" bold={true}>
                      Plano {plan?.name} {plan?.type === "free" ? "30 dias" : "Mensal"}
                    </Text>
                    <Text color="green" size="22px" bold={true}>
                      {plan?.price ? formatPrice(plan.price) : ""}
                    </Text>
                  </div>
                </div>
                <div>
                  <Button name="Avançar" click={handleValidationPayment} />
                </div>
              </div>
              <table style={{ width: "100%" }}>
                <tbody className="tableContainer">
                  <tr>
                    <td>
                      <Checkbox
                        onChange={(e) => handleCopyClient(e.target.checked)}
                        label="Sou o responsável financeiro"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Nome do titular*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={70}
                        value={name}
                        change={(e) => setName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        CPF do titular*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input width="100%" type="text" value={cpf} change={handleChangeCpf} />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        E-mail*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input
                        width="100%"
                        type="text"
                        maxLength={60}
                        value={email}
                        change={(e) => setEmail(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Celular*{" "}
                        <Text size="14px" width="90%">
                          obrigatório
                        </Text>
                      </Text>
                      <Input width="100%" type="text" value={cellphone} change={handleChangeCellphone} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text color="var(--blackAll)" bold={true} class="d-block">
                        Número do cartão de crédito*
                      </Text>
                      <Input
                        disabled={plan?.type === "free"}
                        type="text"
                        placeholder="0000 0000 0000 0000"
                        width="100%"
                        value={cardNumber}
                        change={handleChangeCardNumber}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <Text color="var(--blackAll)" bold={true} class="d-block">
                          Validade*
                        </Text>
                        <Input
                          disabled={plan?.type === "free"}
                          width="100%"
                          type="text"
                          placeholder="00/00"
                          value={cardExp}
                          change={handleChangeCardExp}
                        />
                      </div>
                      <div>
                        <Text color="var(--blackAll)" bold={true} class="d-block">
                          CVV*
                        </Text>
                        <Input
                          disabled={plan?.type === "free"}
                          width="100%"
                          type="number"
                          placeholder="000"
                          value={cardCvv}
                          change={(e) => setCardCvv(e.target.value?.toString()?.slice(0, 3))}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Checkbox
                        onChange={(e) => setSaveCard(e.target.checked)}
                        label={
                          <>
                            Li e concordo com os{" "}
                            <Anchor href="https://elodin.com.br/terms" target="_blank" inherit>
                              Termos de uso e Política de privacidade
                            </Anchor>
                          </>
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
        <Tab eventKey="finish" title={<span onClick={() => setActiveTab("finish")}>Conclusão</span>}>
          <div className="formContainer">
            <div className="tableRegistration">
              <div className="initialContent">
                <div>
                  <Text color="var(--blackAll)" size="40px" bold={true}>
                    Conclusão
                  </Text>
                  <div>
                    <Text class="textFinishResponsive" color="green" size="22px" bold={true}>
                      Obrigado por nos escolher como sua plataforma de vendas, finalize seu pedido e aproveite!
                    </Text>
                  </div>
                </div>
                <div>
                  <Button name="finalizar" click={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
      <Footer />
    </>
  );
};

export default Registration;
