import { ReactNode, FC } from "react";

type Props = {
  children: ReactNode;
  color?: string;
  size?: string;
  class?: string;
  bold?: boolean;
  width?: string;
};

const Text: FC<Props> = ({ ...props }: Props) => {
  return (
    <span
      className={props.class}
      style={{
        color: props.color,
        fontSize: props.size ?? "16px",
        fontWeight: props.bold ? "bold" : "normal",
        // margin: "20px 0",
        width: props.width,
      }}
    >
      {props.children}
    </span>
  );
};

export default Text;
