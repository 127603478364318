import { FC } from "react";
import Button from "../button/button";
import "./timeline.css";

type Props = {
  date: string;
  status: string;
  color: string;
  message?: string;
  messageSupport?: string;
  subject?: string;
  support?: boolean;
};

const Timeline: FC<Props> = ({ ...props }: Props) => {
  return (
    <table className="timelineContainer">
      <tbody>
        <tr>
          <td className="statusTicketButton">
            <span style={{ fontSize: "14px" }}>{props.date}</span>
            <div className="statusTicket ">
              <Button name={props.status} backgroundColor={props.color} disabled />
            </div>
          </td>
          <td>
            <i className="fa-regular fa-clock"></i>
          </td>
          <td>
            <div className={props.support ? "contentSup" : "content"}>
              <span>{props.subject}</span>
              <span className="d-block" style={{ fontSize: "15.8px" }}>
                {props.message ?? <i>{props.messageSupport}</i>}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div className="timeline"></div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default Timeline;
