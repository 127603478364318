import { FC } from "react";
import { Toaster } from "react-hot-toast";

const Toast: FC = () => {
  return (
    <Toaster
    // toastOptions={{
    //   success: { position: "top-left", duration: 5000 },
    //   error: { position: "top-left", duration: 5000 },
    // }}
    />
  );
};

export default Toast;
