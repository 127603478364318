import { FC, FormEvent, ChangeEvent, useState } from "react";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Footer from "../../components/footer/footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import axios from "axios";
import "./register.css";
import { formatCpf, validateName, validatePassword, validateRequired } from "../../validations/validations";
import { Anchor, PasswordInput } from "@mantine/core";

const Register: FC = () => {
  const [name, setName] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handlePlans = async (e: FormEvent) => {
    e.preventDefault();
    navigate("/landing-page");
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    navigate("/login");
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setCpf(cpf);
  };

  const handleSubmit = async () => {
    if (
      !validateRequired({
        Nome: name,
        CPF: cpf,
        Senha: password,
      })
    ) {
      return;
    }
    if (!validateName(name)) return;
    if (!validatePassword(password)) return;

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/user/new-user", {
        name,
        cpf,
        password,
      });
      localStorage.setItem("user", JSON.stringify(response.data));
      navigate(`/registration/plan/${id}`);
      toast("Bem-vindo(a) ao ELODIN", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#cce5ff", color: "#004085" },
        icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  return (
    <div className="containerCustom">
      <div className="loginContainer">
        <img src="/logo-e-alpha.png" alt="efi" width="60px" height="60px" />
        <i>Cadastro</i>
        <hr />
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            Nome*{" "}
            <Text class="textResponsive" size="14px">
              obrigatório
            </Text>
          </Text>
        </div>
        <Input type="text" value={name} change={(e) => setName(e.target.value)} />
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            CPF*{" "}
            <Text class="textResponsive" size="14px">
              obrigatório
            </Text>
          </Text>
        </div>
        <Input type="text" /*placeholder="000.000.000-00"*/ value={cpf} change={handleChangeCpf} />
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            Senha*{" "}
            <Text class="textResponsive" size="14px">
              obrigatório
            </Text>
          </Text>
        </div>
        <div style={{ width: "80%" }}>
          <PasswordInput maxLength={20} width="100%" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <Text size="12px" class="info">
          Seus dados serão utilizados apenas para disponibilização de acesso ao ELODIN. Para saber mais acesse nossos{" "}
          <Anchor href="https://elodin.com.br/terms" target="_blank" inherit>
            Termos de uso e Política de privacidade
          </Anchor>
        </Text>

        <Button name="enviar" click={handleSubmit} />
        <div style={{ marginTop: "40px" }}>
          <Text size="16px">Já tem uma conta?</Text>
          <span> </span>
          <a href="/login">Ir para o login</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
