import Router from "./routes/router";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { WebSocketProvider } from "./contexts/WebSocketContext";

const domNode = document.getElementById("main")!;
const root = createRoot(domNode);
root.render(
  <MantineProvider>
    <WebSocketProvider>
      <Provider store={store}>
        <Router />
      </Provider>
    </WebSocketProvider>
  </MantineProvider>
);
