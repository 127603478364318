import { FC, FormEvent, useState, useEffect, Fragment } from "react";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import PaginationCustom from "../../components/pagination/pagination";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import { toast } from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import "./products.css";
import Loading from "../../components/loading/loading";
import { useNavigate } from "react-router-dom";

type ProductType = {
  id: number | undefined | null | any;
  code: string | undefined | null;
  name: string | undefined | null;
  description: string | undefined | null;
  quantity: number | undefined | null;
  unitaryPrice: number | undefined | null;
  createdAt: string | undefined | null;
  updatedAt: string | undefined | null;
  createdBy: { name: string; id: number };
};

const Products: FC = () => {
  const navigate = useNavigate();
  useAuth();
  const [user] = useClient();
  const [showModalProduct, setShowModalProduct] = useState<boolean>(false);
  const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalRemove, setShowModalRemove] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [productFiltered, setProductFiltered] = useState<string>("");
  const [quantity, setQuantity] = useState<number | string>("");
  const [unitaryPrice, setUnitaryPrice] = useState<number | string>("");
  const [productId, setProductId] = useState<number>();
  const [product, setProduct] = useState<ProductType>();
  const [products, setProducts] = useState<[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = (type: string) => {
    if (type === "modalProduct") setShowModalProduct(false);
    if (type === "modalProductDetails") setShowModalDetails(false);
    if (type === "modalEditProduct") {
      setCode("");
      setName("");
      setDescription("");
      setQuantity("");
      setUnitaryPrice("");
      setShowModalEdit(false);
    }
    if (type === "modalRemoveProduct") setShowModalRemove(false);
  };

  const handleShow = (type: string) => {
    if (type === "modalProduct") setShowModalProduct(true);
    if (type === "modalProductDetails") setShowModalDetails(true);
    if (type === "modalEditProduct") setShowModalEdit(true);
    if (type === "modalRemoveProduct") setShowModalRemove(true);
  };

  const handleSubmitContinue = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/product/new-product",
        {
          environmentId: parseInt(user.environmentId),
          code,
          name,
          description,
          quantity,
          unitaryPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshData();
      toast.success("Produto adicionado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
    setCode("");
    setName("");
    setDescription("");
    setQuantity("");
    setUnitaryPrice("");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/product/new-product",
        {
          environmentId: parseInt(user.environmentId),
          code,
          name,
          description,
          quantity,
          unitaryPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshData();
      toast.success("Produto adicionado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
    setCode("");
    setName("");
    setDescription("");
    setQuantity("");
    setUnitaryPrice("");
    setShowModalProduct(false);
  };

  const handleGetProductForDetails = async (id: number) => {
    try {
      const productData = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/get-product/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setProduct(productData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleGetProductForEdit = async (id: number | any, e: FormEvent) => {
    try {
      const product = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/get-product/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setProductId(product.data.id);
      setCode(product.data.code);
      setName(product.data.name);
      setDescription(product.data.description);
      setQuantity(product.data.quantity);
      setUnitaryPrice(product.data.unitaryPrice);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleEditProduct = async (id: number | any, e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/product/edit-product/${id}/environment/${user.environmentId}`,
        {
          code,
          name,
          description,
          quantity,
          unitaryPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setCode("");
      setName("");
      setDescription("");
      setQuantity("");
      setUnitaryPrice("");
      await refreshData();
      toast.success("Produto editado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalEdit(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleRemoveProduct = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/product/del-product/${productId}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshData();
      toast.success("Produto removido com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalRemove(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleProducts = async (page?: number, filter?: string) => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const productData = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/get-products/environment/${userStorage.environmentId}?page=${
          page ?? 1
        }&filter=${filter ?? ""}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setProducts(productData.data.products);
      setTotalPages(productData.data.totalPages);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleFilteredProducts = async () => {
    setPage(1);
    handleProducts(1, productFiltered);
  };

  const handleChangePage = async (page: number) => {
    setPage(page);
    await handleProducts(page, productFiltered);
  };

  useEffect(() => {
    handleProducts();
  }, []);

  const refreshData = async () => {
    await handleProducts();
  };

  return (
    <>
      <Nav user={user} />
      <div className="productsContainer">
        <div className="productsChildren">
          <div className="productsTitle">
            <div>
              <Text size="24px">Produtos</Text>
            </div>
            <div>
              <Button name="adicionar" click={() => handleShow("modalProduct")} />
            </div>
          </div>
          <hr />
          <div className="searchContainer">
            <Input
              width="30%"
              type="text"
              placeholder="pesquisar"
              value={productFiltered}
              change={(e) => setProductFiltered(e.target.value)}
              focus={(e) => setProductFiltered(e.target.value)}
            />
            <Button name={<i className="fa-solid fa-magnifying-glass"></i>} click={handleFilteredProducts}></Button>
          </div>
          <hr />
          <div className="productsTableCustom">
            <Table hover responsive className="hideResponsive">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Código do produto</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th>Preço Unitário</th>
                  <th>Data criação</th>
                  <th>Data modificação</th>
                  <th>Responsável</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products
                    // .filter(
                    //   (e: ProductType) =>
                    //     e.name?.toLowerCase().includes(productFiltered) ||
                    //     e.name?.toUpperCase().includes(productFiltered) ||
                    //     e.code?.includes(productFiltered) ||
                    //     productFiltered === ""
                    // )
                    .map((iterator: ProductType, index: number) => {
                      return (
                        <tr key={iterator.id}>
                          <td>{index + 1}</td>
                          <td>{iterator.code ?? ""}</td>
                          <td>{iterator.name ?? ""}</td>
                          <td>{iterator.description ?? ""}</td>
                          <td>{iterator.quantity ?? ""}</td>
                          <td>R$ {iterator.unitaryPrice ?? ""}</td>
                          <td>{iterator.createdAt ? moment(iterator.createdAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                          <td>{iterator.updatedAt ? moment(iterator.updatedAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                          <td>{iterator.createdBy.name ?? ""}</td>
                          <td>
                            <div className="actionsTable">
                              <i
                                className="fa-solid fa-circle-info"
                                onClick={() => {
                                  handleShow("modalProductDetails");
                                  handleGetProductForDetails(iterator.id);
                                }}
                              ></i>
                              <i
                                className="fa-regular fa-pen-to-square"
                                onClick={(e) => {
                                  handleGetProductForEdit(iterator.id, e);
                                  handleShow("modalEditProduct");
                                }}
                              ></i>
                              <i
                                className="fa-solid fa-trash"
                                onClick={() => {
                                  setProductId(iterator.id);
                                  handleShow("modalRemoveProduct");
                                }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
            <Table hover responsive className="showResponsive">
              <tbody>
                {products &&
                  products
                    .filter(
                      (e: ProductType) =>
                        e.name?.toLowerCase().includes(productFiltered) ||
                        e.name?.toUpperCase().includes(productFiltered) ||
                        e.code?.includes(productFiltered) ||
                        productFiltered === ""
                    )
                    .map((iterator: ProductType, index: number) => {
                      return (
                        <Fragment key={iterator.id}>
                          <tr>
                            <th>#</th>
                            <td>{index + 1}</td>
                          </tr>
                          <tr>
                            <th>Código do produto</th>
                            <td>{iterator.code ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Nome</th>
                            <td>{iterator.name ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Descrição</th>
                            <td>{iterator.description ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Quantidade</th>
                            <td>{iterator.quantity ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Preço Unitário</th>
                            <td>R$ {iterator.unitaryPrice ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Data criação</th>
                            <td>{iterator.createdAt ? moment(iterator.createdAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                          </tr>
                          <tr>
                            <th>Data modificação</th>
                            <td>{iterator.updatedAt ? moment(iterator.updatedAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                          </tr>
                          <tr>
                            <th>Responsável</th>
                            <td>{iterator.createdBy.name ?? ""}</td>
                          </tr>
                          <tr>
                            <th>Ações</th>
                            <td>
                              <div className="actionsTable">
                                <i
                                  className="fa-solid fa-circle-info"
                                  onClick={() => {
                                    handleGetProductForDetails(iterator.id);
                                    handleShow("modalProductDetails");
                                  }}
                                ></i>
                                <i
                                  className="fa-regular fa-pen-to-square"
                                  onClick={(e) => {
                                    handleGetProductForEdit(iterator.id, e);
                                    handleShow("modalEditProduct");
                                  }}
                                ></i>
                                <i
                                  className="fa-solid fa-trash"
                                  onClick={() => {
                                    setProductId(iterator.id);
                                    handleShow("modalRemoveProduct");
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <hr />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </tbody>
            </Table>
          </div>
          <div className="d-block">
            Total encontrados na pesquisa:{" "}
            {products &&
              products.filter(
                (e: ProductType) =>
                  e.name?.toLowerCase().includes(productFiltered) ||
                  e.name?.toUpperCase().includes(productFiltered) ||
                  e.code?.includes(productFiltered) ||
                  productFiltered === ""
              ).length}
          </div>
          Total de produtos: {products?.length}
          <div className="centered">
            <Pagination className="productsPagination">
              <Pagination.First onClick={() => handleChangePage(1)} disabled={page === 1} />
              <Pagination.Prev onClick={() => handleChangePage(page - 1)} disabled={page === 1} />
              {page > 1 && <Pagination.Item onClick={() => handleChangePage(page - 1)}>{page - 1}</Pagination.Item>}
              <Pagination.Item active>{page}</Pagination.Item>
              {totalPages > 1 && totalPages !== page && (
                <Pagination.Item onClick={() => handleChangePage(page + 1)}>{page + 1}</Pagination.Item>
              )}
              {totalPages > 2 && page + 2 <= totalPages && <Pagination.Ellipsis />}
              {totalPages > 2 && page + 2 <= totalPages && (
                <Pagination.Item onClick={() => handleChangePage(totalPages)}>{totalPages}</Pagination.Item>
              )}
              {/* <span>
          Página {props.currentPage} de {props.totalPages}
        </span> */}
              <Pagination.Next onClick={() => handleChangePage(page + 1)} disabled={page === totalPages} />
              <Pagination.Last onClick={() => handleChangePage(totalPages)} disabled={page === totalPages} />
            </Pagination>
          </div>
        </div>
        <Modal show={showModalProduct} onHide={() => handleClose("modalProduct")} centered>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar produto</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <div className="form-group">
                <Text color="var(--blackAll)" bold={true} class="d-block">
                  Código do produto{" "}
                  <Text size="14px" width="300px">
                    opcional
                  </Text>
                </Text>
                <Input width="100%" type="text" value={code} change={(e) => setCode(e.target.value)} />
              </div>
              <div className="form-group">
                <Text color="var(--blackAll)" bold={true} class="d-block">
                  Nome*{" "}
                  <Text size="14px" width="300px">
                    obrigatório
                  </Text>
                </Text>
                <Input width="100%" type="text" value={name} change={(e) => setName(e.target.value)} />
              </div>
              <div className="form-group">
                <Text color="var(--blackAll)" bold={true} class="d-block">
                  Descrição{" "}
                  <Text size="14px" width="300px">
                    opcional
                  </Text>
                </Text>
                <Input width="100%" type="text" value={description} change={(e) => setDescription(e.target.value)} />
              </div>
              <div className="form-group">
                <Text color="var(--blackAll)" bold={true} class="d-block">
                  Quantidade*{" "}
                  <Text size="14px" width="300px">
                    obrigatório
                  </Text>
                </Text>
                <Input
                  type="number"
                  value={quantity?.toString()}
                  change={(e) => setQuantity(parseInt(e.target.value))}
                />
              </div>
              <div className="form-group">
                <Text color="var(--blackAll)" bold={true} class="d-block">
                  Preço Unitário*{" "}
                  <Text size="14px" width="300px">
                    obrigatório
                  </Text>
                </Text>
                <Input
                  type="number"
                  value={unitaryPrice?.toString()}
                  change={(e) => setUnitaryPrice(parseFloat(e.target.value))}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button name="cancelar" click={handleClose} /> */}
              <Button name="salvar e continuar" click={handleSubmitContinue} />
              <Button name="salvar" click={handleSubmit} />
            </Modal.Footer>
          </form>
        </Modal>
        <Modal show={showModalDetails} onHide={() => handleClose("modalProductDetails")} centered>
          <Modal.Header closeButton>
            <Modal.Title>Informações do produto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {product && (
              <>
                <Text class="d-block">Código do produto: {product.code ?? ""}</Text>
                <Text class="d-block">Nome: {product.name ?? ""}</Text>
                <Text class="d-block">Descrição: {product.description ?? ""}</Text>
                <Text class="d-block">Quantidade: {product.quantity ?? ""}</Text>
                <Text class="d-block">Preço Unitário: {product.unitaryPrice ?? ""}</Text>
                <Text class="d-block">
                  Data criação: {product.createdAt ? moment(product.createdAt).format("DD/MM/YYYY HH:mm") : ""}
                </Text>
                <Text class="d-block">
                  Data modificação: {product.updatedAt ? moment(product.updatedAt).format("DD/MM/YYYY HH:mm") : ""}
                </Text>
                <Text class="d-block">Responsável: {product.createdBy.name ?? ""}</Text>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={showModalEdit} onHide={() => handleClose("modalEditProduct")} centered>
          <Modal.Header closeButton>
            <Modal.Title>Editar produto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Código do produto{" "}
                <Text size="14px" width="300px">
                  opcional
                </Text>
              </Text>
              <Input width="100%" type="text" value={code} change={(e) => setCode(e.target.value)} />
            </div>
            <div className="form-group">
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Nome*{" "}
                <Text size="14px" width="300px">
                  obrigatório
                </Text>
              </Text>
              <Input width="100%" type="text" value={name} change={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Descrição*{" "}
                <Text size="14px" width="300px">
                  obrigatório
                </Text>
              </Text>
              <Input width="100%" type="text" value={description} change={(e) => setDescription(e.target.value)} />
            </div>
            <div className="form-group">
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Quantidade*{" "}
                <Text size="14px" width="300px">
                  obrigatório
                </Text>
              </Text>
              <Input type="number" value={quantity?.toString()} change={(e) => setQuantity(parseInt(e.target.value))} />
            </div>
            <div className="form-group">
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Preço Unitário*{" "}
                <Text size="14px" width="300px">
                  obrigatório
                </Text>
              </Text>
              <Input
                type="number"
                value={unitaryPrice?.toString()}
                change={(e) => setUnitaryPrice(parseFloat(e.target.value))}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button name="cancelar" click={() => handleClose("modalEditProduct")} />
            <Button name="salvar" click={(e) => handleEditProduct(productId, e)} />
          </Modal.Footer>
        </Modal>
        <Modal show={showModalRemove} onHide={() => handleClose("modalRemoveProduct")} centered>
          <Modal.Header closeButton>
            <Modal.Title>Deseja realmente remover o produto?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button name="cancelar" click={() => handleClose("modalRemoveProduct")} />
            <Button name="remover" backgroundColor="crimson" click={handleRemoveProduct} />
          </Modal.Footer>
        </Modal>
        {/* <Pagination className="productsPagination">
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Next />
          <Pagination.Last />
        </Pagination> */}
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Products;
