import React from "react";
import Text from "../../components/text/text";
import "./notFound.css";

const NotFound = () => {
  return (
    <div className="notFoundContainer">
      <Text size="48px" bold={true}>
        Página não encontrada. :(
      </Text>
    </div>
  );
};

export default NotFound;
