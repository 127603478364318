import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import "./environment.css";
import axios from "axios";
import toast from "react-hot-toast";

const Environment: FC = () => {
  useAuth();
  const [user, environments, handleUser] = useClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const logout = async () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const handleAcceptEnvironment = async (id: number, logo: string) => {
    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/user/accept-invite/environment/${id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
        goTo(id.toString(), logo);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleDeclineEnvironment = async (id: number) => {
    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/user/decline-invite/environment/${id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
        handleUser();
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const goTo = (e: string, logo: string) => {
    localStorage.setItem(
      "user",
      JSON.stringify(
        Object.assign(user, {
          environmentId: e,
          logo: logo,
        })
      )
    );
    navigate("/");
  };

  return (
    <>
      <Navbar className="navDefault">
        <Container className=" navCustomContainer">
          <Navbar.Brand>
            <img src="logo-e-alpha.png" alt="efi" width="60px" height="60px" />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end navCustom">
            <Navbar.Text className="me-4">
              {/* Usuário logado:{" "} */}
              <Text size="18px">
                {user?.name.split(" ")[0] ?? ""} ({user?.role?.toLowerCase() ?? ""})
              </Text>
            </Navbar.Text>
            <Button name="sair" click={logout} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="environmentContainer">
        <div className="environmentLayout">
          <div className="environmentsTitle">
            <div>
              <Text color="var(--blackAll)" size="32px" bold={true}>
                Bem vindo(a) de volta! {/*Escolha seu ambiente de vendas*/}
              </Text>
            </div>
            <div>
              <Button
                name="adicionar ambiente"
                class={["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "") ? "active" : "inactive"}
              />
            </div>
          </div>
          <div className="environmentsChildren">
            {environments &&
              environments.map((env: { status: string; environment: { id: number; name: string; logo: string } }) => {
                if (env.status == "PENDING") {
                  return (
                    <div key={env.environment.id}>
                      <div
                        key={env.environment.id?.toString()}
                        id={env.environment.id?.toString()}
                        className="envPendingLink envDisabled"
                        // disabled
                        // onClick={(e) => {
                        //   goTo(e.currentTarget.id);
                        // }}
                      >
                        <div className="logoEnv">
                          {env.environment.logo ? (
                            <img src={process.env.REACT_APP_API_URL_UPLOAD + env.environment.logo} alt="efi" />
                          ) : (
                            <i className="fa-solid fa-shop" style={{ fontSize: "26px" }}></i>
                          )}
                        </div>
                        {env.environment.name}
                        <div className="envControl">
                          <Button
                            name={<i className="fa fa-x"></i>}
                            backgroundColor="crimson"
                            click={(e) => {
                              handleDeclineEnvironment(env.environment.id);
                            }}></Button>
                          <Button
                            name={<i className="fa fa-check"></i>}
                            backgroundColor="green"
                            click={(e) => {
                              handleAcceptEnvironment(env.environment.id, env.environment.logo ?? "");
                            }}></Button>
                        </div>
                      </div>
                    </div>
                  );
                }

                return (
                  <button
                    key={env.environment.id?.toString()}
                    id={env.environment.id?.toString()}
                    className="envLink"
                    onClick={(e) => {
                      goTo(e.currentTarget.id, env.environment.logo ?? "");
                    }}>
                    <div className="logoEnv">
                      {env.environment.logo ? (
                        <img src={process.env.REACT_APP_API_URL_UPLOAD + env.environment.logo} alt="efi" />
                      ) : (
                        <i className="fa-solid fa-shop" style={{ fontSize: "26px" }}></i>
                      )}
                    </div>
                    {env.environment.name}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Environment;
