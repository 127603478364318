import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "loading",
  initialState: {
    loading: false,
  },
  reducers: {
    changeLoading(state: any, { payload }) {
      return { ...state, payload };
    },
  },
});

export const { changeLoading } = slice.actions;

export const selectLoading = (state: any) => state.loading;

export default slice.reducer;
