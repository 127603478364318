import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "userData",
  initialState: {
    data: {
      id: "",
      cpf: "",
      password: "",
    },
  },
  reducers: {
    changeUserData(state: any, { payload }) {
      return { ...state, data: payload };
    },
    logout(state: any) {
      return { ...state, data: {} };
    },
  },
});

export const { changeUserData, logout } = slice.actions;

export const selectUserData = (state: any) => state.user;

export default slice.reducer;
