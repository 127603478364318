import { toast } from "react-hot-toast";

const CPF = (cpf: string) => {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }
  var sum = 0;
  var rest;
  for (var i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest != parseInt(cpf.substring(9, 10))) return false;
  sum = 0;
  for (var i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const validateCPF = (cpf: string): boolean => {
  if (!CPF(cpf)) {
    toast.error("CPF inválido.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }
  return true;
};

const CNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  var size = cnpj.length - 2;
  var numbers: any = cnpj.substring(0, size);
  var digits = cnpj.substring(size);
  var sum = 0;
  var pos = size - 7;

  for (var i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var result: any = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) return false;

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (var i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) return false;

  return true;
};

export const validateCNPJ = (cnpj: string): boolean => {
  if (!CNPJ(cnpj)) {
    toast.error("CNPJ inválido.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }
  return true;
};

export const validateEmail = (email: string) => {
  if (!email.match(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i)) {
    toast.error("E-mail inválido.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }
  return true;
};

export const validatePassword = (password: string) => {
  if (password.length > 20) {
    toast.error("A senha não pode ter mais do que 20 caracteres.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }
  if (password.length < 8) {
    toast.error("A senha deve ter no mínimo 8 caracteres.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }
  // if (!password.match(/((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g)) {
  //   toast.error(
  //     "A senha deve ter pelo menos 1 número, 1 caractere especial, letra minúscula e maiúscula. Ex: 1@aA",
  //     {
  //       position: "top-center",
  //       duration: 5000,
  //       style: { backgroundColor: "#f8d7da", color: "crimson" },
  //     }
  //   );
  //   return false;
  // }

  return true;
};

export const validateLogin = (login: string) => {
  if (login.length > 20) {
    toast.error("Campo login não pode ter mais do que 20 caracteres.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }

  return true;
};

export const validateName = (name: string) => {
  if (name.length < 4) {
    toast.error("Campo nome não pode ter menos do que 4 caracteres.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }

  if (name.length > 100) {
    toast.error("Campo nome não pode ter mais do que 100 caracteres.", {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }

  return true;
};

export const validateString = (value: string, min: number, max: number) => {
  if (value.length < min) {
    toast.error(`Limite mínimo de ${min} caracteres não informado.`, {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }

  if (value.length > max) {
    toast.error(`Limite máximo de ${max} caracteres ultrapassado.`, {
      position: "top-center",
      duration: 5000,
      style: { backgroundColor: "#f8d7da", color: "crimson" },
    });
    return false;
  }

  return true;
};

export const validateRequired = (obj: {}) => {
  for (const element of Object.entries(obj)) {
    if (element[1] === "") {
      toast.error(`Campo ${element[0]} é obrigatório.`, {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return false;
    }
  }
  return true;
};

export const formatCnpj = (rawValue: string) => {
  const cnpjArray = rawValue.split("");
  if (rawValue.length >= 3) cnpjArray.splice(2, 0, ".");
  if (rawValue.length >= 6) cnpjArray.splice(6, 0, ".");
  if (rawValue.length >= 10) cnpjArray.splice(10, 0, "/");
  if (rawValue.length >= 14) cnpjArray.splice(15, 0, "-");
  return cnpjArray.join("").slice(0, 18);
};

export const formatCpf = (rawValue: string) => {
  const cpfArray = rawValue.split("");
  if (rawValue.length >= 4) cpfArray.splice(3, 0, ".");
  if (rawValue.length >= 7) cpfArray.splice(7, 0, ".");
  if (rawValue.length >= 10) cpfArray.splice(11, 0, "-");
  return cpfArray.join("").slice(0, 14);
};

export const formatCardCpf = (rawValue: string) => {
  const cardCpfArray = rawValue.split("");
  if (rawValue.length >= 4) cardCpfArray.splice(3, 0, ".");
  if (rawValue.length >= 7) cardCpfArray.splice(7, 0, ".");
  if (rawValue.length >= 10) cardCpfArray.splice(11, 0, "-");
  return cardCpfArray.join("").slice(0, 14);
};

export const formatCellphone = (rawValue: string) => {
  const cellphoneArray = rawValue.split("");
  if (rawValue.length >= 1) cellphoneArray.splice(0, 0, "(");
  if (rawValue.length >= 3) cellphoneArray.splice(3, 0, ")");
  if (rawValue.length >= 8) cellphoneArray.splice(9, 0, "-");
  return cellphoneArray.join("").slice(0, 14);
};

export const formatCardNumber = (rawValue: string) => {
  const cardNumberArray = rawValue.split("");
  if (rawValue.length >= 5) cardNumberArray.splice(4, 0, " ");
  if (rawValue.length >= 9) cardNumberArray.splice(9, 0, " ");
  if (rawValue.length >= 13) cardNumberArray.splice(14, 0, " ");
  return cardNumberArray.join("").slice(0, 19);
};

export const formatCardExp = (rawValue: string) => {
  const cardExpArray = rawValue.split("");
  if (rawValue.length >= 3) cardExpArray.splice(2, 0, "/");
  return cardExpArray.join("").slice(0, 5);
};
