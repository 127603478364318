import { FC, FormEvent, useState } from "react";
import Text from "../../components/text/text";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import Loading from "../../components/loading/loading";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { validateEmail } from "../../validations/validations";
import axios from "axios";

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!validateEmail(email)) return;
    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_API_URL + "/user/forgot-password", {
        email,
      });
      setTimeout(() => {
        setLoading(false);
      }, 200);
      setEmail("");
      toast.success(response.data?.message ?? "Email enviado com sucesso", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  return (
    <div className="containerCustom">
      <div className="loginContainer">
        <img src="logo-e-alpha.png" alt="efi" width="60px" height="60px" />
        <i>Recupere sua senha</i>
        <hr />
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            E-mail* <Text size="14px">obrigatório</Text>
          </Text>
        </div>
        <Input width="80%" type="email" value={email} change={(e) => setEmail(e.target.value)} />
        <Text size="12px">Enviaremos um e-mail com link para recuperação de senha.</Text>
        <Button name="enviar" click={handleSubmit} />
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </div>
  );
};

export default ForgotPassword;
