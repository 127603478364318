import { FC, FormEvent, useState } from "react";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading/loading";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { validatePassword, validateRequired } from "../../validations/validations";
import { PasswordInput } from "@mantine/core";

const ChangePassword: FC = () => {
  let { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !validateRequired({
        "Nova senha": password,
      })
    ) {
      return;
    }
    if (password !== passwordConfirm) {
      toast.error("As senhas não conferem.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return;
    }
    // if (!validatePassword(password)) return;
    try {
      setLoading(true);
      await axios.put(process.env.REACT_APP_API_URL + "/user/update-forgot-password", {
        token,
        password,
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setPassword("");
      setPasswordConfirm("");
      navigate("/login");
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="containerCustom">
      <div className="loginContainer">
        <img src="/logo-e-alpha.png" alt="efi" width="60px" height="60px" />
        <i>Alteração de senha</i>
        <hr />
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            Nova senha* <Text size="14px">obrigatório</Text>
          </Text>
        </div>
        <div style={{ width: "80%" }}>
          <PasswordInput maxLength={20} width="100%" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div style={{ width: "80%" }}>
          <Text color="var(--blackAll)" bold={true}>
            Confirmar nova senha* <Text size="14px">obrigatório</Text>
          </Text>
        </div>
        <div style={{ width: "80%" }}>
          <PasswordInput
            maxLength={20}
            width="100%"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>
        <Text size="12px" class="text-center">
          Nos compremetemos com a segurança de seus dados, sua senha é pessoal e intransferível, não compartilhe com
          ninguém.
        </Text>
        <Button name="enviar" click={handleSubmit} />
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </div>
  );
};

export default ChangePassword;
