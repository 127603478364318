import { ChangeEvent, FC, useEffect, useState } from "react";
import Nav from "../../components/nav/nav";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Loading from "../../components/loading/loading";
import Footer from "../../components/footer/footer";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import "./schedule.css";
import moment from "moment";
import Input from "../../components/input/input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Models from "../../models/index";
import { formatCellphone } from "../../validations/validations";

const Schedule: FC = () => {
  const navigate = useNavigate();
  useAuth();
  const [user] = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<any>([]);
  const [monthSelected, setMonthSelected] = useState<number>(0);
  const [showModalSchedule, setShowModalSchedule] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalScheduleCode, setShowModalScheduleCode] = useState<boolean>(false);
  const [schedulingCode, setSchedulingCode] = useState<Models.SchedulingCode>();
  const [cellPhone, setCellphone] = useState<string>("");
  const busySchedule = ["2024-03-14", "2024-03-11", "2024-03-21"];

  const handleClose = (modal: string) => {};

  const verifyDay = (date: string): string => {
    let classes = "";
    let monthCurrent = moment().month() + 1;
    let dayCurrent = moment().date();
    let month = date.split("-")[1];
    let day = date.split("-")[2];
    if (dayCurrent == parseInt(day) && monthCurrent == parseInt(month)) {
      classes += "todaySchedule";
    }

    if (busySchedule.includes(date)) {
      classes += " busySchedule";
    } else {
      classes += " availableSchedule";
    }

    if (moment().add(monthSelected, "month").month() + 1 != parseInt(month)) {
      classes += " otherDaysSchedule";
    }

    return classes;
  };

  const makeCalendar = () => {
    let calendarData = [];
    const year = moment().add(monthSelected, "month").year();
    const startWeek = moment().add(monthSelected, "month").startOf("month").week();
    const endWeek = moment().add(monthSelected, "month").endOf("month").week();
    for (
      var week = startWeek;
      week <= (endWeek > startWeek ? endWeek : endWeek + moment().add(monthSelected, "month").isoWeeksInYear());
      week++
    ) {
      calendarData.push({
        week: week,
        days: Array(7)
          .fill(0)
          .map((n, i) =>
            moment()
              .year(year)
              .week(week)
              .startOf("week")
              .clone()
              .add(n + i, "day")
              .format("YYYY-MM-DD")
          ),
      });
    }

    setCalendar(calendarData);
  };

  const monthChange = (value: number) => {
    setMonthSelected(value);
  };

  const handleChangeCellphone = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cellphone = formatCellphone(rawValue);
    setCellphone(cellphone);
  };

  const handleGetScheduleCode = async () => {
    try {
      setLoading(true);
      const scheduleCodeData = await axios.get(`${process.env.REACT_APP_API_URL}/scheduling/code/environment/1`);
      setSchedulingCode(scheduleCodeData.data);
      setTimeout(() => {
        setLoading(false);
        setShowModalConfirmation(true);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleCreateScheduleCode = async () => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/scheduling/code/environment/1`, {
        cellphone: cellPhone,
      });
      await handleGetScheduleCode();
      setCellphone("");
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  useEffect(() => {
    makeCalendar();
  }, [monthSelected]);

  return (
    <>
      <Nav user={user} />
      <div className="saleCheckoutContainer">
        <Button name="ordem de chegada" click={() => setShowModalScheduleCode(true)}></Button>
        <div className="saleCheckoutChildren">
          <div className="d-flex justify-content-between align-items-center">
            <Text size="24px">Agendas</Text>
            <div>
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar profissional"
                  // value={productFiltered}
                  // change={(e) => setProductFiltered(e.target.value)}
                  // focus={(e) => setProductFiltered(e.target.value)}
                />
                <Button
                  name={<i className="fa-solid fa-magnifying-glass hideResponsive"></i>}
                  // click={handleFilteredProducts}
                ></Button>
              </div>
            </div>
          </div>
          <hr />
          <div className="titleTableSchedule">
            <Text color="var(--backgroundColor3)" bold={true}>
              Hoje
            </Text>
            <div>
              <Button
                name={<i className="fa-solid fa-chevron-left"></i>}
                click={() => monthChange(monthSelected - 1)}
              />
              <Button
                name={<i className="fa-solid fa-chevron-right"></i>}
                click={() => monthChange(monthSelected + 1)}
              />
            </div>
            <div>
              <Text size="24px" bold={true} color="var(--blackAll)">
                {moment().add(monthSelected, "month").format("MMMM")}
              </Text>{" "}
              <Text>{moment().add(monthSelected, "month").format("YYYY")}</Text>
            </div>
          </div>
          <Table responsive className="hideResponsive tableSchedule">
            <thead>
              <tr>
                <th>DOM</th>
                <th>SEG</th>
                <th>TER</th>
                <th>QUA</th>
                <th>QUI</th>
                <th>SEX</th>
                <th>SÁB</th>
              </tr>
            </thead>
            <tbody>
              {calendar &&
                calendar.map((week: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className={verifyDay(week.days[0])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[0].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[1])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[1].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[2])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[2].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[3])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[3].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[4])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[4].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[5])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[5].split("-")[2]}
                      </td>
                      <td className={verifyDay(week.days[6])} onClick={() => setShowModalSchedule(true)}>
                        {week.days[6].split("-")[2]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Modal show={showModalSchedule} onHide={() => setShowModalSchedule(false)} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              Marque seu agendamento do dia 24/06/2024 (SEG)
              {/* <div>
                <Text size="18px">Data selecionada: 31/03/2024 (Domingo)</Text>
              </div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <div>
                <div>
                  <div className="searchContainer">
                    <Input
                      width="70%"
                      type="text"
                      placeholder="pesquisar serviço"
                      // value={productFiltered}
                      // change={(e) => setProductFiltered(e.target.value)}
                      // focus={(e) => setProductFiltered(e.target.value)}
                    />
                    <Button
                      name={<i className="fa-solid fa-magnifying-glass hideResponsive"></i>}
                      // click={handleFilteredProducts}
                    ></Button>
                  </div>
                  <hr />
                </div>
                <div>Profissionais</div>
                <div className="professionalContainer">
                  <i className="fa-solid fa-chevron-left"></i>
                  {[
                    { id: 1, name: "CLEUSA", photo: "" },
                    { id: 2, name: "FRANCISCO", photo: "" },
                  ] &&
                    [
                      { id: 1, name: "CLEUSA", photo: "" },
                      { id: 2, name: "FRANCISCO", photo: "" },
                    ].map((person: any) => {
                      return (
                        <button
                          key={person.id?.toString()}
                          id={person.id?.toString()}
                          className="professionalLink"
                          // onClick={(e) => {
                          //   goTo(e.currentTarget.id);
                          // }}
                        >
                          <div className="logoProfessional">
                            {/* <img src="belladoceria.png" alt="efi" /> */}
                            <i className="fa-solid fa-user" style={{ fontSize: "26px" }}></i>
                          </div>
                          {person.name}
                        </button>
                      );
                    })}{" "}
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
                Horários disponíveis
                <div className="timeContent">
                  {[
                    "08:00",
                    "08:30",
                    "09:00",
                    "09:30",
                    "10:00",
                    "10:30",
                    "11:00",
                    "11:30",
                    "13:00",
                    "13:30",
                    "14:00",
                    "14:30",
                  ].map((hour, index) => {
                    return (
                      <div className="timeSchedule" key={index}>
                        {hour}{" "}
                        <select>
                          <option value="volvo" selected>
                            ...
                          </option>
                          <option value="5266">#5266 - ESCOVA R$ 49,99</option>
                          <option value="5267">#5267 - ESCOVA PREMIUM R$ 59,99</option>
                        </select>
                      </div>
                    );
                  })}
                </div>
              </div>
              <span className="separator"></span>
              <div className="tableSideModalSchedule">
                Agendas selecionadas
                <div className="tableModalSchedule">
                  <Table hover responsive className="hideResponsive" style={{ fontSize: "14px" }}>
                    <thead>
                      <tr>
                        <th>Horário</th>
                        <th>Profissional</th>
                        <th>Serviço</th>
                        <th>Preço</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>08:00</td>
                        <td>CLEUSA</td>
                        <td>#5266 - ESCOVA</td>
                        <td>R$ 49,99</td>
                        <td>
                          <i className="fa-solid fa-circle-minus trashMinus"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>08:30</td>
                        <td>CLEUSA</td>
                        <td>#5267 - ESCOVA PREMIUM</td>
                        <td>R$ 59,99</td>
                        <td>
                          <i className="fa-solid fa-circle-minus trashMinus"></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <Text>Total</Text>
                  <div>R$ 109,98</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            Tudo certo? Agora é só clicar em agendar
            <Button name="agendar" />
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalScheduleCode}
          onHide={() => setShowModalScheduleCode(false)}
          centered
          size="lg"
          className="modalSchedulingCode"
        >
          <Modal.Header closeButton>
            <Modal.Title>Marque sua ordem de chegada para o dia 02/04/2024 (TER)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Input label="Celular" width="75%" type="text" value={cellPhone} change={handleChangeCellphone} />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text size="14px" class="info">
                Tudo certo? Agora é só clicar em agendar
              </Text>
              <Text size="12px" class="info">
                Sua senha será gerada logo em seguida
              </Text>
            </div>
            <Button
              name="agendar"
              click={() => {
                setShowModalScheduleCode(false);
                handleCreateScheduleCode();
              }}
            />
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalConfirmation}
          onHide={() => setShowModalConfirmation(false)}
          centered
          size="lg"
          className="modalSchedulingCode"
        >
          <Modal.Header closeButton>
            <Modal.Title>Tudo certo!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div>
                <Text size="18px" class="info">
                  Sua senha é
                </Text>
              </div>
              <Text size="30px" class="info">
                E{schedulingCode?.code ?? 1}
              </Text>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Text size="14px" class="info">
              Apresente esta senha no estabelecimento, você será notificado quando sua vez chegar
            </Text>
          </Modal.Footer>
        </Modal>
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Schedule;
