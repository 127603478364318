import { FC, useState, ChangeEvent, FormEvent, Fragment, useEffect } from "react";
import Nav from "../../components/nav/nav";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading/loading";
import Timeline from "../../components/timeline/timeline";
import PaginationCustom from "../../components/pagination/pagination";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import { Pagination, Table } from "react-bootstrap";
import moment from "moment";
import "moment/locale/pt-br";
import "./administration.css";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {
  formatCpf,
  validateCPF,
  validateEmail,
  validateName,
  validateRequired,
  validatePassword,
  validateString,
  formatCellphone,
  formatCnpj,
} from "../../validations/validations";
import * as Models from "../../models/index";
import { formatPrice } from "../../utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PasswordInput } from "@mantine/core";

const Administration: FC = () => {
  const navigate = useNavigate();
  useAuth();
  const [user] = useClient();
  let [searchParams] = useSearchParams();
  const [personsFiltered, setPersonsFiltered] = useState<string>("");
  const [personsAccessFiltered, setPersonsAccessFiltered] = useState<string>("");
  const [ticketsFiltered, setTicketsFiltered] = useState<string>("");
  const [addClass, setAddClass] = useState("controlContainer");
  const [employeeCpf, setEmployeeCpf] = useState<string>("");
  const [employeeName, setEmployeeName] = useState<string>("");
  const [employeeEmail, setEmployeeEmail] = useState<string>("");
  const [employeeEditName, setEmployeeEditName] = useState<string>("");
  const [employeeEditEmail, setEmployeeEditEmail] = useState<string>("");
  const [employeeId, setEmployeeId] = useState<string>("");
  const [employees, setEmployees] = useState<Models.Employee[]>();
  const [employee, setEmployee] = useState<Models.Person>();
  const [clientId, setClientId] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [clientCpf, setClientCpf] = useState<string>("");
  const [clientEmail, setClientEmail] = useState<string>("");
  const [clientCellphone, setClientCellphone] = useState<string>("");
  const [clients, setClients] = useState<Models.Client[]>();
  const [client, setClient] = useState<Models.Client>();
  const [userDetails, setUserDetails] = useState<Models.Person>();
  const [userControlDetails, setUserControlDetails] = useState<Models.Person>();
  const [environmentDetails, setEnvironmentDetails] = useState<Models.Environment>();
  const [starList, setStarList] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tagName, setTagName] = useState<string>("");
  const [tagId, setTagId] = useState<number>(0);
  const [tags, setTags] = useState<Models.Tag[]>();

  const [administrationModule, setAdministrationModule] = useState<boolean>(false);
  const [reportModule, setReportModule] = useState<boolean>(false);
  const [stockModule, setStockModule] = useState<boolean>(false);
  const [serviceModule, setServiceModule] = useState<boolean>(false);
  const [saleModule, setSaleModule] = useState<boolean>(false);
  const [manageEmployee, setManageEmployee] = useState<boolean>(false);
  const [manageClient, setManageClient] = useState<boolean>(false);
  const [manageStock, setManageStock] = useState<boolean>(false);
  const [manageService, setManageService] = useState<boolean>(false);

  const [manageEmployeeTag, setManageEmployeeTag] = useState<boolean[]>([]);
  const [manageClientTag, setManageClientTag] = useState<boolean[]>([]);
  const [manageStockTag, setManageStockTag] = useState<boolean[]>([]);
  const [manageServiceTag, setManageServiceTag] = useState<boolean[]>([]);

  const [saleExpress, setSaleExpress] = useState<boolean>(false);
  const [salePersonalized, setSalePersonalized] = useState<boolean>(false);

  const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false);
  const [showModalChangePermission, setShowModalChangePermission] = useState<boolean>(false);
  const [showModalTicketHistory, setShowModalTicketHistory] = useState<boolean>(false);
  const [showModalRemoveAccount, setShowModalRemoveAccount] = useState<boolean>(false);

  const [showModalEmployeeDetails, setShowModalEmployeeDetails] = useState<boolean>(false);
  const [showModalEditEmployee, setShowModalEditEmployee] = useState<boolean>(false);
  const [showModalRemoveEmployee, setShowModalRemoveEmployee] = useState<boolean>(false);
  const [showModalClientDetails, setShowModalClientDetails] = useState<boolean>(false);
  const [showModalEditClient, setShowModalEditClient] = useState<boolean>(false);
  const [showModalRemoveClient, setShowModalRemoveClient] = useState<boolean>(false);
  const [showModalChangeLogo, setShowModalChangeLogo] = useState<boolean>(false);
  const [showModalAllTags, setShowModalAllTags] = useState<boolean>(false);
  const [showModalLayout, setShowModalLayout] = useState<boolean>(false);
  const [showModalEditLayout, setShowModalEditLayout] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");
  const [logoChanged, setLogoChanged] = useState<File | null>(null);
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const [layoutType, setLayoutType] = useState<string>("");
  const [layoutTypeCode, setLayoutTypeCode] = useState<number>(0);
  const [layoutId, setLayoutId] = useState<number>(0);
  const [layouts, setLayouts] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [userName, setUserName] = useState<string>("");
  const [editUserName, setEditUserName] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [editUserEmail, setEditUserEmail] = useState<boolean>(false);

  const handleClose = (type: string) => {
    if (type === "modalChangePassword") {
      setPassword("");
      setNewPassword("");
      setPasswordConfirm("");
      setShowModalChangePass(false);
    }
    if (type === "modalChangePermission") setShowModalChangePermission(false);
    if (type === "modalTicketHistory") setShowModalTicketHistory(false);
    if (type === "modalRemoveAccount") setShowModalRemoveAccount(false);
    if (type === "modalEmployeeDetails") setShowModalEmployeeDetails(false);
    if (type === "modalEditEmployee") setShowModalEditEmployee(false);
    if (type === "modalRemoveEmployee") setShowModalRemoveEmployee(false);
    if (type === "modalClientDetails") setShowModalClientDetails(false);
    if (type === "modalEditClient") setShowModalEditClient(false);
    if (type === "modalRemoveClient") setShowModalRemoveClient(false);
    if (type === "modalChangeLogo") {
      // setLogo("");
      setLogoChanged(null);
      setShowModalChangeLogo(false);
    }
    if (type === "modalAllTags") setShowModalAllTags(false);
    if (type === "modalLayout") setShowModalLayout(false);
    if (type === "modalEditLayout") {
      setLayoutType("");
      setLayoutTypeCode(0);
      setShowModalEditLayout(false);
    }
  };

  const handleShow = (type: string) => {
    if (type === "modalChangePassword") setShowModalChangePass(true);
    if (type === "modalChangePermission") setShowModalChangePermission(true);
    if (type === "modalTicketHistory") setShowModalTicketHistory(true);
    if (type === "modalRemoveAccount") setShowModalRemoveAccount(true);
    if (type === "modalEmployeeDetails") setShowModalEmployeeDetails(true);
    if (type === "modalEditEmployee") setShowModalEditEmployee(true);
    if (type === "modalRemoveEmployee") setShowModalRemoveEmployee(true);
    if (type === "modalClientDetails") setShowModalClientDetails(true);
    if (type === "modalEditClient") setShowModalEditClient(true);
    if (type === "modalRemoveClient") setShowModalRemoveClient(true);
    if (type === "modalChangeLogo") setShowModalChangeLogo(true);
    if (type === "modalAllTags") setShowModalAllTags(true);
    if (type === "modalLayout") setShowModalLayout(true);
    if (type === "modalEditLayout") setShowModalEditLayout(true);
  };

  const handleClickEditName = () => {
    setEditUserName(!editUserName);
  };

  const handleClickEditEmail = () => {
    setEditUserEmail(!editUserEmail);
  };

  const handleClickEditUser = async () => {
    if (!userDetails) return;
    if (userDetails.name != userName) setEditUserName(false);
    if (userDetails.email != userEmail) setEditUserEmail(false);
    setUserDetails({ ...userDetails, name: userName || userDetails.name, email: userEmail || userDetails.email });
    try {
      setLoading(true);
      await axios.put(
        process.env.REACT_APP_API_URL + `/user/edit-user/${user.id}`,
        {
          name: userDetails?.name != userName ? userName : undefined,
          email: userDetails?.email != userEmail ? userEmail : undefined,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Alterado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleClick = (page: string) => {
    setAddClass(page);
  };

  const ticketListMock = [
    {
      id: 1,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda",
      message: "Problema referente a venda",
      dateCreated: "26/01/2024 12:58",
      dateUpdated: "26/01/2024 12:58",
      responsible: "jeni",
    },
    {
      id: 2,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda novamente",
      message: "Problema referente a venda novamente",
      dateCreated: "26/01/2024 14:58",
      dateUpdated: "26/01/2024 14:58",
      responsible: "jeni",
    },
    {
      id: 3,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda novamente",
      message: "Problema referente a venda novamente",
      dateCreated: "26/01/2024 14:58",
      dateUpdated: "26/01/2024 14:58",
      responsible: "jeni",
    },
    {
      id: 4,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda novamente",
      message: "Problema referente a venda novamente",
      dateCreated: "26/01/2024 14:58",
      dateUpdated: "26/01/2024 14:58",
      responsible: "jeni",
    },
    {
      id: 5,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda novamente",
      message: "Problema referente a venda novamente",
      dateCreated: "26/01/2024 14:58",
      dateUpdated: "26/01/2024 14:58",
      responsible: "jeni",
    },
    {
      id: 6,
      status: "ativo",
      color: "green",
      subject: "Problema referente a venda novamente",
      message: "Problema referente a venda novamente",
      dateCreated: "26/01/2024 14:58",
      dateUpdated: "26/01/2024 14:58",
      responsible: "jeni",
    },
  ];

  const handleChangeEmployeeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setEmployeeCpf(cpf);
  };

  const handleChangeClientCpf = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cpf = formatCpf(rawValue);
    setClientCpf(cpf);
  };

  const handleEmployeeRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !validateRequired({
        CPF: employeeCpf,
        Nome: employeeName,
        "E-mail": employeeEmail,
      })
    ) {
      return;
    }
    if (!validateCPF(employeeCpf)) return;
    if (!validateName(employeeName)) return;
    if (!validateEmail(employeeEmail)) return;
    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_API_URL + `/user/administration/environment/${user.environmentId}/new-employee`,
        {
          cpf: employeeCpf,
          name: employeeName,
          email: employeeEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 200);
      setEmployeeCpf("");
      setEmployeeName("");
      setEmployeeEmail("");
      toast.success("Adicionado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleEmployees = async (page?: number, filter?: string) => {
    try {
      setLoading(true);
      const employeesData = await axios.get(
        process.env.REACT_APP_API_URL + `/environment/${user.environmentId}/get-users`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setEmployees(employeesData.data);
      setTotalPages(employeesData.data.totalPages ?? 1);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleClients = async (page?: number, filter?: string) => {
    try {
      setLoading(true);
      const clientData = await axios.get(
        process.env.REACT_APP_API_URL + `/client/get-clients/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      console.error("clientData", clientData.data);
      setClients(clientData.data);
      setTotalPages(clientData.data.totalPages ?? 1);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleUser = async () => {
    try {
      setLoading(true);
      const userData = await axios.get(
        process.env.REACT_APP_API_URL + `/user/get-user-details/${user.id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setUserDetails(userData.data);
      setUserName(userData.data.name);
      setUserEmail(userData.data.email);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleEnvironment = async () => {
    try {
      setLoading(true);
      const environmentData = await axios.get(process.env.REACT_APP_API_URL + `/environment/${user.environmentId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      setEnvironmentDetails(environmentData.data);
      // let list = [];
      // for (let index = 0; index < environmentData.data.plan.star; index++) {
      //   list.push(index);
      // }
      setStarList(Array.from({ length: environmentData.data.plan.star }, (_, i) => i));
      setAdministrationModule(environmentData.data.administrationModule);
      setReportModule(environmentData.data.reportModule);
      setStockModule(environmentData.data.stockModule);
      setServiceModule(environmentData.data.serviceModule);
      setSaleModule(environmentData.data.saleModule);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleTags = async (userId?: number, modalAllTags?: string, adminAllTags?: string) => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const tagsData = await axios.get(
        process.env.REACT_APP_API_URL + `/tag/all-tags/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setTags(tagsData.data);
      if (userId) {
        const tagCheck = tagsData.data.find((tag: any) =>
          tag.userTag.find((userTag: any) => userTag.user.id === userId)
        )?.id;
        setTagId(tagCheck);
      }
      if (modalAllTags) {
        let employeeManageList = [];
        let clientManageList = [];
        let stockManageList = [];
        let serviceManageList = [];
        for (const tag of tagsData.data) {
          employeeManageList.push(tag.permission.includes("EMPLOYEEMANAGER"));
          clientManageList.push(tag.permission.includes("CLIENTMANAGER"));
          stockManageList.push(tag.permission.includes("PRODUCTMANAGER"));
          serviceManageList.push(tag.permission.includes("SERVICEMANAGER"));
        }
        setManageEmployeeTag(employeeManageList);
        setManageClientTag(clientManageList);
        setManageStockTag(stockManageList);
        setManageServiceTag(serviceManageList);
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleUserTag = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const tagData = await axios.get(
        process.env.REACT_APP_API_URL + `/tag-user/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      if (tagData.data.hasOwnProperty("tag")) setUserPermissions(tagData.data.tag.permission);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleChangePassword = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !validateRequired({
        "Senha atual": password,
        "Nova senha": newPassword,
      })
    ) {
      return;
    }
    if (newPassword !== passwordConfirm) {
      toast.error("As novas senhas não conferem.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return;
    }
    // if (!validatePassword(newPassword)) return;
    try {
      setLoading(true);
      await axios.put(
        process.env.REACT_APP_API_URL + "/user/change-password",
        {
          oldPassword: password,
          newPassword,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      toast.success("Atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
        // icon: "👏",
      });
      setPassword("");
      setNewPassword("");
      setPasswordConfirm("");
      setShowModalChangePass(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const handleGetUserForDetails = async (id: number) => {
    try {
      const productData = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/get-user-details/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setUserControlDetails(productData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleGetEmployeeForDetails = async (id: number) => {
    try {
      const employeeData = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/get-user-details/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setEmployee(employeeData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleGetEmployeeForEdit = async (id: string) => {
    try {
      const employee = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/get-user-details/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setEmployeeId(employee.data.id);
      setEmployeeEditName(employee.data.name);
      setEmployeeEditEmail(employee.data.email);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleEditEmployee = async (employeeId: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/user/edit-other-user/${employeeId}/environment/${user.environmentId}`,
        {
          name: employeeEditName,
          email: employeeEditEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setEmployeeEditName("");
      setEmployeeEditEmail("");
      await refreshEmployeeData();
      toast.success("Colaborador atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalEditEmployee(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleRemoveEmployee = async (employeeId: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/user/del-other-user/${employeeId}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshEmployeeData();
      toast.success("Colaborador removido com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalRemoveEmployee(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleGetClientForDetails = async (id: number) => {
    try {
      const clientData = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-client/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setClient(clientData.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleGetClientForEdit = async (id: string) => {
    try {
      const client = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-client/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setClientId(client.data.id);
      setClientName(client.data.name);
      setClientCpf(client.data.cpf);
      setClientEmail(client.data.email);
      setClientCellphone(client.data.cellphone);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleEditClient = async (clientId: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/client/edit-client/${clientId}/environment/${user.environmentId}`,
        {
          name: clientName,
          cpf: clientCpf,
          email: clientEmail,
          cellphone: clientCellphone,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setClientName("");
      setClientCpf("");
      setClientEmail("");
      setClientCellphone("");
      await refreshClientData();
      toast.success("Cliente atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalEditClient(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleRemoveClient = async (clientId: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/client/del-client/${clientId}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshClientData();
      toast.success("Cliente removido com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalRemoveClient(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const refreshClientData = async () => {
    await handleClients();
  };

  const refreshEmployeeData = async () => {
    await handleEmployees();
  };

  const refreshTagData = async () => {
    await handleTags();
  };

  const handleLogo = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    if (!ev.target.files) return;

    const file = ev.target.files[0];
    const url = URL.createObjectURL(file);
    setLogo(url);
    setLogoChanged(ev.target.files[0]);
  };

  const handleSaveLogo = async () => {
    if (logoChanged) {
      try {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
        var formData = new FormData();
        formData.append("logo", logoChanged);
        await axios.put(`${process.env.REACT_APP_API_URL}/environment/${user.environmentId}/new-logo`, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Alterado com sucesso.", {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "#d4edda", color: "green" },
        });
        setShowModalChangeLogo(false);
      } catch (error: any) {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          toast.error(
            error.response.data.errorcode
              ? error.response.data.message
              : `Erro inesperado, contate o suporte. [${error.response.status}]`,
            {
              position: "top-center",
              duration: 5000,
              style: { backgroundColor: "#f8d7da", color: "crimson" },
            }
          );
        }
      }
    }
  };

  const handleModulesParams = async () => {
    console.log({
      administrationModule: administrationModule,
      reportModule: reportModule,
      stockModule: stockModule,
      serviceModule: serviceModule,
      saleModule: saleModule,
    });
  };

  const handleManageTags = async () => {
    if (!validateString(tagName, 4, 50)) return;
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/tag/new-tag/environment/${user.environmentId}`,
        {
          name: tagName,
          permissions: {
            EMPLOYEEMANAGER: manageEmployee,
            CLIENTMANAGER: manageClient,
            PRODUCTMANAGER: manageStock,
            SERVICEMANAGER: manageService,
            REPORTMANAGER: false,
            TICKETMANAGER: false,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Tag criada com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleChangeLinkTag = async (tagId: number, userId: number) => {
    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_API_URL + `/tag/link-tag/${tagId}/user/${userId}/environment/${user.environmentId}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
        // icon: "👏",
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleModuleSale = async () => {
    console.log({ saleExpress: saleExpress, salePersonalized: salePersonalized });
  };

  const handleEditTag = async (
    tagId: number,
    // tagName: string,
    manageEmployee: boolean,
    manageClient: boolean,
    manageStock: boolean,
    manageService: boolean
  ) => {
    try {
      setLoading(true);
      await axios.put(
        process.env.REACT_APP_API_URL + `/tag/edit-tag/${tagId}`,
        {
          // name: tagName,
          permissions: {
            EMPLOYEEMANAGER: manageEmployee,
            CLIENTMANAGER: manageClient,
            PRODUCTMANAGER: manageStock,
            SERVICEMANAGER: manageService,
            REPORTMANAGER: false,
            TICKETMANAGER: false,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await refreshTagData();
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleRemoveTag = async (tagId: number) => {
    try {
      setLoading(true);
      await axios.delete(process.env.REACT_APP_API_URL + `/tag/del-tag/${tagId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      await refreshTagData();
      setTimeout(() => {
        setLoading(false);
      }, 200);
      toast.success("Removido com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleAdminPermissions = async () => {
    await handleUserTag();
  };

  const handleChangeCellphone = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const cellphone = formatCellphone(rawValue);
    setClientCellphone(cellphone);
  };

  const handleLayout = async () => {
    if (!validateRequired({ Tipo: layoutType, Numeração: layoutTypeCode })) return;
    if (!validateString(layoutType, 4, 20)) return;
    if (!validateString(layoutTypeCode.toString(), 1, 10)) return;
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/sale-personalized/new-layout/environment/${user.environmentId}`,
        {
          type: layoutType,
          typeCode: layoutTypeCode,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setLayoutType("");
      setLayoutTypeCode(0);
      toast.success("Adicionado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      await handleLayoutDetails();
      setShowModalLayout(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleEditLayout = async (id: number) => {
    if (layoutType && !validateString(layoutType, 4, 20)) return;
    if (layoutTypeCode && !validateString(layoutTypeCode.toString(), 1, 10)) return;
    try {
      await axios.put(
        process.env.REACT_APP_API_URL + `/sale-personalized/edit-layout/${id}/environment/${user.environmentId}`,
        {
          type: layoutType,
          typeCode: layoutTypeCode,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setLayoutType("");
      setLayoutTypeCode(0);
      toast.success("Atualizado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      await handleLayoutDetails();
      setShowModalEditLayout(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleLayoutDetails = async (id?: number) => {
    setLoading(true);
    try {
      const layoutData = await axios.get(
        process.env.REACT_APP_API_URL + `/sale-personalized/get-layouts/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 200);
      if (id) {
        const layout = layoutData.data.find((layout: any) => layout.id === id);
        setLayoutId(layout.id);
        setLayoutType(layout.type);
        setLayoutTypeCode(layout.typeCode);
      } else {
        setLayouts(layoutData.data);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleRemoveLayout = async (id: number) => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `/sale-personalized/del-layout/${id}/environment/${user.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await handleLayoutDetails();
      toast.success("Removido com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      setShowModalEditLayout(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  const handleChangePage = async (page: number, entity: string) => {
    setPage(page);
    if (entity === "clients") await handleClients(page, personsFiltered);
    if (entity === "employees") await handleEmployees(page, personsFiltered);
    if (entity === "personAccess") await handleEmployees(page, personsAccessFiltered);
  };

  const handleAuthorizationMP = async (code: string | null) => {
    try {
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      await axios.post(
        `${process.env.REACT_APP_API_URL}/sale/mercado-pago/new-device/environment/${userStorage?.environmentId}/user/${userStorage?.id}`,
        {
          code: code,
        },
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      toast.success("Point validado com sucesso.", {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#d4edda", color: "green" },
      });
      navigate("/administration");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleAdminPermissions();
    if (searchParams.get("code")) handleAuthorizationMP(searchParams.get("code"));
  }, []);

  return (
    <>
      <Nav user={user} />
      <div className="containerCustom">
        <div style={{ display: "flex", gap: "2%", margin: "10px 10px 0px 10px", width: "100%" }}>
          <div className="administrationSide">
            <div
              className={
                (userPermissions && userPermissions.includes("CLIENTMANAGER")) ||
                (userPermissions && userPermissions.includes("EMPLOYEEMANAGER")) ||
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                  ? "administrationTitle activeSide"
                  : "administrationTitle inactive"
              }
            >
              <div className="menuTitle">
                <i className="fa-solid fa-user sideIcon"></i>
                <Text>Pessoas</Text>
              </div>
              <button
                className={
                  (userPermissions && userPermissions.includes("CLIENTMANAGER")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => {
                  handleClick("clientList");
                  handleClients();
                }}
              >
                Listar clientes
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("EMPLOYEEMANAGER")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => {
                  handleClick("employeeList");
                  handleEmployees();
                }}
              >
                Listar colaboradores
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("EMPLOYEEMANAGER")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => handleClick("employeeRegister")}
              >
                Cadastrar colaborador
              </button>
            </div>
            <div className="administrationTitle">
              <div className="menuTitle">
                <i className="fa-solid fa-gear sideIcon"></i>
                <Text>Conta</Text>
              </div>
              <button
                className="sideCustom"
                onClick={() => {
                  handleClick("accountInfo");
                  handleUser();
                }}
              >
                Informações da conta
              </button>
              <button
                className="sideCustom"
                onClick={() => {
                  handleClick("environmentInfo");
                  handleEnvironment();
                }}
              >
                Informações do ambiente
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => {
                  handleClick("accessControl");
                  handleEmployees();
                }}
              >
                Controle de acesso
              </button>
            </div>
            <div className="administrationTitle">
              <div className="menuTitle">
                <i className="fa-solid fa-bell sideIcon"></i>
                <Text>Notificação</Text>
              </div>
              <button className="sideCustom" onClick={() => handleClick("system")}>
                Sistema
              </button>
            </div>
            <div
              className={
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                  ? "administrationTitle activeSide"
                  : "administrationTitle inactive"
              }
            >
              <div className="menuTitle">
                <i className="fa-solid fa-list sideIcon"></i>
                <Text>Módulos</Text>
              </div>

              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => {
                  handleClick("modulesParams");
                  handleEnvironment();
                }}
              >
                Permissões
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => {
                  handleClick("sales");
                  handleLayoutDetails();
                }}
              >
                Vendas
              </button>
            </div>
            <div
              className={
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                  ? "administrationTitle activeSide"
                  : "administrationTitle inactive"
              }
            >
              <div className="menuTitle">
                <i className="fa-solid fa-mobile-retro sideIcon"></i>
                <Text>Mercado Pago</Text>
              </div>

              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => handleClick("pointList")}
              >
                Listar points
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => handleClick("point")}
              >
                Cadastrar point
              </button>
            </div>
            <div
              className={
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                  ? "administrationTitle activeSide"
                  : "administrationTitle inactive"
              }
            >
              <div className="menuTitle">
                <i className="fa-solid fa-screwdriver-wrench sideIcon"></i>
                <Text>Suporte</Text>
              </div>

              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => handleClick("ticketList")}
              >
                Listar tickets
              </button>
              <button
                className={
                  (userPermissions && userPermissions.includes("advanced")) ||
                  ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                    ? "sideCustom activeSide"
                    : "sideCustom inactive"
                }
                onClick={() => handleClick("contact")}
              >
                Contato
              </button>
            </div>
          </div>
          <div className="administrationLayout">
            <div
              className={
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "") ||
                addClass !== "controlContainer"
                  ? "inactive"
                  : "d-flex justify-content-center align-items-center flex-column activeSide"
              }
              style={{ height: "100%" }}
            >
              <img width={200} height={200} src="./nodata.png" alt="nodata" />
              <Text>Nada por aqui...</Text>
            </div>
            <div
              className={
                (userPermissions && userPermissions.includes("advanced")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(user?.role ?? "")
                  ? "activeSide"
                  : "inactive"
              }
            >
              <div className={addClass === "controlContainer" ? "controlContainer menuActive" : "inactive"}>
                <div className="controlChildren">
                  <Text>Receita</Text>
                  <Text class="d-block" color="green">
                    $ 0,00
                  </Text>
                </div>
                <div className="controlChildren">
                  <Text>Despesas</Text>
                  <Text class="d-block" color="crimson">
                    $ 0,00
                  </Text>
                </div>
                <div className="controlChildren">
                  <Text>Total estoque</Text>
                  <Text class="d-block" color="green">
                    $ 0,00
                  </Text>
                </div>
                <div className="controlChildren">
                  <Text>Total vendas</Text>
                  <Text class="d-block" color="green">
                    $ 0,00
                  </Text>
                </div>
                <div className="controlChildren">
                  <Text>Resultado</Text>
                  <Text class="d-block" color="var(--backgroundColor3)">
                    $ 0,00
                  </Text>
                </div>
              </div>
            </div>
            <div className={addClass === "clientList" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Pessoas - Listar clientes
              </Text>
              <hr />
              <Input
                class="searchInput"
                width="40%"
                type="text"
                placeholder="pesquisar"
                value={personsFiltered}
                change={(e) => setPersonsFiltered(e.target.value)}
                focus={(e) => setPersonsFiltered(e.target.value)}
              />
              {/* <i className="fa-solid fa-magnifying-glass hideResponsive"></i> */}
              {/*<i className="fa-solid fa-circle-info hideResponsive"></i>*/}
              <hr />
              <div className="tableAdmin">
                <Table hover responsive className="hideResponsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>CPF</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Celular</th>
                      <th>Data criação</th>
                      <th>Data modificação</th>
                      {/* <th>Criado por</th>
                      <th>Modificado por</th> */}
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients &&
                      clients
                        .filter(
                          (e: Models.Client) =>
                            e.name?.toLowerCase().includes(personsFiltered) ||
                            e.name?.toUpperCase().includes(personsFiltered) ||
                            e.cpf?.includes(personsFiltered) ||
                            personsFiltered === ""
                        )
                        .map((iterator: Models.Client, index: number) => {
                          return (
                            <tr key={iterator.id}>
                              <td>{index + 1}</td>
                              <td>{iterator.cpf ? formatCpf(iterator.cpf) : ""}</td>
                              <td>{iterator.name ?? ""}</td>
                              <td>{iterator.email ?? ""}</td>
                              <td>{iterator.cellphone ?? ""}</td>
                              <td>{iterator.createdAt ? moment(iterator.createdAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                              <td>{iterator.updatedAt ? moment(iterator.updatedAt).format("DD/MM/YYYY HH:mm") : ""}</td>
                              {/* <td>{iterator.createdBy ? iterator.createdBy.name : ""}</td>
                              <td>{iterator.updatedBy ? iterator.updatedBy.name : ""}</td> */}
                              <td>
                                <div className="actionsTable">
                                  <i
                                    className="fa-solid fa-circle-info"
                                    onClick={() => {
                                      handleShow("modalClientDetails");
                                      handleGetClientForDetails(iterator.id);
                                    }}
                                  ></i>
                                  <i
                                    className="fa-regular fa-pen-to-square"
                                    onClick={() => {
                                      handleGetClientForEdit(iterator.id?.toString());
                                      handleShow("modalEditClient");
                                    }}
                                  ></i>
                                  <i
                                    className="fa-solid fa-trash"
                                    onClick={() => {
                                      setClientId(iterator.id?.toString());
                                      handleShow("modalRemoveClient");
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
                <Table hover responsive className="showResponsive">
                  <tbody>
                    {clients &&
                      clients
                        .filter(
                          (e: Models.Client) =>
                            e.name?.toLowerCase().includes(personsFiltered) ||
                            e.name?.toUpperCase().includes(personsFiltered) ||
                            e.cpf?.includes(personsFiltered) ||
                            personsFiltered === ""
                        )
                        .map((iterator: Models.Client) => {
                          return (
                            <Fragment key={iterator.id}>
                              <tr>
                                <th>CPF</th>
                                <td>{iterator.cpf ? formatCpf(iterator.cpf) : ""}</td>
                              </tr>
                              <tr>
                                <th>Nome</th>
                                <td>{iterator.name ?? ""}</td>
                              </tr>
                              <tr>
                                <th>E-mail</th>
                                <td>{iterator.email ? iterator.email.substring(0, 4) + "..." : ""}</td>
                              </tr>
                              <tr>
                                <th>Celular</th>
                                <td>{iterator.cellphone ?? ""}</td>
                              </tr>
                              <tr>
                                <th>Data criação</th>
                                <td>
                                  {iterator.createdAt ? moment(iterator.createdAt).format("DD/MM/YYYY HH:mm") : ""}
                                </td>
                              </tr>
                              <tr>
                                <th>Data modificação</th>
                                <td>
                                  {iterator.updatedAt ? moment(iterator.updatedAt).format("DD/MM/YYYY HH:mm") : ""}
                                </td>
                              </tr>
                              {/* <tr>
                                <th>Criado por</th>
                                <td>{iterator.createdBy ? iterator.createdBy.name : ""}</td>
                              </tr>
                              <tr>
                                <th>Modificado por</th>
                                <td>{iterator.updatedBy ? iterator.updatedBy.name : ""}</td>
                              </tr> */}
                              <tr>
                                <th>Ações</th>
                                <td>
                                  <div className="actionsTable">
                                    <i
                                      className="fa-solid fa-circle-info"
                                      onClick={() => {
                                        handleShow("modalClientDetails");
                                        // handleGetProductForDetails(iterator.id);
                                      }}
                                    ></i>
                                    <i
                                      className="fa-regular fa-pen-to-square"
                                      onClick={() => {
                                        handleGetClientForEdit(iterator.id?.toString());
                                        handleShow("modalEditClient");
                                      }}
                                    ></i>
                                    <i
                                      className="fa-solid fa-trash"
                                      onClick={() => {
                                        // setProductId(iterator.id);
                                        handleShow("modalRemoveClient");
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <hr />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
              <div className="d-block">
                Total encontrados na pesquisa:{" "}
                {clients &&
                  clients.filter(
                    (e: Models.Client) =>
                      e.name?.toLowerCase().includes(personsFiltered) ||
                      e.name?.toUpperCase().includes(personsFiltered) ||
                      e.cpf?.includes(personsFiltered) ||
                      personsFiltered === ""
                  ).length}
              </div>
              Total de clientes: {clients?.length}
              <div className="centered">
                <Pagination className="productsPagination">
                  <Pagination.First onClick={() => handleChangePage(1, "clients")} disabled={page === 1} />
                  <Pagination.Prev onClick={() => handleChangePage(page - 1, "clients")} disabled={page === 1} />
                  {page > 1 && (
                    <Pagination.Item onClick={() => handleChangePage(page - 1, "clients")}>{page - 1}</Pagination.Item>
                  )}
                  <Pagination.Item active>{page}</Pagination.Item>
                  {totalPages > 1 && totalPages !== page && (
                    <Pagination.Item onClick={() => handleChangePage(page + 1, "clients")}>{page + 1}</Pagination.Item>
                  )}
                  {totalPages > 2 && page + 2 <= totalPages && <Pagination.Ellipsis />}
                  {totalPages > 2 && page + 2 <= totalPages && (
                    <Pagination.Item onClick={() => handleChangePage(totalPages, "clients")}>
                      {totalPages}
                    </Pagination.Item>
                  )}
                  <Pagination.Next
                    onClick={() => handleChangePage(page + 1, "clients")}
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={() => handleChangePage(totalPages, "clients")}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </div>
            </div>
            <div className={addClass === "employeeList" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Pessoas - Listar colaboradores
              </Text>
              <hr />
              <Input
                class="searchInput"
                width="40%"
                type="text"
                placeholder="pesquisar"
                value={personsFiltered}
                change={(e) => setPersonsFiltered(e.target.value)}
                focus={(e) => setPersonsFiltered(e.target.value)}
              />
              <hr />
              <div className="tableAdmin">
                <Table hover responsive className="hideResponsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>CPF</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Data criação</th>
                      <th>Data modificação</th>
                      <th>Último acesso</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees &&
                      employees
                        .filter(
                          (e: Models.Employee) =>
                            e.user.name?.toLowerCase().includes(personsFiltered) ||
                            e.user.name?.toUpperCase().includes(personsFiltered) ||
                            e.user.cpf?.includes(personsFiltered) ||
                            personsFiltered === ""
                        )
                        .map((iterator: Models.Employee, index: number) => {
                          return (
                            <tr key={iterator.user.id}>
                              <td>{index + 1}</td>
                              <td>{iterator.user.cpf ? formatCpf(iterator.user.cpf) : ""}</td>
                              <td>{iterator.user.name ?? ""}</td>
                              <td>{iterator.user.email ?? ""}</td>
                              <td>
                                {iterator.user.createdAt
                                  ? moment(iterator.user.createdAt).format("DD/MM/YYYY HH:mm")
                                  : ""}
                              </td>
                              <td>
                                {iterator.user.updatedAt
                                  ? moment(iterator.user.updatedAt).format("DD/MM/YYYY HH:mm")
                                  : ""}
                              </td>
                              <td>
                                {iterator.user.lastLogin
                                  ? moment(iterator.user.lastLogin).format("DD/MM/YYYY HH:mm")
                                  : ""}
                              </td>
                              <td>
                                <div className="actionsTable">
                                  <i
                                    className="fa-solid fa-circle-info"
                                    onClick={() => {
                                      handleShow("modalEmployeeDetails");
                                      handleGetEmployeeForDetails(iterator.user.id);
                                    }}
                                  ></i>
                                  <i
                                    className="fa-regular fa-pen-to-square"
                                    onClick={() => {
                                      handleGetEmployeeForEdit(iterator.user.id?.toString());
                                      handleShow("modalEditEmployee");
                                    }}
                                  ></i>
                                  <i
                                    className="fa-solid fa-trash"
                                    onClick={() => {
                                      setEmployeeId(iterator.user.id?.toString());
                                      handleShow("modalRemoveEmployee");
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
                <Table hover responsive className="showResponsive">
                  <tbody>
                    {employees &&
                      employees
                        .filter(
                          (e: Models.Employee) =>
                            e.user.name?.toLowerCase().includes(personsFiltered) ||
                            e.user.name?.toUpperCase().includes(personsFiltered) ||
                            e.user.cpf?.includes(personsFiltered) ||
                            personsFiltered === ""
                        )
                        .map((iterator: Models.Employee) => {
                          return (
                            <Fragment key={iterator.user.id}>
                              <tr>
                                <th>CPF</th>
                                <td>{iterator.user.cpf ? formatCpf(iterator.user.cpf) : ""}</td>
                              </tr>
                              <tr>
                                <th>Nome</th>
                                <td>{iterator.user.name ?? ""}</td>
                              </tr>
                              <tr>
                                <th>E-mail</th>
                                <td>{iterator.user.email ? iterator.user.email.substring(0, 4) + "..." : ""}</td>
                              </tr>

                              <tr>
                                <th>Data criação</th>
                                <td>
                                  {iterator.user.createdAt
                                    ? moment(iterator.user.createdAt).format("DD/MM/YYYY HH:mm")
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <th>Data modificação</th>
                                <td>
                                  {iterator.user.updatedAt
                                    ? moment(iterator.user.updatedAt).format("DD/MM/YYYY HH:mm")
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <th>Último acesso</th>
                                <td>
                                  {iterator.user.lastLogin
                                    ? moment(iterator.user.lastLogin).format("DD/MM/YYYY HH:mm")
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <th>Ações</th>
                                <td>
                                  <div className="actionsTable">
                                    <i
                                      className="fa-solid fa-circle-info"
                                      onClick={() => {
                                        handleShow("modalEmployeeDetails");
                                        // handleGetProductForDetails(iterator.id);
                                      }}
                                    ></i>
                                    <i
                                      className="fa-regular fa-pen-to-square"
                                      onClick={(e) => {
                                        // handleGetProductForEdit(iterator.id, e);
                                        handleShow("modalEditEmployee");
                                      }}
                                    ></i>
                                    <i
                                      className="fa-solid fa-trash"
                                      onClick={() => {
                                        // setProductId(iterator.id);
                                        handleShow("modalRemoveEmployee");
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <hr />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
              <div className="d-block">
                Total encontrados na pesquisa:{" "}
                {employees &&
                  employees.filter(
                    (e: Models.Employee) =>
                      e.user.name?.toLowerCase().includes(personsFiltered) ||
                      e.user.name?.toUpperCase().includes(personsFiltered) ||
                      e.user.cpf?.includes(personsFiltered) ||
                      personsFiltered === ""
                  ).length}
              </div>
              Total de colaboradores: {employees?.length}
              <div className="centered">
                <Pagination className="productsPagination">
                  <Pagination.First onClick={() => handleChangePage(1, "employees")} disabled={page === 1} />
                  <Pagination.Prev onClick={() => handleChangePage(page - 1, "employees")} disabled={page === 1} />
                  {page > 1 && (
                    <Pagination.Item onClick={() => handleChangePage(page - 1, "employees")}>
                      {page - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{page}</Pagination.Item>
                  {totalPages > 1 && totalPages !== page && (
                    <Pagination.Item onClick={() => handleChangePage(page + 1, "employees")}>
                      {page + 1}
                    </Pagination.Item>
                  )}
                  {totalPages > 2 && page + 2 <= totalPages && <Pagination.Ellipsis />}
                  {totalPages > 2 && page + 2 <= totalPages && (
                    <Pagination.Item onClick={() => handleChangePage(totalPages, "employees")}>
                      {totalPages}
                    </Pagination.Item>
                  )}
                  <Pagination.Next
                    onClick={() => handleChangePage(page + 1, "employees")}
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={() => handleChangePage(totalPages, "employees")}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </div>
            </div>
            <div className={addClass === "employeeRegister" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Pessoas - Cadastrar colaborador
              </Text>
              <hr />
              <Text color="var(--blackAll)" bold={true} class="d-block">
                CPF*{" "}
                <Text class="textResponsive" size="14px">
                  obrigatório
                </Text>
              </Text>
              <Input
                type="text"
                width="50%"
                // placeholder="000.000.000-00"
                value={employeeCpf}
                change={handleChangeEmployeeCpf}
              />
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Nome*{" "}
                <Text class="textResponsive" size="14px">
                  obrigatório
                </Text>
              </Text>
              <Input width="50%" type="text" value={employeeName} change={(e) => setEmployeeName(e.target.value)} />
              <Text color="var(--blackAll)" bold={true} class="d-block">
                E-mail*{" "}
                <Text class="textResponsive" size="14px">
                  obrigatório
                </Text>
              </Text>
              <Input width="50%" type="text" value={employeeEmail} change={(e) => setEmployeeEmail(e.target.value)} />
              <div style={{ marginTop: "20px" }}>
                <Button name="cadastrar" class="d-block" click={handleEmployeeRegister} />
              </div>
            </div>
            <div className={addClass === "accountInfo" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Conta - Informações da conta
              </Text>
              <hr />
              <Text color="var(--blackAll)" bold={true} class="d-block">
                CPF
              </Text>
              {userDetails && <Text>{userDetails.cpf ? formatCpf(userDetails.cpf) : " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Nome <i className="fa-regular fa-pen-to-square" onClick={() => handleClickEditName()}></i>
              </Text>
              {userDetails &&
                (editUserName ? (
                  <div className="d-flex">
                    <Input
                      type="text"
                      change={(e) => setUserName(e.target.value)}
                      value={userName || " - "}
                      width="50%"
                    />
                    <Button
                      name={<i className="fa fa-check"></i>}
                      backgroundColor="green"
                      click={() => handleClickEditUser()}
                    />
                  </div>
                ) : (
                  <Text>{userName ?? " - "}</Text>
                ))}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                E-mail <i className="fa-regular fa-pen-to-square" onClick={() => handleClickEditEmail()}></i>
              </Text>
              {userDetails &&
                (editUserEmail ? (
                  <div className="d-flex">
                    <Input
                      type="text"
                      change={(e) => setUserEmail(e.target.value)}
                      value={userEmail || " - "}
                      width="50%"
                    />
                    <Button
                      name={<i className="fa fa-check"></i>}
                      backgroundColor="green"
                      click={() => handleClickEditUser()}
                    />
                  </div>
                ) : (
                  <Text>{userEmail ?? " - "}</Text>
                ))}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Cargo
              </Text>
              {userDetails && <Text>{userDetails.role ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Status
              </Text>
              {userDetails && <Text>{userDetails.status ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Membro desde
              </Text>
              {userDetails && <Text>{moment(userDetails.createdAt).format("LL")}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Senha{" "}
                <i
                  style={{ cursor: "pointer" }}
                  className="fa-regular fa-pen-to-square"
                  onClick={() => handleShow("modalChangePassword")}
                ></i>
              </Text>
              ****
              <hr />
              {/* <Text class="d-block">Configurações</Text> */}
              <div style={{ display: "flex" }}>
                {/* <Button class="d-block" name="alterar senha" click={() => handleShow("modalChangePassword")} /> */}
                <Button
                  class="d-block"
                  name="excluir conta"
                  color="white"
                  backgroundColor="crimson"
                  click={() => handleShow("modalRemoveAccount")}
                />
              </div>
            </div>
            <div className={addClass === "environmentInfo" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Conta - Informações do ambiente
              </Text>
              <hr />
              <Text color="var(--blackAll)" bold={true} class="d-block">
                CNPJ
              </Text>
              {environmentDetails && (
                <Text>{environmentDetails.cnpj ? formatCnpj(environmentDetails.cnpj) : " - "}</Text>
              )}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Razão social
              </Text>
              {environmentDetails && <Text>{environmentDetails.companyName ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Nome fantasia
              </Text>
              {environmentDetails && <Text>{environmentDetails.fantasyName ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Nome <i className="fa-regular fa-pen-to-square"></i>
              </Text>
              {environmentDetails && <Text>{environmentDetails.name ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                CEP <i className="fa-regular fa-pen-to-square"></i>
              </Text>
              {environmentDetails && <Text>{environmentDetails.zipcode ?? " sem cep "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Endereço <i className="fa-regular fa-pen-to-square"></i>
              </Text>
              {environmentDetails && (
                <Text>
                  {(environmentDetails.street ?? "sem rua") +
                    " , " +
                    (environmentDetails.number ?? " sem número") +
                    " - " +
                    (environmentDetails.complement ?? "sem complemento") +
                    " , " +
                    (environmentDetails.city ?? "sem cidade") +
                    " , " +
                    (environmentDetails.state ?? "sem estado") +
                    " - " +
                    (environmentDetails.country ?? " sem país") ?? " - "}
                </Text>
              )}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Plano
                {starList &&
                  starList.map((i, index: number) => (
                    <i key={index} className="fa-solid fa-star" style={{ color: "var(--backgroundColor3)" }}></i>
                  ))}
                {starList && starList.length == 1 && (
                  <>
                    <i className="fa-regular fa-star" style={{ color: "var(--backgroundColor3)" }}></i>
                    <i className="fa-regular fa-star" style={{ color: "var(--backgroundColor3)" }}></i>
                  </>
                )}
                {starList && starList.length == 2 && (
                  <i className="fa-regular fa-star" style={{ color: "var(--backgroundColor3)" }}></i>
                )}
              </Text>
              {environmentDetails && <Text>{environmentDetails.plan.name ?? " - "}</Text>}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Valor do plano
              </Text>
              {environmentDetails && environmentDetails.plan.price !== 0 ? (
                <Text>{formatPrice(environmentDetails.plan.price)}</Text>
              ) : (
                "R$ 0"
              )}
              <Text color="var(--blackAll)" bold={true} class="d-block">
                Data criação
              </Text>
              {environmentDetails && <Text>{moment(environmentDetails.createdAt).format("LL")}</Text>}
              <hr />
              <Button class="d-block" name="alterar logo" click={() => handleShow("modalChangeLogo")} />
            </div>
            <div className={addClass === "accessControl" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Conta - Controle de acesso
              </Text>
              <hr />
              <div>
                <Input
                  class="searchInput"
                  width="40%"
                  type="text"
                  placeholder="pesquisar"
                  value={personsAccessFiltered}
                  change={(e) => setPersonsAccessFiltered(e.target.value)}
                  focus={(e) => setPersonsAccessFiltered(e.target.value)}
                />
                <hr />
                <div className="tableAdmin">
                  <Table hover responsive className="hideResponsive">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>CPF</th>
                        <th>Nome</th>
                        <th>Permissão</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees &&
                        employees
                          .filter(
                            (e: Models.Employee) =>
                              e.user.name?.toLowerCase().includes(personsAccessFiltered) ||
                              e.user.name?.toUpperCase().includes(personsAccessFiltered) ||
                              e.user.cpf?.includes(personsAccessFiltered) ||
                              personsAccessFiltered === ""
                          )
                          .map((iterator: Models.Employee, index: number) => {
                            return (
                              <tr key={iterator.user.id}>
                                <td>{index + 1}</td>
                                <td>{iterator.user.cpf ? formatCpf(iterator.user.cpf) : ""}</td>
                                <td>{iterator.user.name ?? ""}</td>
                                <td>
                                  <i
                                    className="fa-solid fa-lock tableIcon"
                                    onClick={() => {
                                      handleShow("modalChangePermission");
                                      handleGetUserForDetails(iterator.user.id);
                                      handleTags(iterator.user.id);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </Table>
                  <Table hover responsive className="showResponsive">
                    <tbody>
                      {employees &&
                        employees
                          .filter(
                            (e: Models.Employee) =>
                              e.user.name?.toLowerCase().includes(personsAccessFiltered) ||
                              e.user.name?.toUpperCase().includes(personsAccessFiltered) ||
                              e.user.cpf?.includes(personsAccessFiltered) ||
                              personsAccessFiltered === ""
                          )
                          .map((iterator: Models.Employee) => {
                            return (
                              <Fragment key={iterator.user.id}>
                                <tr>
                                  <th>CPF</th>
                                  <td>{iterator.user.cpf ? formatCpf(iterator.user.cpf) : ""}</td>
                                </tr>
                                <tr>
                                  <th>Nome</th>
                                  <td>{iterator.user.name ?? ""}</td>
                                </tr>

                                <tr>
                                  <th>Permissão</th>
                                  <td>
                                    <i
                                      className="fa-solid fa-lock tableIcon"
                                      onClick={() => {
                                        handleShow("modalChangePermission");
                                        handleGetUserForDetails(iterator.user.id);
                                        handleTags(iterator.user.id);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <hr />
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                    </tbody>
                  </Table>
                </div>
                <div className="d-block">
                  Total encontrados na pesquisa:{" "}
                  {employees &&
                    employees.filter(
                      (e: Models.Employee) =>
                        e.user.name?.toLowerCase().includes(personsAccessFiltered) ||
                        e.user.name?.toUpperCase().includes(personsAccessFiltered) ||
                        e.user.cpf?.includes(personsAccessFiltered) ||
                        personsAccessFiltered === ""
                    ).length}
                </div>
                Total de colaboradores: {employees?.length}
                <div className="centered">
                  <Pagination className="productsPagination">
                    <Pagination.First onClick={() => handleChangePage(1, "personAccess")} disabled={page === 1} />
                    <Pagination.Prev onClick={() => handleChangePage(page - 1, "personAccess")} disabled={page === 1} />
                    {page > 1 && (
                      <Pagination.Item onClick={() => handleChangePage(page - 1, "personAccess")}>
                        {page - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {totalPages > 1 && totalPages !== page && (
                      <Pagination.Item onClick={() => handleChangePage(page + 1, "personAccess")}>
                        {page + 1}
                      </Pagination.Item>
                    )}
                    {totalPages > 2 && page + 2 <= totalPages && <Pagination.Ellipsis />}
                    {totalPages > 2 && page + 2 <= totalPages && (
                      <Pagination.Item onClick={() => handleChangePage(totalPages, "personAccess")}>
                        {totalPages}
                      </Pagination.Item>
                    )}
                    <Pagination.Next
                      onClick={() => handleChangePage(page + 1, "personAccess")}
                      disabled={page === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => handleChangePage(totalPages, "personAccess")}
                      disabled={page === totalPages}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
            <div className={addClass === "system" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Notificação - Sistema
              </Text>
              <hr />
            </div>
            <div className={addClass === "modulesParams" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Módulos - Permissões
              </Text>
              <hr />
              <Text size="14px">
                Todos os módulos parametrizados aqui, serão listados na parte superior do sistema. Para gerenciar os
                acessos aos módulos para demais colaboradores e clientes, é necessário configurar no setor de Controle
                de acesso.
              </Text>
              <div className="modulesParamsContainer">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Administração"
                  checked={administrationModule}
                  onChange={(e) => setAdministrationModule(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="disabled-custom-switch"
                  label="Relatórios"
                  checked={reportModule}
                  onChange={(e) => setReportModule(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="disabled-custom-switch"
                  label="Estoque"
                  checked={stockModule}
                  onChange={(e) => setStockModule(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="disabled-custom-switch"
                  label="Serviços"
                  checked={serviceModule}
                  onChange={(e) => setServiceModule(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="disabled-custom-switch"
                  label="Vendas"
                  checked={environmentDetails?.saleModule}
                  onChange={(e) => setSaleModule(e.target.checked)}
                />
                <Button name="salvar" click={handleModulesParams} />
              </div>
              <div className="modulesTagsContainer">
                <Text size="14px" class="d-block">
                  As tags criadas aqui podem ser vinculadas a colaboradores no setor de Controle de acesso, para
                  customizar permissões.
                </Text>
                <div className="modulesTagTitle gap-2">
                  <Text color="var(--blackAll)" bold={true}>
                    Nome da tag* <Text size="14px">obrigatório</Text>
                  </Text>{" "}
                  <div>
                    <Input type="text" value={tagName} change={(e) => setTagName(e.target.value)} />
                  </div>
                  <Button name="adicionar tag" click={handleManageTags} />
                </div>
                <div className="formTagsCheck">
                  <div className="tagCheck">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="Gerenciar colaboradores"
                      checked={manageEmployee}
                      onChange={(e) => setManageEmployee(e.target.checked)}
                    />
                    <Text size="16px">Permite que o usuário crie, edite ou exclua outros colaboradores.</Text>
                  </div>
                  <div className="tagCheck">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="disabled-custom-switch"
                      label="Gerenciar clientes"
                      checked={manageClient}
                      onChange={(e) => setManageClient(e.target.checked)}
                    />
                    <Text size="16px" class="descriptionCheck">
                      Permite que o usuário crie, edite ou exclua outros clientes.
                    </Text>
                  </div>
                  <div className="tagCheck">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="disabled-custom-switch"
                      label="Gerenciar estoque"
                      checked={manageStock}
                      onChange={(e) => setManageStock(e.target.checked)}
                    />
                    <Text size="16px" class="descriptionCheck">
                      Permite que o usuário crie, edite ou exclua produtos no estoque.
                    </Text>
                  </div>
                  <div className="tagCheck">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="disabled-custom-switch"
                      label="Gerenciar serviços"
                      checked={manageService}
                      onChange={(e) => setManageService(e.target.checked)}
                    />
                    <Text size="16px" class="descriptionCheck">
                      Permite que o usuário crie, edite ou exclua serviços.
                    </Text>
                  </div>
                </div>
              </div>
              <Button
                name="visualizar tags"
                click={() => {
                  handleShow("modalAllTags");
                  handleTags(undefined, "modalAllTags");
                }}
              />
            </div>
            <div className={addClass === "sales" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Módulos - Vendas
              </Text>
              <hr />
              <Text size="14px">
                A parametrização de vendas é importante para que o sistema possa se adaptar a sua realidade.
              </Text>
              <div className="formTagsCheck">
                <div className="tagCheck">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Venda express"
                    checked={saleExpress}
                    onChange={(e) => setSaleExpress(e.target.checked)}
                  />
                  <Text size="16px">
                    Vendas feitas apenas pelo módulo de vendas. Indicado para vendas de caixa, sem espaço físico para
                    consumo dos clientes.
                  </Text>
                </div>
                <div
                  className="tagCheck"
                  style={{
                    border: "1px solid var(--backgroundColor6)",
                    borderRadius: "10px",
                    padding: "24px",
                  }}
                >
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="disabled-custom-switch"
                    label="Venda personalizada"
                    checked={salePersonalized}
                    onChange={(e) => setSalePersonalized(e.target.checked)}
                  />
                  <Text size="16px" class="descriptionCheck">
                    Vendas feitas pelo módulo de vendas e personalização de espaço físico para consumo de clientes, como
                    mesas, cadeiras, etc. Quando marcada essa opção, o sistema disponibilizará a criação do seu layout
                    personalizado.
                  </Text>
                  <hr />
                  <div className="d-flex justify-content-between align-items-center">
                    <Text>Crie seu layout de vendas personalizadas</Text>
                    <Button
                      name="adicionar item"
                      click={() => {
                        handleShow("modalLayout");
                      }}
                    />
                  </div>
                  <hr />
                  <div className="orders">
                    {layouts &&
                      layouts.map((iterator: any) => {
                        return (
                          <button
                            key={iterator.id}
                            className={iterator.status === "busy" ? "cardCustom" : "cardCustomOp"}
                            onClick={() => {
                              handleLayoutDetails(iterator.id);
                              handleShow("modalEditLayout");
                            }}
                          >
                            <div className={iterator.status === "busy" ? "cardHeaderCustom" : "cardHeaderCustomOp"}>
                              <div className="sonarWrapper">
                                <div className="sonarEmitter">
                                  <div className="sonarWave"></div>
                                </div>
                              </div>
                              {iterator.type?.toUpperCase()} - {iterator.typeCode}
                            </div>
                            {/* <div>
                              <i className="fa-regular fa-clock iconHome"></i>
                              {iterator.time ?? "00:00"}
                            </div>
                            <div className="money">{formatPrice(iterator.total) ?? "R$ 0,00"}</div>
                            <div>
                              <i className="fa-regular fa-user iconHome"></i>
                              {iterator.user}
                            </div> */}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className={addClass === "ticketList" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Suporte - Listar tickets
              </Text>
              <hr />
              <Input
                class="searchInput"
                width="40%"
                type="text"
                placeholder="pesquisar"
                value={ticketsFiltered}
                change={(e) => setTicketsFiltered(e.target.value)}
                focus={(e) => setTicketsFiltered(e.target.value)}
              />
              <i className="fa-solid fa-magnifying-glass hideResponsive"></i>
              <hr />
              <div className="tableAdmin">
                <Table hover responsive className="hideResponsive">
                  <thead>
                    <tr>
                      <th>Ticket</th>
                      <th>Status</th>
                      <th>Assunto</th>
                      <th>Data criação</th>
                      <th>Data modificação</th>
                      <th>Responsável</th>
                      <th>Detalhes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketListMock &&
                      ticketListMock
                        .filter(
                          (e: Models.Ticket) =>
                            e.subject?.toLowerCase().includes(ticketsFiltered) ||
                            e.subject?.toUpperCase().includes(ticketsFiltered) ||
                            ticketsFiltered === ""
                        )
                        .map((iterator: Models.Ticket, index: number) => {
                          return (
                            <tr key={iterator.id}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="statusTicket">
                                  <Button name={iterator.status} backgroundColor={iterator.color ?? ""} disabled />
                                </div>
                              </td>
                              <td>{iterator.subject}</td>
                              <td>{iterator.dateCreated}</td>
                              <td>{iterator.dateUpdated}</td>
                              <td>{iterator.responsible}</td>
                              <td>
                                <i
                                  className="fa-solid fa-circle-info tableIcon"
                                  onClick={() => {
                                    handleShow("modalTicketHistory");
                                    // handleGetUserForDetails(iterator.user.id);
                                  }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
                <Table hover responsive className="showResponsive">
                  <tbody>
                    {ticketListMock &&
                      ticketListMock
                        .filter(
                          (e: Models.Ticket) =>
                            e.subject?.toLowerCase().includes(ticketsFiltered) ||
                            e.subject?.toUpperCase().includes(ticketsFiltered) ||
                            ticketsFiltered === ""
                        )
                        .map((iterator: Models.Ticket) => {
                          return (
                            <Fragment key={iterator.id}>
                              <tr>
                                <th>Ticket</th>
                                <td>{iterator.id}</td>
                              </tr>
                              <tr>
                                <th>Status</th>
                                <td>
                                  <div className="statusTicket">
                                    <Button name={iterator.status} backgroundColor={iterator.color ?? ""} disabled />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>Assunto</th>
                                <td>{iterator.subject}</td>
                              </tr>
                              <tr>
                                <th>Data criação</th>
                                <td>{iterator.dateCreated}</td>
                              </tr>
                              <tr>
                                <th>Data modificação</th>
                                <td>{iterator.dateUpdated}</td>
                              </tr>
                              <tr>
                                <th>Responsável</th>
                                <td>{iterator.responsible}</td>
                              </tr>
                              <tr>
                                <th>Detalhes</th>
                                <td>
                                  <i
                                    className="fa-solid fa-circle-info tableIcon"
                                    onClick={() => {
                                      handleShow("modalTicketHistory");
                                      // handleGetUserForDetails(iterator.user.id);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <hr />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
              <div className="d-block">
                Total encontrados na pesquisa:{" "}
                {ticketListMock &&
                  ticketListMock.filter(
                    (e: Models.Ticket) =>
                      e.subject?.toLowerCase().includes(ticketsFiltered) ||
                      e.subject?.toUpperCase().includes(ticketsFiltered) ||
                      e.id?.toString().includes(ticketsFiltered) ||
                      ticketsFiltered === ""
                  ).length}
              </div>
              Total de tickets: {ticketListMock?.length}
              <PaginationCustom currentPage={1} totalPages={10} />
            </div>
            <div className={addClass === "contact" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Suporte - Contato
              </Text>
              <hr />
              <Text class="d-block" color="var(--blackAll)" bold={true}>
                Assunto
              </Text>
              <Input width="50%" type="text" />
              <Text class="d-block" color="var(--blackAll)" bold={true}>
                Descreva o problema para nós
              </Text>
              <textarea
                style={{
                  width: "90%",
                  height: "200px",
                  letterSpacing: "2px",
                  border: "1px solid var(--backgroundColor6)",
                  borderRadius: "10px",
                  color: "var(--color4)",
                  padding: "10px",
                }}
                maxLength={400}
                // value={message}
                // onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <Text size="12.8px" class="d-block">
                Recomendamos que você descreva o problema com o máximo de detalhes possíveis, para que possamos te
                ajudar da melhor forma. Seu ticket entrará em análise pela equipe responsável pelo suporte e em breve
                retornaremos com uma solução. Você pode acompanhar o status do seu ticket no setor de Listar tickets.
              </Text>
              <Button name="abrir ticket" />
            </div>
            <div className={addClass === "point" ? "menuActive" : "inactive"}>
              <Text class="d-block" size="24px" bold={true} color="var(--blackAll)">
                Administração | Mercado Pago - Point
              </Text>
              <hr />
              <Text size="14px">
                Aqui você pode adicionar ou remover pontos de venda, para que possa gerenciar suas vendas de forma
                automatizada com o Mercado Pago.
              </Text>
              <Text size="12.8px" class="d-block">
                Ao adicionar sua point, você poderá defini-la como principal no setor de Listar points para receber
                pagamentos de vendas feitas no sistema.
              </Text>
              <div style={{ paddingTop: "20px", textAlign: "center" }}>
                <Button name="Adicionar point">
                  <a
                    target="_blank"
                    style={{ color: "white", textDecoration: "none" }}
                    href={`https://auth.mercadopago.com.br/authorization?client_id=${process.env.REACT_APP_MERCADO_PAGO_CLIENT_ID}&response_type=code`}
                  >
                    Adicionar point
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalChangePass} onHide={() => handleClose("modalChangePassword")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Altere sua senha aqui</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ width: "80%" }}>
              <Text color="var(--blackAll)" bold={true}>
                Senha atual* <Text size="14px">obrigatório</Text>
              </Text>
            </div>
            <div style={{ width: "80%" }}>
              <PasswordInput
                maxLength={20}
                width="100%"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div style={{ width: "80%" }}>
              <Text color="var(--blackAll)" bold={true}>
                Nova senha* <Text size="14px">obrigatório</Text>
              </Text>
            </div>
            <div style={{ width: "80%" }}>
              <PasswordInput
                maxLength={20}
                width="100%"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div style={{ width: "80%" }}>
              <Text color="var(--blackAll)" bold={true}>
                Confirmar nova senha* <Text size="14px">obrigatório</Text>
              </Text>
            </div>
            <div style={{ width: "80%" }}>
              <PasswordInput
                maxLength={20}
                width="100%"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button name="salvar" click={handleChangePassword} />
        </Modal.Footer>
      </Modal>
      <Modal show={showModalRemoveAccount} onHide={() => handleClose("modalRemoveAccount")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirme sua senha para excluir sua conta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Text color="var(--blackAll)" bold={true}>
              Senha atual*{" "}
              <Text class="textResponsive" size="14px">
                obrigatório
              </Text>
            </Text>
            <div style={{ width: "80%" }}>
              <PasswordInput
                maxLength={20}
                width="100%"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <span style={{ color: "crimson", fontSize: "16px" }}>
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i> Área
            sensível a alterações irreversíveis{" "}
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i>
          </span>
          <Text size="12px" class="d-block">
            Ao excluir sua conta, todos os seus dados serão apagados. Se você for um administrador todos os dados
            vinculados ao ambiente serão apagados, sendo colaboradores, clientes, estoque, serviços, vendas e tickets.
            Certifique-se de que deseja realmente excluir sua conta.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button name="excluir conta" color="white" backgroundColor="crimson" />
        </Modal.Footer>
      </Modal>
      <Modal show={showModalChangePermission} onHide={() => handleClose("modalChangePermission")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Altere as permissões aqui</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text class="d-block">CPF: {userControlDetails?.cpf ? formatCpf(userControlDetails?.cpf) : ""}</Text>
          <Text class="d-block">Nome: {userControlDetails?.name ? userControlDetails?.name : ""}</Text>
          <div className="permissionContainer">
            {tags &&
              tags.map((tag: Models.Tag) => {
                return (
                  <Form.Check // prettier-ignore
                    type="radio"
                    id="disabled-custom-switch"
                    label={tag.name}
                    name="permission"
                    checked={tagId === tag.id}
                    onChange={() => setTagId(tag.id)}
                    key={tag.id}
                  />
                );
              })}
          </div>
          <span style={{ color: "crimson", fontSize: "16px" }}>
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i> Área
            sensível a alterações{" "}
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i>
          </span>
          <Text size="12px" class="d-block">
            Ao alterar as permissões acima, o usuário poderá ter acesso a informações sensíveis, certifique-se de que o
            usuário é confiável. Necessário que o mesmo realize o login novamente para que as permissões sejam
            aplicadas.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button name="salvar" click={() => handleChangeLinkTag(tagId ?? 0, userControlDetails?.id ?? 0)} />
        </Modal.Footer>
      </Modal>
      <Modal show={showModalTicketHistory} onHide={() => handleClose("modalTicketHistory")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Histórico do ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Timeline
            date="26/01/2024 16:04"
            status="ativo"
            color="green"
            message="Tentei realizar uma venda e fica parado no icone de carregando por muito tempo e o sistema trava."
            subject="Problema ao realizar uma venda"
          />
          <Timeline
            date="26/01/2024 16:32"
            status="suporte"
            color="gray"
            messageSupport="Verificamos que a venda foi concluída e o problema foi resolvido."
            support={true}
          />
          <Timeline
            date="26/01/2024 16:32"
            status="suporte"
            color="gray"
            messageSupport="Verificamos que a venda foi concluída e o problema foi resolvido."
            support={true}
          />
          <Timeline
            date="26/01/2024 16:32"
            status="suporte"
            color="gray"
            messageSupport="Verificamos que a venda foi concluída e o problema foi resolvido."
            support={true}
          />
          <Timeline
            date="26/01/2024 16:32"
            status="suporte"
            color="gray"
            messageSupport="Verificamos que a venda foi concluída e o problema foi resolvido."
            support={true}
          />
          <Timeline
            date="26/01/2024 16:32"
            status="suporte"
            color="gray"
            messageSupport="Verificamos que a venda foi concluída e o problema foi resolvido."
            support={true}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showModalEmployeeDetails} onHide={() => handleClose("modalEmployeeDetails")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Informações do colaborador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {employee && (
            <>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Nome</Text> {employee.name ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">CPF</Text> {employee.cpf ? formatCpf(employee.cpf) : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">E-mail</Text> {employee.email ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Data criação</Text>{" "}
                {employee.createdAt ? moment(employee.createdAt).format("DD/MM/YYYY HH:mm") : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Data modificação</Text>{" "}
                {employee.updatedAt ? moment(employee.updatedAt).format("DD/MM/YYYY HH:mm") : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Último acesso</Text>{" "}
                {employee.lastLogin ? moment(employee.lastLogin).format("DD/MM/YYYY HH:mm") : "-"}
              </Text>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showModalEditEmployee} onHide={() => handleClose("modalEditEmployee")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar colaborador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Nome{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input
              width="100%"
              type="text"
              value={employeeEditName}
              change={(e) => setEmployeeEditName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              E-mail{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input
              width="100%"
              type="text"
              value={employeeEditEmail}
              change={(e) => setEmployeeEditEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button name="cancelar" click={() => handleClose("modalEditEmployee")} />
          <Button name="salvar" click={() => handleEditEmployee(employeeId)} />
        </Modal.Footer>
      </Modal>
      <Modal show={showModalRemoveEmployee} onHide={() => handleClose("modalRemoveEmployee")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Deseja realmente remover o colaborador?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button name="cancelar" click={() => handleClose("modalRemoveEmployee")} />
          <Button name="remover" backgroundColor="crimson" click={() => handleRemoveEmployee(employeeId)} />
        </Modal.Footer>
      </Modal>

      <Modal show={showModalClientDetails} onHide={() => handleClose("modalClientDetails")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Informações do cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {client && (
            <>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Nome</Text> {client.name ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">CPF</Text> {client.cpf ? formatCpf(client.cpf) : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">E-mail</Text> {client.email ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Celular</Text> {client.cellphone ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Data criação</Text>{" "}
                {client.createdAt ? moment(client.createdAt).format("DD/MM/YYYY HH:mm") : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Data modificação</Text>{" "}
                {client.updatedAt ? moment(client.updatedAt).format("DD/MM/YYYY HH:mm") : "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Criado por</Text> {client.createdBy?.name ?? "-"}
              </Text>
              <Text class="d-block">
                <Text color="var(--backgroundColor3)">Modificado por</Text> {client.updatedBy?.name ?? "-"}
              </Text>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showModalEditClient} onHide={() => handleClose("modalEditClient")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Nome{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input width="100%" type="text" value={clientName} change={(e) => setClientName(e.target.value)} />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              CPF{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input
              width="52%"
              type="text"
              // placeholder="000.000.000-00"
              value={clientCpf ? formatCpf(clientCpf) : ""}
              change={handleChangeClientCpf}
            />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              E-mail{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input width="100%" type="text" value={clientEmail} change={(e) => setClientEmail(e.target.value)} />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Celular{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input width="52%" type="text" value={clientCellphone} change={handleChangeCellphone} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button name="cancelar" click={() => handleClose("modalEditClient")} />
          <Button
            name="salvar"
            click={() => {
              handleEditClient(clientId);
            }}
          />
        </Modal.Footer>
      </Modal>
      <Modal show={showModalRemoveClient} onHide={() => handleClose("modalRemoveClient")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Deseja realmente remover o cliente?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button name="cancelar" click={() => handleClose("modalRemoveClient")} />
          <Button name="remover" backgroundColor="crimson" click={() => handleRemoveClient(clientId)} />
        </Modal.Footer>
      </Modal>

      <Modal show={showModalChangeLogo} onHide={() => handleClose("modalChangeLogo")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Altere sua logo aqui</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text color="var(--blackAll)" bold={true} class="d-block">
            Logo{" "}
            <Text class="textResponsive" size="14px">
              a logo será exibida na sua página de vendas e demais acessos
            </Text>
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <div className="logoEnv">
              {logo ? <img src={logo} alt="efi" /> : <i className="fa-solid fa-shop" style={{ fontSize: "26px" }}></i>}
            </div>
          </div>
          <Input width="100%" type="file" id="formLogoFile" change={handleLogo} />
          <Text size="14px">Formatos de arquivos aceitos: .png, .jpg, .jpeg</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button name="cancelar" click={() => handleClose("modalChangeLogo")} />
          <Button name="salvar" click={handleSaveLogo} />
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={showModalAllTags} onHide={() => handleClose("modalAllTags")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tags cadastradas no sistema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: "crimson", fontSize: "16px" }}>
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i> Área
            sensível a alterações{" "}
            <i className="fa-solid fa-triangle-exclamation" style={{ color: "crimson", fontSize: "20px" }}></i>
          </span>
          <Text size="14px" class="d-block">
            Ao alterar as permissões, o usuário poderá ter acesso a informações sensíveis, certifique-se de que o
            usuário é confiável. Necessário que o mesmo realize o login novamente para que as permissões sejam
            aplicadas.
          </Text>
          <hr />
          <div className="tableAdmin">
            <Table hover responsive className="hideResponsive">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Gerenciar colaboradores</th>
                  <th>Gerenciar clientes</th>
                  <th>Gerenciar estoque</th>
                  <th>Gerenciar serviços</th>
                  <th>Usuários vinculados</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tags &&
                  tags.map((iterator: Models.Tag, index: number) => {
                    return (
                      <tr key={iterator.id}>
                        <td>{iterator.name ?? ""}</td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="disabled-custom-switch"
                            checked={manageEmployeeTag[index]}
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            onChange={(e) => setManageEmployeeTag({ ...manageEmployeeTag, [index]: e.target.checked })}
                          />
                        </td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="disabled-custom-switch"
                            checked={manageClientTag[index]}
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            onChange={(e) => setManageClientTag({ ...manageClientTag, [index]: e.target.checked })}
                          />
                        </td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="disabled-custom-switch"
                            checked={manageStockTag[index]}
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            onChange={(e) => setManageStockTag({ ...manageStockTag, [index]: e.target.checked })}
                          />
                        </td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="disabled-custom-switch"
                            checked={manageServiceTag[index]}
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            onChange={(e) => setManageServiceTag({ ...manageServiceTag, [index]: e.target.checked })}
                          />
                        </td>
                        <td>
                          <i className="fa-solid fa-user"></i> {iterator.userTag.length}
                        </td>
                        <td>
                          <button
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            style={{
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fa-solid fa-circle-check"
                              style={{
                                color: "green",
                              }}
                              onClick={() =>
                                handleEditTag(
                                  iterator.id,
                                  manageEmployeeTag[index],
                                  manageClientTag[index],
                                  manageStockTag[index],
                                  manageServiceTag[index]
                                )
                              }
                            ></i>
                          </button>
                        </td>
                        <td>
                          <button
                            disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                            style={{
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <i
                              className="fa-solid fa-circle-minus trashMinus"
                              onClick={() => handleRemoveTag(iterator.id)}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Table hover responsive className="showResponsive">
              <tbody>
                {tags &&
                  tags.map((iterator: Models.Tag, index: number) => {
                    return (
                      <Fragment key={iterator.id}>
                        <tr>
                          <th>Nome</th>
                          <td>{iterator.name ?? ""}</td>
                        </tr>
                        <tr>
                          <th>Gerenciar colaboradores</th>
                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="disabled-custom-switch"
                              checked={manageEmployeeTag[index]}
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              onChange={(e) =>
                                setManageEmployeeTag({ ...manageEmployeeTag, [index]: e.target.checked })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Gerenciar clientes</th>
                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="disabled-custom-switch"
                              checked={manageClientTag[index]}
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              onChange={(e) => setManageClientTag({ ...manageClientTag, [index]: e.target.checked })}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Gerenciar estoque</th>
                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="disabled-custom-switch"
                              checked={manageStockTag[index]}
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              onChange={(e) => setManageStockTag({ ...manageStockTag, [index]: e.target.checked })}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Gerenciar serviços</th>
                          <td>
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="disabled-custom-switch"
                              checked={manageServiceTag[index]}
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              onChange={(e) => setManageServiceTag({ ...manageServiceTag, [index]: e.target.checked })}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Usuários vinculados</th>
                          <td>
                            <i className="fa-solid fa-user"></i> {iterator.userTag.length}
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            <button
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-check"
                                style={{
                                  color: "green",
                                }}
                                onClick={() =>
                                  handleEditTag(
                                    iterator.id,
                                    manageEmployeeTag[index],
                                    manageClientTag[index],
                                    manageStockTag[index],
                                    manageServiceTag[index]
                                  )
                                }
                              ></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            <button
                              disabled={["basic", "blocked", "advanced"].includes(iterator.name)}
                              style={{
                                outline: "none",
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-minus trashMinus"
                                onClick={() => handleRemoveTag(iterator.id)}
                              ></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <hr />
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModalEditLayout} onHide={() => handleClose("modalEditLayout")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Altere seu item aqui</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Tipo{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input type="text" value={layoutType} change={(e) => setLayoutType(e.target.value)} />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Numeração{" "}
              <Text size="14px" width="300px">
                opcional
              </Text>
            </Text>
            <Input
              type="number"
              value={layoutTypeCode}
              change={(e) => setLayoutTypeCode(parseInt(e.target.value ?? "0"))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button name="excluir" color="white" backgroundColor="crimson" click={() => handleRemoveLayout(layoutId)} />
          <Button name="salvar alterações" click={() => handleEditLayout(layoutId)} />
        </Modal.Footer>
      </Modal>

      <Modal show={showModalLayout} onHide={() => handleClose("modalLayout")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Adicione seu item aqui</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Tipo*{" "}
              <Text size="14px" width="300px">
                obrigatório
              </Text>
            </Text>
            <Input type="text" value={layoutType} change={(e) => setLayoutType(e.target.value)} />
          </div>
          <div className="form-group">
            <Text color="var(--blackAll)" bold={true} class="d-block">
              Numeração*{" "}
              <Text size="14px" width="300px">
                obrigatório
              </Text>
            </Text>
            <Input
              type="number"
              value={layoutTypeCode}
              change={(e) => setLayoutTypeCode(parseInt(e.target.value ?? "0"))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button name="salvar" click={handleLayout} />
        </Modal.Footer>
      </Modal>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default Administration;
