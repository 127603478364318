import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Text from "../text/text";
import Button from "../button/button";
import "./nav.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { formatCnpj } from "../../validations/validations";

type Props = {
  user: any;
};

const Nav: FC<Props> = ({ ...props }: Props) => {
  const navigate = useNavigate();
  const [environmentName, setEnvironmentName] = useState<string>("");
  const [environmentCNPJ, setEnvironmentCNPJ] = useState<string>("");
  const [logo, setLogo] = useState<string>("");
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const getEnvironment = async () => {
    try {
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/environment/${userStorage.environmentId}`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setEnvironmentName(response.data.name);
      setEnvironmentCNPJ(response.data.cnpj);
      setLogo(process.env.REACT_APP_API_URL_UPLOAD + response.data.logo);
      if (props.user) {
        const tagsData = await axios.get(
          process.env.REACT_APP_API_URL + `/tag/all-tags/environment/${props.user.environmentId}`,
          {
            headers: {
              Authorization: "Bearer " + props.user.token,
            },
          }
        );

        let userPermissionsData = tagsData.data.find((tag: any) =>
          tag.userTag.find((userTag: any) => userTag.user.id === props.user.id)
        )?.permission;
        if (userPermissionsData) setUserPermissions(userPermissionsData);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
    }
  };

  useEffect(() => {
    getEnvironment();
  }, []);

  const logout = async () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <Navbar className="navCustomAll">
      <Container className="navCustomContainer">
        <Navbar.Brand href="/">
          {/* <img src="logo-e-alpha.png" alt="efi" width="60px" height="60px" /> */}
          <div className="logoEnvNav">
            {logo ? <img src={logo} alt="efi" /> : <i className="fa-solid fa-shop" style={{ fontSize: "26px" }}></i>}
          </div>
        </Navbar.Brand>
        <Navbar.Collapse className="navCustom">
          <div className="navLinksCustom">
            <div>
              <Text bold={true}>
                {environmentName}{" "}
                <button
                  style={{ outline: "none", border: "none", backgroundColor: "inherit" }}
                  onClick={() => navigate("/environments")}
                >
                  <i className="fa-solid fa-repeat"></i>
                </button>
              </Text>
              <div style={{ fontSize: "14px" }}>{environmentCNPJ && formatCnpj(environmentCNPJ)}</div>
            </div>
            <Text class="administrationLink">
              <a href="/administration">Administração</a>
            </Text>
            <Text
              class={
                (userPermissions && userPermissions.includes("REPORTMANAGER")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(props.user?.role ?? "")
                  ? "administrationLink activeSide"
                  : "administrationLink inactive"
              }
            >
              <a href="/report">Relatórios</a>
            </Text>
            <Text
              class={
                (userPermissions && userPermissions.includes("STOCKMANAGER")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(props.user?.role ?? "")
                  ? "administrationLink activeSide"
                  : "administrationLink inactive"
              }
            >
              <a href="/products">Estoque</a>
            </Text>
            <Text
              class={
                (userPermissions && userPermissions.includes("SERVICEMANAGER")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(props.user?.role ?? "")
                  ? "administrationLink activeSide"
                  : "administrationLink inactive"
              }
            >
              <a href="#">Serviços</a>
            </Text>
            <NavDropdown
              className={
                (userPermissions && userPermissions.includes("CALENDARMANAGER")) ||
                ["SYSADMIN", "ADMINISTRATOR"].includes(props.user?.role ?? "")
                  ? "administrationLink activeSide"
                  : "administrationLink inactive"
              }
              title="Agendas"
              id="basic-nav-dropdown"
              style={{ fontSize: "16px" }}
            >
              <NavDropdown.Item href="/schedule">Novo agendamento</NavDropdown.Item>
              <NavDropdown.Item href="/schedules">Seus agendamentos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="administrationLink"
              title="Vendas"
              id="basic-nav-dropdown"
              style={{ fontSize: "16px" }}
            >
              <NavDropdown.Item href="/sale">Nova venda rápida</NavDropdown.Item>
              <NavDropdown.Item href="/sale-checkout">Listar vendas</NavDropdown.Item>
              <NavDropdown.Item href="/sale-personalized">Vendas personalizadas</NavDropdown.Item>
              <NavDropdown.Item href="#">Novo cliente</NavDropdown.Item>
              <NavDropdown.Item href="#">Listar clientes</NavDropdown.Item>
            </NavDropdown>
          </div>
          <div className="navEndCustom">
            <Navbar.Text className="me-4">
              {/* Usuário logado:{" "} */}
              <Text size="16px">
                {props.user?.name.split(" ")[0] ?? ""} ({props.user?.role.toLowerCase() ?? ""})
              </Text>
            </Navbar.Text>
            <Button name="sair" click={logout} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;
