import { FC, useEffect, useState } from "react";
import Nav from "../../components/nav/nav";
import Input from "../../components/input/input";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import PaginationCustom from "../../components/pagination/pagination";
import Loading from "../../components/loading/loading";
import Footer from "../../components/footer/footer";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import { Pagination, Table } from "react-bootstrap";
import { formatPrice } from "../../utils";
import axios from "axios";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import * as Models from "../../models/index";
import "./report.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const SaleCheckout: FC = () => {
  const navigate = useNavigate();
  useAuth();
  const [user] = useClient();
  const [saleSearch, setSaleSearch] = useState<string>("");

  const [paymentSearch, setPaymentSearch] = useState<string>("");
  const [sellerSearch, setSellerSearch] = useState<string>("");
  const [dateSearch, setDateSearch] = useState<[Date | null, Date | null]>([null, null]);

  const [loading, setLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<number>(0);
  const [carts, setCarts] = useState<any>([]);
  const [paymentOptions, setPaymentOptions] = useState<Array<Models.PaymentOptions>>([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleCarts = async (page?: number, filter?: string) => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const carts = await axios.get(
        `${process.env.REACT_APP_API_URL}/sale-personalized/get-carts/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setCarts(carts.data);
      setTotalPages(carts.data.totalPages ?? 1);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handlePaymentOptions = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const paymentOptionsData = await axios.get(`${process.env.REACT_APP_API_URL}/sale/get-payment-options`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setPaymentOptions(paymentOptionsData.data);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleClear = () => {
    setSaleSearch("");
    setPaymentSearch("");
    setSellerSearch("");
    setDateSearch([null, null]);
    refreshData();
  };

  const handleChangePage = async (page: number) => {
    await handleCarts(page, saleSearch);
    setPage(page);
  };

  const refreshData = async () => {
    await handlePaymentOptions();
    await handleCarts();
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      <Nav user={user} />
      <div className="saleCheckoutContainer">
        <div className="saleCheckoutChildren">
          <div className="saleChildrenClientFilter">
            <div>
              <Text size="28px">Relatórios de vendas</Text>
            </div>
            <div>
              <Button name="limpar filtros" click={handleClear} />
            </div>
          </div>
          <hr />
          <div className="searchContainer">
            <Input
              width="30%"
              type="text"
              placeholder="pesquisar"
              value={saleSearch}
              change={(e) => setSaleSearch(e.target.value)}
              focus={(e) => setSaleSearch(e.target.value)}
            />
          </div>
          <hr />
          <div className="tableCustomProducts">
            <Table hover responsive className="hideResponsive saleStatus">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Venda</th>
                  <th>Cliente</th>
                  <th>Itens</th>
                  <th>
                    Pagamento{" "}
                    <button className="filterCustom">
                      <i
                        className="fa-solid fa-filter"
                        onClick={() => (showFilter == 1 ? setShowFilter(0) : setShowFilter(1))}
                      ></i>
                    </button>
                    {showFilter === 1 && (
                      <div className="dropdownFilter">
                        <ul>
                          {paymentOptions &&
                            paymentOptions.map((item: any) => {
                              return (
                                <li
                                  key={item.id}
                                  value={item.id}
                                  onClick={() => {
                                    setPaymentSearch(item.id);
                                    setShowFilter(0);
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </th>
                  <th>Desconto</th>
                  <th>Preço total</th>
                  <th>
                    Responsável{" "}
                    <button className="filterCustom">
                      <i
                        className="fa-solid fa-filter"
                        onClick={() => (showFilter == 2 ? setShowFilter(0) : setShowFilter(2))}
                      ></i>
                    </button>
                    {showFilter === 2 && (
                      <div className="dropdownFilter">
                        <ul>
                          {[
                            {
                              id: 1,
                              name: "JENI",
                            },
                            {
                              id: 2,
                              name: "FADU",
                            },
                          ] &&
                            [
                              {
                                id: 1,
                                name: "JENI",
                              },
                              {
                                id: 2,
                                name: "FADU",
                              },
                            ].map((item: any) => {
                              return (
                                <li
                                  key={item.id}
                                  value={item.id}
                                  onClick={() => {
                                    setSellerSearch(item.name);
                                    setShowFilter(0);
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </th>
                  <th>
                    Data criação{" "}
                    <button className="filterCustom">
                      <i
                        className="fa-solid fa-filter"
                        onClick={() => (showFilter == 3 ? setShowFilter(0) : setShowFilter(3))}
                      ></i>
                    </button>
                    {showFilter === 3 && (
                      <div className="datepicker">
                        <DatePicker
                          type="range"
                          value={dateSearch}
                          onChange={(e) => {
                            setDateSearch(e);
                            setShowFilter(0);
                          }}
                        />
                      </div>
                    )}
                  </th>
                  <th>Detalhes</th>
                </tr>
              </thead>
              <tbody>
                {carts &&
                carts.filter(
                  (e: any) =>
                    (e.client?.name?.toLowerCase().includes(saleSearch) ||
                      e.client?.name?.toUpperCase().includes(saleSearch) ||
                      saleSearch === "") &&
                    (e.paymentOptionId == paymentSearch || paymentSearch == "") &&
                    (e.seller?.name == sellerSearch || sellerSearch == "")
                ).length > 0 ? (
                  carts
                    .filter(
                      (e: any) =>
                        (e.client?.name?.toLowerCase().includes(saleSearch) ||
                          e.client?.name?.toUpperCase().includes(saleSearch) ||
                          saleSearch === "") &&
                        (e.paymentOptionId == paymentSearch || paymentSearch == "") &&
                        (e.seller?.name == sellerSearch || sellerSearch == "")
                    )
                    .map((iterator: any, index: number) => {
                      return (
                        <tr key={iterator.id}>
                          <td>{index + 1}</td>
                          <td>MESA 40</td>
                          <td>{iterator.name}</td>
                          <td>{iterator.cart.cartProduct.length}</td>
                          <td>{iterator.paymentOption.name}</td>
                          <td>{iterator.cart.coupon ? formatPrice(iterator.cart.coupon) : "-"}</td>
                          <td>{iterator.cart && iterator.cart.total ? formatPrice(iterator.cart.total) : "-"}</td>
                          <td>{iterator.seller.name}</td>
                          <td>{iterator.createdAt ? moment(iterator.createdAt).format("L LT") : ""}</td>
                          <td>
                            <hr />
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Não foram encontrados resultados para a pesquisa...
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* <Table hover responsive className="showResponsive fontResponsive">
              <tbody>
                {carts &&
                  carts
                    .filter(
                      (e: any) =>
                        e.salePersoLayout[0]?.type?.toLowerCase().includes(saleSearch) ||
                        e.salePersoLayout[0]?.type?.toUpperCase().includes(saleSearch) ||
                        e.salePersoLayout[0]?.typeCode?.toString() === saleSearch ||
                        saleSearch === ""
                    )
                    .map((iterator: any, index: any) => {
                      return (
                        <Fragment key={iterator.id}>
                          <tr>
                            <th>#</th>
                            <td>{index + 1}</td>
                          </tr>
                          <tr>
                            <th>Venda</th>
                            <td>{iterator.salePersoLayout && iterator.salePersoLayout[0]?.type}</td>
                          </tr>
                          <tr>
                            <th>Número</th>
                            <td>{iterator.salePersoLayout && iterator.salePersoLayout[0]?.typeCode}</td>
                          </tr>
                          <tr>
                            <th>Preço total</th>
                            <td>
                              {iterator.salePersoLayout && iterator.salePersoLayout[0]?.total
                                ? formatPrice(iterator.salePersoLayout[0]?.total)
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th>Tempo</th>
                            <td>
                              {iterator.salePersoLayout &&
                                moment(iterator.salePersoLayout[0].time).format("HH:MM:ss")}
                            </td>
                          </tr>
                          <tr>
                            <th>Responsável</th>
                            <td>{iterator.salePersoLayout && iterator.salePersoLayout[0].user.name}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>
                              {iterator.salepersoLayout && iterator.salepersoLayout[0]?.status === "done" ? (
                                <>
                                  <i className="fa-regular fa-circle-check" style={{ color: "green" }}></i>{" "}
                                  Concluída
                                </>
                              ) : (
                                <>
                                  <i className="fa-solid fa-spinner" style={{ color: "#ff6000" }}></i> Aberta
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Ação</th>
                            <td onClick={() => navigate(`/sale/${iterator.id}`)}>
                              <i
                                className="fa-solid fa-right-to-bracket saleCheckoutIcon"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <hr />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </tbody>
            </Table> */}
          </div>
          <div className="d-block">
            Total encontrados na pesquisa:{" "}
            {/* {carts &&
              carts.filter(
                (e: any) =>
                  e.salePersoLayout[0]?.type?.toLowerCase().includes(saleSearch) ||
                  e.salePersoLayout[0]?.type?.toUpperCase().includes(saleSearch) ||
                  e.salePersoLayout[0]?.typeCode?.toString() === saleSearch ||
                  saleSearch === ""
              ).length} */}
          </div>
          Total de vendas: {carts && carts.length}
          <div className="centered">
            <Pagination className="productsPagination">
              <Pagination.First onClick={() => handleChangePage(1)} disabled={page === 1} />
              <Pagination.Prev onClick={() => handleChangePage(page - 1)} disabled={page === 1} />
              {page > 1 && <Pagination.Item onClick={() => handleChangePage(page - 1)}>{page - 1}</Pagination.Item>}
              <Pagination.Item active>{page}</Pagination.Item>
              {totalPages > 1 && totalPages !== page && (
                <Pagination.Item onClick={() => handleChangePage(page + 1)}>{page + 1}</Pagination.Item>
              )}
              {totalPages > 2 && page + 2 <= totalPages && <Pagination.Ellipsis />}
              {totalPages > 2 && page + 2 <= totalPages && (
                <Pagination.Item onClick={() => handleChangePage(totalPages)}>{totalPages}</Pagination.Item>
              )}
              <Pagination.Next onClick={() => handleChangePage(page + 1)} disabled={page === totalPages} />
              <Pagination.Last onClick={() => handleChangePage(totalPages)} disabled={page === totalPages} />
            </Pagination>
          </div>
        </div>
      </div>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default SaleCheckout;
