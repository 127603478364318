import { FC, Fragment, useEffect, useState, ChangeEvent, MouseEvent } from "react";
import Nav from "../../components/nav/nav";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading/loading";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import * as Models from "../../models/index";
import { formatPrice } from "../../utils";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import axios from "axios";
import "./salePersonalized.css";
import toast from "react-hot-toast";
import moment from "moment";
import { useWebSocket } from "../../contexts/WebSocketContext";

const SalePersonalized: FC = () => {
  useAuth();
  const { socket } = useWebSocket();
  const [user] = useClient();
  const navigate = useNavigate();
  const [showModalSale, setShowModalSale] = useState<boolean>(false);
  const [productSearch, setProductSearch] = useState<string>("");
  const [saleSearch, setSaleSearch] = useState<string>("");
  const [products, setProducts] = useState<any>([]);
  const [cart, setCart] = useState<any>();
  const [cartProduct, setCartProduct] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [layouts, setLayouts] = useState<any>([]);
  const [timer, setTimer] = useState<any[]>([]);
  const [timerTick, setTimerTick] = useState<number>(0);
  const [productQuantity, setProductQuantity] = useState<Array<{ quantity: string | undefined }>>([]);
  const [saleTotalPrice, setSaleTotalPrice] = useState<number>(0);

  const handleClose = (type: string) => {
    if (type === "modalSale") setShowModalSale(false);
  };

  const handleShow = (type: string) => {
    if (type === "modalSale") setShowModalSale(true);
  };

  const handleProducts = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const productData = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/get-products/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setProducts(productData.data.products);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleCartDetails = async (id: number) => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/sale-personalized/get-layout/${id}/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setCart({});
      setCartProduct([]);
      setProductQuantity([]);
      setCart(response.data);
      setCartProduct(response.data.cart?.cartProduct);
      let productQuantityTest: any = [];
      for (const product of response.data.cart?.cartProduct ?? []) {
        productQuantityTest[product.productId] = { quantity: product.quantity };
        handleCalculatePrice(product.quantity, product.productId);
      }
      setProductQuantity(productQuantityTest);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleLayoutDetails = async () => {
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      const layoutData = await axios.get(
        process.env.REACT_APP_API_URL + `/sale-personalized/get-layouts/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      setLayouts(layoutData.data);

      let timers: any[] = [];

      layoutData.data.forEach((layout: any, index: number) => {
        // let timers = timer;
        // if (index == 0) timers = [];
        // console.log(index, timer, timers);
        timers.push({
          start: layout.time,
          time: secondsToHMS(moment().diff(layout.time ?? moment().format(), "seconds")),
        });
        setTimer(timers);
      });
      console.log(timers);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleCart = async (layoutId: number) => {
    const body = {
      coupon: cart.coupon,
      total: saleTotalPrice,
      timeInicio: cart.timeInicio ?? moment().format("YYYY-MM-DD HH:mm:ss"),
      cartProduct: cartProduct.map((e: any) => {
        return {
          id: e?.id,
          productId: e.product.id,
          quantity: parseInt(productQuantity[e.product.id]?.quantity ?? "0"),
          coupon: e.coupon,
        };
      }),
    };
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      await axios.post(
        process.env.REACT_APP_API_URL +
          `/sale-personalized/new-cart/layout/${layoutId}/environment/${userStorage.environmentId}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      await refreshData();
      setTimeout(() => {
        setLoading(false);
      }, 200);
      setShowModalSale(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleCartProducts = (productId: number) => {
    setCartProduct([
      ...(cartProduct ?? []),
      { product: products.find((e: Models.Product) => e.id === productId) as Models.Product },
    ]);
    setProductSearch("");
  };

  const handleCalculatePrice = (e: string, index: number) => {
    const product = products.find((prod: any) => prod.id == index);
    if (parseInt(e) > product?.quantity!) {
      toast.error(`Produto ${product?.name}. Quantidade ${product?.quantity} em estoque.`, {
        position: "top-center",
        duration: 5000,
        style: { backgroundColor: "#f8d7da", color: "crimson" },
      });
      return;
    }
    productQuantity[index] = { quantity: e ?? "0" };
    setProductQuantity([...productQuantity]);
    let price = 0;
    cartProduct.forEach((p: any) => {
      price += parseInt(productQuantity[p.product.id]?.quantity ?? "0") * p.product.unitaryPrice;
    });
    setSaleTotalPrice(price);
  };

  const handleRemoveProduct = async (layoutId: number, cartId: number, cartProductId: number) => {
    setCartProduct(cartProduct.filter((e: any) => e.id !== cartProductId));
    try {
      setLoading(true);
      const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : "";
      await axios.delete(
        process.env.REACT_APP_API_URL +
          `/sale-personalized/cart/${cartId}/del-cart-product/${cartProductId}/environment/${userStorage.environmentId}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );
      // let price = 0;
      // console.log(cartProduct);
      // cartProduct.forEach((p: any) => {
      //   price += parseInt(productQuantity[p.product.id]?.quantity ?? "0") * p.product.unitaryPrice;
      // });
      // setSaleTotalPrice(price);
      //await handleCart(layoutId);
      await handleCartDetails(layoutId);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(
          error.response.data.errorcode
            ? error.response.data.message
            : `Erro inesperado, contate o suporte. [${error.response.status}]`,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "#f8d7da", color: "crimson" },
          }
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleSale = async (id: number) => {
    //limpar carrinho
    setCart({});
    setCartProduct([]);
    setProductQuantity([]);
    setSaleTotalPrice(0);
    setProductSearch("");

    setShowModalSale(false);

    navigate(`/sale-finish/cart/${id}`);
  };

  const refreshData = async () => {
    await handleProducts();
    await handleLayoutDetails();
  };

  const secondsToHMS = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (socket) {
      socket.on("message", (msg: string) => {
        console.log("Message from server:", msg);
      });

      socket.on("sale-personalized", (msg) => {
        console.log("Message from server in subject sale-personalized:", msg);
        if (msg.action == "layouts-update") handleLayoutDetails();
      });

      socket.emit("subscribe", "sale-personalized");

      return () => {
        socket.off("message");
      };
    }
  }, [socket]);

  useEffect(() => {
    const newTimer = timer.map((timer) => {
      return { time: secondsToHMS(moment().diff(timer.start ?? moment().format(), "seconds")), start: timer.start };
    });
    setTimer(newTimer);

    setTimeout(() => {
      setTimerTick(timerTick + 1);
    }, 1000);
  }, [timerTick]);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    let price = 0;
    cartProduct &&
      cartProduct.forEach((p: any) => {
        price += parseInt(productQuantity[p.product.id]?.quantity ?? "0") * p.product.unitaryPrice;
      });
    setSaleTotalPrice(price);
  }, [cartProduct]);

  return (
    <>
      <Nav user={user} />
      <div className="salePersoContainer">
        <div className="salePersoChildren">
          <div className="saleChildrenClientFilter">
            <div>
              <Text size="28px">Vendas personalizadas</Text>
            </div>
            <div>
              <div className="searchContainer">
                <Input
                  width="30%"
                  type="text"
                  placeholder="pesquisar"
                  value={saleSearch}
                  change={(e) => setSaleSearch(e.target.value)}
                  focus={(e) => setSaleSearch(e.target.value)}
                />{" "}
              </div>
              <hr />
            </div>
          </div>
          <hr />
          <div className="orders">
            {layouts &&
              layouts
                .filter(
                  (e: any) =>
                    e.type?.toLowerCase().includes(saleSearch) ||
                    e.type?.toUpperCase().includes(saleSearch) ||
                    e.typeCode?.toString() === saleSearch ||
                    saleSearch === ""
                )
                .map((iterator: any, index: number) => {
                  return (
                    <button
                      key={iterator.id}
                      className={iterator.status === "busy" ? "cardCustom" : "cardCustomOp"}
                      onClick={() => {
                        handleCartDetails(iterator.id);
                        handleShow("modalSale");
                      }}>
                      <div className={iterator.status === "busy" ? "cardHeaderCustom" : "cardHeaderCustomOp"}>
                        <div className="sonarWrapper">
                          <div className="sonarEmitter">
                            <div className="sonarWave"></div>
                          </div>
                        </div>
                        {iterator.type?.toUpperCase()} - {iterator.typeCode}
                      </div>
                      <div>
                        <i className="fa-regular fa-clock iconHome"></i>
                        {/* {iterator.time ? moment(iterator.time).format("HH:mm:ss") : "00:00"} */}
                        {timer && timer[index] && timer[index].time}
                      </div>
                      <div className="money">{formatPrice(iterator.total) ?? "R$ 0,00"}</div>
                      <div style={{ padding: "6px" }}>
                        <i className="fa-regular fa-user iconHome"></i>
                        {iterator.user?.name}
                      </div>
                    </button>
                  );
                })}
          </div>
        </div>
      </div>
      <Modal
        show={showModalSale}
        onHide={() => handleClose("modalSale")}
        centered
        // className="modal-fullscreen-xl-down"
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center justify-content-center gap-4">
            <div className={cart && cart.status === "busy" ? "modalCustom" : "modalCustomOp"}>
              <div className="sonarWrapper">
                <div className="sonarEmitter">
                  <div className="sonarWave"></div>
                </div>
              </div>
            </div>
            {cart && cart.type?.toUpperCase()} - {cart && cart.typeCode}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            width="80%"
            type="text"
            placeholder="pesquisar produto"
            value={productSearch}
            change={(e) => setProductSearch(e.target.value)}
            focus={(e) => setProductSearch(e.target.value)}
          />
          {productSearch &&
            (cartProduct ? (
              <div className="productSearchDropdown">
                <ul>
                  {products &&
                    products
                      .filter(
                        (e: any) =>
                          cartProduct.find((p: any) => e.name === p.product.name) === undefined &&
                          (e.name.toLowerCase().includes(productSearch) ||
                            e.name.toUpperCase().includes(productSearch) ||
                            productSearch === "") /* &&
                              cartProduct.length == 0) ||
                            cartProduct.find((p: any) => e.id === p.product.id) === undefined*/
                      )
                      .map((item: any) => {
                        return (
                          <li
                            key={item.id}
                            value={item.id}
                            onClick={(e: MouseEvent<HTMLLIElement>) => handleCartProducts(e.currentTarget.value)}>
                            {item.name + item.id}
                          </li>
                        );
                      })}
                </ul>
              </div>
            ) : (
              <div className="productSearchDropdown">
                <ul>
                  {products &&
                    products
                      .filter(
                        (e: any) =>
                          e.name.toLowerCase().includes(productSearch) || e.name.toUpperCase().includes(productSearch)
                        // .filter(
                        //   (e: any) =>
                        //     (cartProduct?.find((p: any) => e.name === p.product.name) === undefined &&
                        //       (e.name.toLowerCase().includes(productSearch) ||
                        //         e.name.toUpperCase().includes(productSearch) ||
                        //         productSearch === "") &&
                        //       cartProduct?.length == 0) ||
                        //     cartProduct?.find((p: any) => e.id === p.product.id) === undefined
                        // )
                      )
                      .map((item: any) => {
                        return (
                          <li
                            key={item.id}
                            value={item.id}
                            onClick={(e: MouseEvent<HTMLLIElement>) => handleCartProducts(e.currentTarget.value)}>
                            {item.name + item.id}
                          </li>
                        );
                      })}
                </ul>
              </div>
            ))}
          <hr />
          <hr />
          <div style={{ fontSize: "16px" }}>
            <div className="saleCart">
              <Table hover responsive className="hideResponsive">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Preço unitário</th>
                    <th>Quantidade</th>
                    <th>Preço total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartProduct &&
                    cartProduct.map((iterator: any) => {
                      return (
                        <tr key={iterator.product.id}>
                          <td>{iterator.product.code}</td>
                          <td>{iterator.product.name}</td>
                          <td>{formatPrice(iterator.product.unitaryPrice ?? "0")}</td>
                          <td>
                            <input
                              className="quantity"
                              type="text"
                              maxLength={4}
                              value={productQuantity[iterator.product.id]?.quantity ?? iterator.quantity}
                              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                handleCalculatePrice(e.target.value ?? iterator.quantity, iterator.product.id)
                              }
                            />
                          </td>
                          <td>
                            {(productQuantity[iterator.product.id]?.quantity ?? iterator.quantity) *
                            iterator.product.unitaryPrice
                              ? formatPrice(
                                  (productQuantity[iterator.product.id]?.quantity ?? iterator.quantity) *
                                    iterator.product.unitaryPrice
                                )
                              : "R$ 0,00"}
                          </td>
                          <td>
                            <i
                              className="fa-solid fa-circle-minus trashMinus"
                              onClick={() => handleRemoveProduct(cart.id, iterator.cartId, iterator.id)}></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <Table hover responsive className="showResponsive fontResponsive">
                <tbody>
                  {cartProduct &&
                    cartProduct.map((iterator: any) => {
                      return (
                        <Fragment key={iterator.product.id}>
                          <tr>
                            <th>Código</th>
                            <td>{iterator.product.code}</td>
                          </tr>
                          <tr>
                            <th>Nome</th>
                            <td>{iterator.product.name}</td>
                          </tr>
                          <tr>
                            <th>Preço unitário</th>
                            <td>{formatPrice(iterator.product.unitaryPrice ?? "0")}</td>
                          </tr>
                          <tr>
                            <th>Quantidade</th>
                            <td>
                              <input
                                className="quantity"
                                type="text"
                                maxLength={4}
                                value={productQuantity[iterator.product.id]?.quantity ?? iterator.quantity}
                                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleCalculatePrice(e.target.value ?? iterator.quantity, iterator.product.id)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Preço total</th>
                            <td>
                              {(productQuantity[iterator.product.id]?.quantity ?? iterator.quantity) *
                              iterator.product.unitaryPrice
                                ? formatPrice(
                                    (productQuantity[iterator.product.id]?.quantity ?? iterator.quantity) *
                                      iterator.product.unitaryPrice
                                  )
                                : "R$ 0,00"}
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <td>
                              <i
                                className="fa-solid fa-circle-minus trashMinus"
                                onClick={() => handleRemoveProduct(cart.id, iterator.cartId, iterator.id)}></i>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <hr />
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <hr />
            <div className="paymentSale">
              <div className="d-block">Total de itens: {(cart && cart.cart?.cartProduct?.length) ?? 0}</div>
              <div>
                {/* <div className="d-block">Desconto: 0,00</div> */}
                <div className="d-block" style={{ fontSize: "24px" }}>
                  Total:{" "}
                  {saleTotalPrice ? formatPrice(saleTotalPrice ?? 0) : formatPrice(cart?.total ?? 0) ?? "R$ 0,00"}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="infoSale">
          <div>
            <i className="fa-regular fa-user iconHome"></i>
            <Text size="14px">{cart && cart.user?.name}</Text>
          </div>
          <div>
            <Button name="fechar pedido" click={() => handleSale(cart.cartId)} />
            <Button name="salvar alterações" click={() => handleCart(cart.id)} />
          </div>
        </Modal.Footer>
      </Modal>
      {loading && <Loading show={true} />}
      <Footer />
    </>
  );
};

export default SalePersonalized;
