import { FC } from "react";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer";
import Button from "../../components/button/button";
import Text from "../../components/text/text";
import useAuth from "../../hooks/useAuth";
import useClient from "../../hooks/useClient";
import Carousel from "react-bootstrap/Carousel";
import "./home.css";
import { useNavigate } from "react-router-dom";

const Home: FC = () => {
  useAuth();
  const [user] = useClient();
  const navigate = useNavigate();

  return (
    <>
      <Nav user={user} />
      <div className="homeContainer">
        <div>
          {/* <Text class="tutorialTitle" color="var(--blackAll)">
            Aqui vai um tutorial de como usar o sistema
          </Text> */}
          <Carousel interval={null} data-bs-theme="dark" slide={false} className="carouselCustom">
            <Carousel.Item>
              <div className="tutorialCardItem">
                <Carousel.Caption className="tutorialCards">
                  <div className="tutorialCard">
                    <div className="logoEnv">
                      <i className="fa-solid fa-layer-group" style={{ fontSize: "36px" }}></i>
                    </div>
                    <div>
                      <Text class="tutorialTitle" color="var(--blackAll)">
                        Adicione produtos
                      </Text>
                    </div>
                    <div>
                      <Text size="14px">Adicione produtos ao estoque para que possa ser feita a venda dos mesmos</Text>
                    </div>
                    <hr />
                    <Button name="ir para o estoque" click={() => navigate("/products")} />
                  </div>
                  <div className="tutorialCard">
                    <div className="logoEnv">
                      <i className="fa-solid fa-user-tie" style={{ fontSize: "36px" }}></i>
                    </div>
                    <div>
                      <Text class="tutorialTitle" color="var(--blackAll)">
                        Configure os usuários
                      </Text>
                    </div>
                    <div>
                      <Text size="14px">
                        Configure os usuários para que possam fazer vendas e administração do sistema
                      </Text>
                    </div>
                    <hr />
                    <Button name="ir para administração" click={() => navigate("/administration")} />
                  </div>
                  <div className="tutorialCard">
                    <div className="logoEnv">
                      <i className="fa-solid fa-hand-holding-dollar" style={{ fontSize: "36px" }}></i>
                    </div>
                    <div>
                      <Text class="tutorialTitle" color="var(--blackAll)">
                        Personalize suas vendas
                      </Text>
                    </div>
                    <div>
                      <Text size="14px">Personalize suas vendas para que possa se adaptar a sua realidade</Text>
                    </div>
                    <hr />
                    <Button name="ir para vendas" click={() => navigate("/sale")} />
                  </div>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="tutorialCardItem">
                <Carousel.Caption className="tutorialCards">
                  <div className="tutorialCard">
                    <div className="logoEnv">
                      <i className="fa-solid fa-file-lines" style={{ fontSize: "36px" }}></i>
                    </div>
                    <div>
                      <Text class="tutorialTitle" color="var(--blackAll)">
                        Relatórios de vendas
                      </Text>
                    </div>
                    <Text size="14px">Consulte os dados no sistema de acordo com sua necessidade</Text>
                    <hr />
                    <Button name="ir para o relatórios" click={() => navigate("/report")} />
                  </div>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
