import { FC, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import "./pagination.css";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  handleNextPage?: (page: number) => void;
  handlePrevPage?: (page: number) => void;
};

const PaginationCustom: FC<PaginationProps> = ({ ...props }: PaginationProps) => {
  const [page, setPage] = useState(1);

  const handlePrevPage = (prevPage: number) => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = (nextPage: number) => {
    setPage((nextPage) => nextPage + 1);
  };

  return (
    <div className="centered">
      <Pagination className="productsPagination">
        <Pagination.Prev onClick={() => handlePrevPage(props.currentPage)} disabled={props.currentPage === 1} />
        <Pagination.Item active>{props.currentPage}</Pagination.Item>
        {/* <span>
          Página {props.currentPage} de {props.totalPages}
        </span> */}
        <Pagination.Next
          onClick={() => handleNextPage(props.currentPage)}
          disabled={props.currentPage === props.totalPages}
        />
      </Pagination>
    </div>
  );
};

export default PaginationCustom;
